export default class SecurityOffice {
	constructor (object) {
		this.Id = object?.SecurityOfficeId;
		this.Name = object?.Name;
		this.Code = object?.Code;
		this.Address = object?.Address;
		this.City = object?.City;
		this.State = object?.State;
		this.ZipCode = object?.ZipCode;
		this.Country = object?.Country;
		this.PhoneNumber = object?.PhoneNumber;
		this.Email = object?.Email;
		this.Latitude = object?.Latitude;
		this.Longitude = object?.Longitude;
		this.Altitude = object?.Altitude;
		this.OfficeTypeId = object?.OfficeTypeId;
		this.StatusId = object?.StatusId;
		this.IsMonitoring = object?.IsMonitoring;
		this.Managing = object?.Managing;
		this.Admins = object?.Admins?.map(a => {
			return {
				Name: a.AdminName,
				Surname: a.AdminSurname,
				Email: a.AdminEmail,
				PhoneNumber: a.AdminPhoneNumber,
				Avatar: a.AdminAvatar,
				Id: a.AdminId,
				StatusId: a.StatusId,
			};
		});
		this.Organizations = object !== null?.Managing.map(o => {
			return {
				Id: o.OrganizationId,
				Name: o.OrganizationName,
			};
		});
	}
}
