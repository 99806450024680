import Repository from '@/repositories/RepositoryFactory';

const TaskListRepository = Repository.get('client', 'tasklist');

export default {
	namespaced: true,
	state: {
		tasklists: [],
		assignedTo: [],
	},
	getters: {

	},
	mutations: {
		LOAD_TASKLISTS: (state, payload) => {
			state.tasklists = payload;
		},
		LOAD_ASSIGNED_TO_BRANCHES: (state, payload) => {
			state.assignedTo = payload;
		},
	},
	actions: {
		async getTaskListsFromRepo ({ commit }, orgId) {
			commit('LOAD_TASKLISTS', await TaskListRepository.get(orgId));
		},

		async getAssignedToBranches ({ commit }, clid) {
			commit('LOAD_ASSIGNED_TO_BRANCHES', await TaskListRepository.getAssignedToBranches(clid));
		},

		async deassignChecklist ({ commit }, object) {
			const response = await TaskListRepository.deassign(object);
			return response;
		},

		async assignChecklist ({ commit }, object) {
			const response = await TaskListRepository.assign(object);
			return response;
		},

		async cloneClTemplate ({ commit }, id) {
			const response = await TaskListRepository.cloneClTemplate(id);
			return response;
		},

		async updateClTemplate ({ commit }, object) {
			const response = await TaskListRepository.updateClTemplate(object);
			return response;
		},

		async import ({ commit }) {
			const response = await TaskListRepository.import();
			return response;
		},

		async delete ({ commit }, id) {
			const response = await TaskListRepository.delete(id);
			return response;
		},

		async updateClTemplateName ({ commit }, object) {
			const response = await TaskListRepository.updateClTemplateName(object);
			return response;
		},

		async updateClTemplateOrder ({ commit }, object) {
			const response = await TaskListRepository.updateClTemplateOrder(object);
			return response;
		},
	},
};
