export default class ActivityReport {
	constructor (object) {
		this.SessionId = object.SessionId;
		this.Token = object.Token;
		this.SessionType = object.SessionType;
		this.Date = object.Date;
		this.Id = object.UserId;
		this.Name = object.Name;
		this.Surname = object.Surname;
		this.FullName = `${object.Name} ${object.Surname}`;
		this.RoleName = object.RoleName;
		this.RoleNameDisplay = object.RoleNameDisplay;
		this.OrganizationName = object.OrganizationName;
		this.PhoneNumber = object.PhoneNumber;
		this.Email = object.Email;
		this.UTCTimeStamp = object.UTCTimeStamp;
		this.WorksIn = object.WorksIn;
		this.TaskingInfo = object.TaskingInfo !== null ? object.TaskingInfo : null;
		this.IsVideoChatRunning = object.IsVideoChatRunning;
		this.BranchName = object.WorksIn[0].BranchOfficeName;
		this.RegionName = object.WorksIn[0].RegionalOfficeName;
		this.LastLogin = object.LastLogin;
	}
}
