<template>
	<v-app id="main-app">
		<v-container class="fill-height" fluid>
			<div v-if="loading" class="text-center mx-auto px-10 pb-9">
				<v-progress-circular
					indeterminate
					:size="50"
					color="white"
				></v-progress-circular>
			</div>
			<div v-if="!loading" class="mx-0 mx-sm-auto mx-md-auto mx-lg-auto mx-xl-auto px-0 pb-9">
				<v-card v-if="isItDone" class="mx-auto px-10 pb-9" :width="cardWidth">
					<v-card-title class="justify-center pt-12"><img v-bind:src="logo.src" v-bind:style="{ width: logo.width, height: logo.height }"></v-card-title>
					<v-card-subtitle class="text-center py-6 headline">{{ subtitle }}</v-card-subtitle>
					<v-divider></v-divider>
					<v-card-subtitle class="text-center py-6 headline">{{ topMessage }}</v-card-subtitle>
					<div class="d-flex justify-end">
						<v-tooltip bottom>
							<template v-slot:activator="{ on, attrs }">
								<v-btn
									icon
									v-bind="attrs"
									v-on="on"
								>
								<v-icon @click="revertIcon"> {{ iconEye }} </v-icon>
								</v-btn>
							</template>
							<span>{{$t('showHideContents')}}</span>
						</v-tooltip>
					</div>
					<v-card-text class="text-center">
						<v-form @submit.prevent="activateAccount">
							<v-text-field
								v-model="password"
								label="New Password"
								placeholder=" "
								name="password"
								outlined
								required
								spellcheck="false"
								:autocomplete="showNewPassword ? 'off' : 'current-password'"
								:rules="[passwordRequired]"
								:type="showInputs ? 'text' : 'password'"
								@click:append="showNewPassword = !showNewPassword"/>

							<v-text-field
								label="Reenter Password"
								placeholder=" "
								v-model="confirmPassword"
								name="password"
								outlined
								required
								spellcheck="false"
								:autocomplete="showPassword ? 'off' : 'current-password'"
								:rules="[confirmPasswordRequired]"
								:type="showInputs ? 'text' : 'password'"
								@click:append="showPassword = !showPassword"
								@keyup.enter="clickButton"/>
							<div v-if="showPin">
								<div class="mb-10">
									<v-text-field
										v-model="pin"
										label="New PIN"
										v-mask="'####'"
										outlined
										dense
										required
										:rules="[pinRequired]"
										:type="showInputs ? 'text' : 'password'"
									></v-text-field>
									<v-text-field
										v-model="confirmPin"
										label="Reenter PIN"
										v-mask="'####'"
										outlined
										dense
										required
										:rules="[confirmPinRequired]"
										:type="showInputs ? 'text' : 'password'"
									></v-text-field>
									<p class="text-justify">
										{{ $t('pinDescription') }}
									</p>
								</div>
								<div class="mb-10">
									<v-text-field
										v-model="panicCode"
										label="New Panic Code"
										v-mask="'####'"
										outlined
										dense
										required
										:rules="[panicCodeRequired]"
										:type="showInputs ? 'text' : 'password'"
									></v-text-field>
									<v-text-field
										v-model="confirmPanicCode"
										label="Reenter Panic Code"
										v-mask="'####'"
										outlined
										dense
										required
										:rules="[confirmPanicCodeRequired]"
										:type="showInputs ? 'text' : 'password'"
									></v-text-field>
									<p class="text-justify">
										{{ $t('panicCodeDescription') }}
									</p>
								</div>
							</div>
						</v-form>
					</v-card-text>
					<v-card-actions class="d-flex justify-center mb-6">
						<v-btn :disabled="completed" class="px-16 py-6" color="primary" @click="activateAccount()">{{ $t('activateAccount') }}</v-btn>
					</v-card-actions>
				</v-card>
				<div v-if="!isItDone" class="d-flex flex-column">
					<p class="text-center white--text messagge-size mb-6">{{ finalMessage }}</p>
					<v-btn class="px-16 py-6 text-center" color="primary" @click="goToLogin()">{{ $t('goToLogin') }}</v-btn>
				</div>
			</div>
			<v-footer color="transparent" absolute padless>
				<v-col class="text-center white--text" cols="12">
					&copy;{{ new Date().getFullYear() }} SaferMobility, LLC &#183; {{ appVersion }}
				</v-col>
			</v-footer>
		</v-container>
	</v-app>
</template>

<script>
import Repository from '@/repositories/RepositoryFactory';
const { LOGIN_LOGO } = require(`@/themes/${process.env.VUE_APP_THEME}/image-paths`);

const UserRepository = Repository.get('client', 'user');

export default {
	data () {
		return {
			appVersion: process.env.VUE_APP_VERSION,
			logo: LOGIN_LOGO,
			loaded: false,
			isItDone: true,
			showInputs: false,
			case: '',
			password: '',
			confirmPassword: '',
			showPassword: false,
			showNewPassword: false,
			pin: '',
			confirmPin: '',
			panicCode: '',
			confirmPanicCode: '',
			showPin: false,
			finalMessage: '',
		};
	},
	watch: {
		visibility (val) {
			if (!val) {
				this.alert = false;
				this.visibility = true;
			}
		},
	},
	computed: {
		passwordRequired () {
			if (this.password.length < 8) return 'Password must have eight or more characters';
			if (this.password !== this.confirmPassword && this.confirmPassword.length > 7) return 'Passwords do not match';
			return true;
		},
		confirmPasswordRequired () {
			if (this.confirmPassword.length < 8) return 'Passwords must have eight or more characters';
			if (this.password !== this.confirmPassword) return 'Passwords do not match';
			return !!this.password;
		},
		pinRequired () {
			if (this.pin !== this.confirmPin && this.confirmPin.length === 3) return 'PINs do not match';
			if (this.pin.length < 4) return 'PIN must have four digits';
			if (this.pin !== this.confirmPin && this.confirmPin.length > 3) return 'PINs do not match';
			return true;
		},
		confirmPinRequired () {
			if (this.pin !== this.confirmPin) return 'PINs do not match';
			return !!this.pin;
		},
		panicCodeRequired () {
			if (this.panicCode !== this.confirmPanicCode && this.confirmPanicCode.length === 3) return 'Panic Codes do not match';
			if (this.panicCode.length < 4) return 'Panic Code must have four digits';
			if (this.panicCode !== this.confirmPanicCode && this.confirmPanicCode.length > 3) return 'Panic Codes do not match';
			if (this.pin === this.panicCode) return 'Panic Code must be different from PIN';
			return true;
		},
		confirmPanicCodeRequired () {
			if (this.confirmPanicCode === this.pin) return 'Panic Code must be different from PIN';
			if (this.panicCode !== this.confirmPanicCode) return 'Panic Codes do not match';
			return !!this.confirmPanicCode;
		},
		passwordFields () {
			if (this.password !== this.confirmPassword) {
				return true;
			}
			return false;
		},
		pinFields () {
			if (this.pin !== this.confirmPin) {
				return true;
			}
			return false;
		},
		panicFields () {
			if (this.panicCode !== this.confirmPanicCode) {
				return true;
			}
			return false;
		},
		completed () {
			if (this.showPin) {
				if (this.passwordRequired === true && this.pinRequired === true && this.panicCodeRequired === true) {
					if (!this.passwordFields && !this.pinFields && !this.panicFields) {
						return false;
					}
				}
				return true;
			} else {
				if (this.password === this.confirmPassword) {
					if (this.passwordRequired === true && this.confirmPasswordRequired === true) {
						return false;
					}
				}
				return true;
			}
		},
		subtitle () {
			return 'Activate Your Acount';
		},
		topMessage () {
			return this.showPin === true ? 'To complete your account activation, set your password, PIN, and Panic Code below.' : 'To complete your account activation, set your password below.';
		},
		iconEye () {
			return this.showInputs === true ? 'mdi-eye' : 'mdi-eye-off';
		},
		loading () {
			return this.loaded;
		},
		cardWidth () {
			var width = window.innerWidth;
			switch (this.$vuetify.breakpoint.name) {
			case 'xs':
				if (width <= 320 && width > 0) {
					return '300px';
				} else if (width <= 375 && width > 320) {
					return '350px';
				} else return '400px';
			case 'sm': return '500px';
			case 'md': return '500px';
			case 'lg': return '500px';
			case 'xl': return '600px';
			default: return '500px';
			}
		},
	},
	methods: {
		async activateAccount () {
			const body = {
				token: this.$route.params.token,
				show_pin: this.showPin,
				password: this.password,
				password_confirm: this.confirmPassword,
				pin: this.pin,
				pin_confirm: this.confirmPin,
				duress: this.panicCode,
				duress_confirm: this.confirmPanicCode,
			};
			const response = await UserRepository.confirmValidAndSetPassword(body);
			if (response.success) {
				this.finalMessage = response.description;
				this.isItDone = false;
			} else {
				this.finalMessage = response.description;
				this.isItDone = false;
			}
		},
		clickButton () {
			return this.activateAccount();
		},
		revertIcon () {
			this.showInputs = !this.showInputs;
		},
		goToLogin () {
			this.$router.push({ name: 'Login' });
		},
	},
	async mounted () {
		try {
			this.loaded = true;
			await this.$store.dispatch('authentication/queryLocationServer', this.$route.params.email);
			await this.$store.dispatch('getCsrfToken');
			const response = await UserRepository.getShowPin(this.$route.params.token);
			this.showPin = response.body?.can_login_mobile;
		} catch (e) {
			this.isItDone = false;
			this.finalMessage = e.message;
		} finally {
			this.loaded = false;
		}
	},
};
</script>
<style scoped>
#main-app {
	background: linear-gradient(#557DBE, #3C5886);
	height: 100%;
	overflow: auto;
}
.messagge-size {
	font-size: 1.5rem;
}
</style>
