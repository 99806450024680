import Repository from '@/repositories/RepositoryFactory';

const RegionRepository = Repository.get('client', 'region');

export default {
	namespaced: true,
	state: {
		regionalOffices: [],
	},
	getters: {

	},
	mutations: {
		LOAD_REGIONAL_OFFICES: (state, payload) => {
			state.regionalOffices = payload;
		},
	},
	actions: {
		async getRegionalOfficesFromRepo ({ commit }) {
			commit('LOAD_REGIONAL_OFFICES', await RegionRepository.get());
		},

		async storeRegionalOffice ({ commit }, object) {
			const response = await RegionRepository.store(object);
			return response;
		},

		async updateRegionalOffice ({ commit }, object) {
			const response = await RegionRepository.update(object);
			return response;
		},

		async updateRegionDetails ({ commit }, object) {
			const response = await RegionRepository.updateRegionDetails(object);
			return response;
		},

		async updateRegionWeekSchedule ({ commit }, object) {
			const response = await RegionRepository.updateRegionWeekSchedule(object);
			return response;
		},

		async updateRegionHolidays ({ commit }, object) {
			const response = await RegionRepository.updateRegionHolidays(object);
			return response;
		},
	},
};
