import Repository from '@/repositories/RepositoryFactory';

const TaskRepository = Repository.get('client', 'task');

export default {
	namespaced: true,
	state: {

	},
	getters: {

	},
	mutations: {

	},
	actions: {
		async dropActiveTask ({ commit }, req) {
			const response = await TaskRepository.dropActiveTask(req);
			return response;
		},
		async dropPausedTask ({ commit }, req) {
			const response = await TaskRepository.dropPausedTask(req);
			return response;
		},
	},
};
