import Repository from '@/repositories/RepositoryFactory';
import { captureException } from '@sentry/browser';
import store from '../store';

const AuthenticationRepository = Repository.get('client', 'authentication');
const AdminAuthenticationRepository = Repository.get('admin', 'authentication');

/**
 * Check if the user has a session.
 *
 * NOTE: How we do this will change when we migrate to APIv2.
 *
 * @param {boolean} checkExpiration Whether to test the API token agains the server
 */
export async function checkLogin (mode = 'common', checkExpiration = false) {
	const getterName = mode === 'admin' ? 'authentication/hasStoredAdminSession' : 'authentication/hasStoredCommonSession';

	if (store.getters[getterName]) {
		if (checkExpiration) {
			try {
				const serverResult = mode === 'admin'
					? await AdminAuthenticationRepository.checkAuth()
					: await AuthenticationRepository.checkAuth();

				if (!serverResult) {
					// If the server says the token is invalid, clear it from localStorage
					logout();
				}
				return serverResult;
			} catch (error) {
				captureException(error);

				// If there was a server error, assume logged out.
				return false;
			}
		} else {
			return true;
		}
	}

	return false;
}

var initialized = false;

/**
 * Vue-Router guard to check authentication
 *
 * @param {*} to
 * @param {*} from
 * @param {*} next
 */
export function requireAuth (to, from, next) {
	if (!initialized) {
		store.commit('authentication/initialize');
		initialized = true;
	}
	if (to.matched.some(record => record.meta.requiresAuth)) {
		checkLogin(to.meta.requiresAuth).then(loggedIn => {
			if (loggedIn) {
				next();
			} else {
				next({
					path: to.meta.requiresAuth === 'admin' ? '/admin/login' : '/login',
					query: { redirect: to.fullPath },
				});
			}
		});

		return;
	}

	next();
}

export async function logout () {
	store.dispatch('authentication/doLogout', {
		type: 'warning',
		message: 'Your session has expired. Please log in again.',
	});
}
