<template>
	<router-view v-if="showView" />
</template>

<script>
import { debounce } from './lib/form-utils';
import { mapState } from 'vuex';
export default {
	name: 'App',

	data: () => ({
	}),
	computed: {
		...mapState({
			userObject: state => state.authentication.userObject,
			csrfToken: state => state.csrfToken,
		}),
		showView () {
			// Nasty hack to delay API calls of all components happen after Vuex updates csrfToken
			if (this.$route.path.includes('/login') || this.$route.path.includes('/resetpassword') || this.$route.path.includes('/validateToken') || this.$route.path.includes('/survey')) {
				return true;
			}
			return this.csrfToken !== null;
		},
	},
	methods: {
		on_resize () {
			this.$store.commit('SET_SCREEN_SIZE', {
				height:	window.innerHeight,
				width: window.innerWidth,
			});
		},
	},
	async mounted () {
		this.$store.commit('SET_SCREEN_SIZE', {
			height:	window.innerHeight,
			width: window.innerWidth,
		});
		window.addEventListener('resize', debounce(this.on_resize, 250));

		if (this.$store.getters['authentication/hasStoredSession']) {
			await this.$store.dispatch('getCsrfToken');

			try {
				await this.$store.dispatch('authentication/doLoginWithToken');

				// If this is a non-admin session, also set up the WebSocket
				if (this.$store.getters['authentication/hasStoredCommonSession'] && this.userObject) {
					await this.$store.dispatch('authentication/queryLocationServer', this.userObject.UserInfos.Email);
					this.$store.dispatch('setupWebSockets');
				}
			} catch (error) {
				this.$store.dispatch('authentication/doLogout', {
					type: 'warning',
					message: 'Your session has expired. Please log in again.',
				});
			}
		}
	},
	beforeDestroy () {
		window.removeEventListener('resize', this.on_resize);
	},
};
</script>

<style scoped theme="safebanker">
#main-app {
	background: linear-gradient(#557DBE, #3C5886)
}
</style>

<style theme="safebanker">
/* #main-app {
	background: #04f;
} */
	.card-title{
		background: #F3F4F6;
		color: #000;
	}
</style>

<style theme="saferesource">
/* #main-app {
	background: #fa0;
} */
	.card-title{
		background: #F3F4F6;
		color: #000;
	}
</style>
