/* eslint-disable camelcase */
import Vue from 'vue';
import {
	extend,
	ValidationObserver,
	ValidationProvider,
} from 'vee-validate';
import {
	email,
	max,
	min,
	numeric,
	required,
	alpha_num,
	regex,
} from 'vee-validate/dist/rules';

extend('required', {
	...required,
	message: 'This field is required',
});

extend('regex', {
	...regex,
	message: 'This field needs to be formatted',
});

extend('max', {
	...max,
	message: 'This field must be {length} characters or less',
});

extend('min', {
	...min,
	message: 'This field must be {length} characters or more',
});

extend('email', {
	...email,
	message: 'This field must be a valid email',
});

extend('numeric', numeric);

extend('alphanumeric', {
	...alpha_num,
	message: 'This field must contain alphabetic characters or numbers',
});

Vue.component('validation-provider', ValidationProvider);
Vue.component('validation-observer', ValidationObserver);
