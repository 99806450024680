import Repository from '@/repositories/RepositoryFactory';

const ActiveSessionRepository = Repository.get('client', 'activeSession');

export default {
	namespaced: true,
	state: {
		activeSessions: [],
	},
	mutations: {
		SET_ACTIIVE_SESSIONS (state, payload) {
			state.activeSessions = payload;
		},
	},
	actions: {
		async getActiveSessions ({ commit }) {
			commit('SET_ACTIIVE_SESSIONS', await ActiveSessionRepository.get());
		},
	},
	getters: {
	},
};
