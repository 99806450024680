import Repository from '@/repositories/RepositoryFactory';

const UserRepository = Repository.get('admin', 'user');

export default {
	namespaced: true,
	state: {
		users: [],
		user: [],
	},
	getters: {

	},
	mutations: {
		LOAD_USERS: (state, payload) => {
			state.users = payload;
		},
		LOAD_EMPLOYEE_BY_ID: (state, payload) => {
			state.user = state.users[payload];
		},
	},
	actions: {
		async getUsersFromRepo ({ commit }) {
			commit('LOAD_USERS', await UserRepository.get());
		},
		async storeUser ({ commit }, object) {
			const response = await UserRepository.store(object);
			return response;
		},
		async updateUser ({ commit }, object) {
			const response = await UserRepository.update(object);
			return response;
		},

		selectedUser ({ commit }, index) {
			commit('LOAD_EMPLOYEE_BY_ID', index);
		},
	},
};
