import User from '@/lib/models/Admin/User';
import { UserError } from '@/lib/Errors';
import $http from '../../../plugins/axios';

export default {
	async get () {
		const body = {
			case: 'GET-ALL-EMPLOYEES',
		};

		const response = await $http.post('/api/admin/getallemployees', body);

		if (response.data?.success) {
			const data = response.data.body.personobject || [];
			const users = data.map(u => {
				const user = new User(u);
				return user;
			});
			return users;
		} else {
			throw new UserError('Error retrieving Users');
		}
	},
	async store (object) {
		object.case = 'ADD-EMPLOYEE';
		object.version = 'new';

		const response = await $http.post('/api/admin/manageemployees', object);

		if (response.data?.status === 200) {
			if (response.data?.success) {
				return response.data.body;
			} else return response.data;
		} else {
			throw new UserError('Error adding employee');
		}
	},
	async update (object) {
		object.case = 'UPDATE-EMPLOYEE';
		object.version = 'new';

		const response = await $http.post('/api/admin/manageemployees', object);

		if (response.data?.status === 200) {
			if (response.data?.success) {
				return response.data.body;
			} else return response.data;
		} else {
			throw new UserError('Error updating employee');
		}
	},
};
