<template>
	<v-dialog
		v-model="isVisible"
		persistent
		max-width="290"
	>
		<v-card>
			<v-card-title class="card-title">{{ title }}</v-card-title>
			<v-card-text class="mt-5">{{ message }}</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn
					color="primary"
					:text="defaultButton !== 'no'"
					small
					@click="clickNo"
				>
					{{ $t('no') }}
				</v-btn>
				<v-btn
					color="primary"
					:text="defaultButton !== 'yes'"
					small
					@click="clickYes"
				>
					{{ $t('yes') }}
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	name: 'ConfirmDialog',
	data () {
		return {
			isVisible: false,
			title: '',
			message: '',
			resolvePromise: undefined,
			rejectPromise: undefined,
			defaultButton: 'yes',
		};
	},
	methods: {
		open ({ title, message, defaultButton = 'yes' }) {
			this.title = title;
			this.message = message;
			this.defaultButton = defaultButton;

			this.isVisible = true;

			return new Promise((resolve, reject) => {
				this.resolvePromise = resolve;
				this.rejectPromise = reject;
			});
		},
		clickYes () {
			this.isVisible = false;
			this.resolvePromise(true);
		},
		clickNo () {
			this.isVisible = false;
			this.resolvePromise(false);
		},
	},
};
</script>
