import VideoSession from '@/lib/models/Client/VideoSession';
import { VideoError } from '@/lib/Errors';
import $http from '../../../plugins/axios';

export default {
	async get (object) {
		const body = {
			case: 'GET-ALL-INCIDENTS',
			filter: JSON.stringify(object),
			offset: '0',
			limit: '200',
		};

		const response = await $http.post('/api/client/getincidents', body);

		if (response.data?.success) {
			const data = response.data.body.resultobject || [];
			if (data) {
				const videoSessions = data.map(c => {
					const video = new VideoSession(c);
					return video;
				});
				return videoSessions;
			} else {
				return [];
			}
		} else {
			throw new VideoError('Error retrieving Videos');
		}
	},
	// getSessionDetailsBySessionId (id) {
	// 	const headers = {
	// 		'Content-Type': 'application/json',
	// 	};

	// 	const body = {
	// 		param_session_id: id,
	// 	};

	// 	const data = doFetch('/client/getSessionDetailsBySessionId', 'POST', headers, body)
	// 		.then(json => {
	// 			if (json.success) {
	// 				const response = json.body.resultobject;
	// 				const chatSessionDetails = new ChatSessionDetails(response);
	// 				return chatSessionDetails;
	// 			} else {
	// 				throw new ChatMessageError('Error retrieving Chats');
	// 			}
	// 		});
	// 	return data;
	// },
};
