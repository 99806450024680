<template>
  <v-row justify="center">
    <v-dialog
		v-model="dialog"
		persistent
		scrollable
		min-width="900px"
		width="900px"
		max-width="900px"
		style="z-index: 9999"
    >
      <v-card>
       <v-card-title class="card-title">
          <span>{{ $t('emergencyNotification') }}</span>
            <v-spacer></v-spacer>
            <v-btn
                icon
                small
                @click="close"
            >
                <v-icon color="primary">mdi-close</v-icon>
            </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
				<v-col class="mt-8" cols="12" md="6" sm="6" fluid>
				<v-autocomplete v-if="isSecurityUser" :items="selectableData.organizations" v-model="selectedOrganization" item-text="name" item-value="id" :label="$t('client')" @change="getRegionalOffices" outlined dense></v-autocomplete>
                <v-autocomplete v-if="!isRegionalManager && !isBranchManager" :items="selectableData.regions" v-model="selectedRegion" item-text="name" item-value="id" :label="$t('region')" @change="getAllBranchOffices" outlined dense></v-autocomplete>
				<v-autocomplete v-if="!isBranchManager" v-model="selectedBranch" :items="selectableData.branches" item-text="name" item-value="id" :label="$t('location')" @change="getEmployeesAndMessages(selectedBranch)" outlined dense></v-autocomplete>
                <v-autocomplete :items="selectableData.ensTemplates" v-model="selectedEns" item-text="shortMsg" item-value="id" :label="$t('selectMessage')" outlined dense></v-autocomplete>
                <v-textarea
                    v-if="selectedEns && selectableData.ensTemplates.length"
                    v-model="selectableData.ensTemplates[selectedEns-1].description"
                    outlined
                    dense
                >
                </v-textarea>
				<v-overlay :value="overlay">
					<v-progress-circular indeterminate size="64" />
				</v-overlay>
                </v-col>
                <v-col cols="12" md="6" sm="6" fluid>
                    <ListUsers v-if="branchEmployees.length" :users="branchEmployees" usersType="ens" :selectedUsers="selectedEmployeesForEns" @ens-list="updateSelectedUsers"></ListUsers>
                </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- <v-btn
            color="blue darken-1"
            text
            @click="close"
          >
            {{ $t('close') }}
          </v-btn> -->
          <v-btn
            color="primary"
            @click="send"
			:disabled="!selectedEmployeesForEns.length"
          >
            {{ $t('send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
	<v-snackbar
		v-model="alert"
		:multi-line="false"
		color="error"
	>
		{{ errorMessage }}

		<template v-slot:action="{ attrs }">
			<v-btn
			color="white"
			text
			v-bind="attrs"
			@click="alert = false"
			>
			{{ $t('close') }}
			</v-btn>
		</template>
	</v-snackbar>
  </v-row>
</template>

<script>
import ListUsers from './ListUsers.vue';
import { mapState } from 'vuex';
import { BranchOfficeError, NotificationError } from '@/lib/Errors';

export default {
	name: 'EmergencyDialog',
	// icon: 'mdi-telegram',
	components: {
		ListUsers,
	},
	props: ['dialog', 'selectedObj'],
	data: () => ({
		overlay: false,
		selectableData: {
			organizations: [],
			regions: [],
			branches: [],
			ensTemplates: [],
		},
		selectedOrganization: null,
		selectedRegion: null,
		selectedBranch: null,
		selectedEns: null,
		branchEmployees: [],
		selectedEmployeesForEns: [],
		alert: false,
		errorMessage: '',
	}),
	methods: {
		getOrganizations () {
			this.$store.dispatch('organisation/get')
				.then(() => {
					this.selectableData.organizations = this.organizations.filter(o => {
						return o.StatusId === 1;
					}).map((o) => {
						return {
							id: o.Id,
							name: o.Name,
						};
					});
				});
		},
		getRegionalOffices () {
			this.selectableData.branches = [];
			this.selectableData.ensTemplates = [];
			this.branchEmployees = [];
			this.selectedEmployeesForEns = [];
			this.selectedRegion = null;
			this.selectedBranch = null;
			this.selectedEns = null;
			this.$store.dispatch('regions/getRegionalOfficesFromRepo', { root: true })
				.then(data => {
					this.selectableData.regions = this.regionalOffices
						.filter((r) => {
							if (this.isSecurityUser) {
								return r.Status === 'Active' && r.OrganizationId === this.selectedOrganization;
							} else {
								return r.Status === 'Active';
							}
						}).map((r) => {
							return {
								id: r.Id,
								name: r.Name,
							};
						});
				});
		},
		getAllBranchOffices (regionId) {
			this.selectableData.ensTemplates = [];
			this.branchEmployees = [];
			this.selectedEmployeesForEns = [];
			this.selectedBranch = null;
			this.selectedEns = null;
			this.$store.dispatch('branches/getBranchOfficesFromRepo', { root: true })
				.then(() => {
					this.selectableData.branches = this.branchOffices
						.filter((b) => {
							return b.Status === 'Active' && b.RegionalOfficeId === regionId;
						}).map((b) => {
							return {
								id: b.Id,
								name: b.Name,
							};
						});
				});
		},
		async getAllBranches () {
			this.$store.dispatch('branches/getBranchOfficesFromRepo')
				.then(() => {
					this.filterBranchesOnStatus();
				});
		},
		getEnsMessageTemplate (branchId) {
			if (this.isSecurityUser && this.selectedOrganization === null && this.selectedRegion === null) {
				const selectedBranchData = this.selectableData.branches.filter(b => {
					return b.id === this.selectedBranch;
				});
				this.selectedOrganization = selectedBranchData[0].organizationId;

				this.$store.dispatch('regions/getRegionalOfficesFromRepo')
					.then(() => {
						this.selectableData.regions = this.regionalOffices
							.filter((r) => {
								return r.Status === 'Active' && r.OrganizationId === this.selectedOrganization;
							}).map((r) => {
								return {
									id: r.Id,
									name: r.Name,
								};
							});
					}).then(() => {
						this.selectedRegion = selectedBranchData[0].regionId;
					});
			}
			if (!this.isBranchManager && this.selectedRegion === null) {
				const selectedBranchData = this.selectableData.branches.filter(b => {
					return b.id === this.selectedBranch;
				});
				this.selectedRegion = selectedBranchData[0].regionId;
			}
			const requestObject = {
				branchId: branchId,
			};
			this.$store.dispatch('notifications/getEnsMessageTemplate', requestObject)
				.then(data => {
					this.selectableData.ensTemplates = data
						.map((m) => {
							return {
								id: m.MessageTemplateId,
								shortMsg: m.ShortMsg,
								description: m.Description,
							};
						});
				})
				.catch(error => {
					if (error instanceof NotificationError) {
						this.errorMessage = error.message;
					} else {
						this.errorMessage =
							'Error communicating with server. Please try again.';
					}
				});
		},
		getBranchEmployees (id) {
			this.branchEmployees = [];
			this.selectedEmployeesForEns = [];
			this.$store.dispatch('branches/getBranchEmployees', id)
				.then(() => {
					this.branchEmployees = this.branchEmployeesFromRepo;
				})
				.catch(error => {
					if (error instanceof BranchOfficeError) {
						this.errorMessage = error.message;
					} else {
						this.errorMessage =
							'Error communicating with server. Please try again.';
					}
				});
		},
		updateSelectedUsers (selectedUsers) {
			this.selectedEmployeesForEns = selectedUsers;
		},
		getEmployeesAndMessages (branchId) {
			this.getEnsMessageTemplate(branchId);
			this.getBranchEmployees(branchId);
		},
		close () {
			this.selectableData.branches = [];
			this.selectedRegion = null;
			this.selectedBranch = null;
			this.selectedEns = null;
			this.branchEmployees = [];
			this.selectedEmployeesForEns = [];
			this.$emit('emergency-dialog-closed');
		},
		send () {
			const requestObject = {
				case: 'NOTIFY-EMEGREGENCY',
				organizationId: this.selectedOrganization !== null ? `${this.selectedOrganization}` : `${this.userObject.UserInfos.OrganizationId}`,
				regionId: this.selectedRegion !== null ? `${this.selectedRegion}` : this.userObject.UserInfos.WorksIn[0].RegionalOfficeId,
				branchId: this.selectedBranch !== null ? `${this.selectedBranch}` : this.userObject.UserInfos.WorksIn[0].BranchOfficeId,
				fromUserId: `${this.userObject.UserInfos.UserId}`,
				toUsersId: '{' + this.selectedEmployeesForEns + '}',
				message: this.selectableData.ensTemplates[this.selectedEns - 1].description,
				notificationMethod: 'ALL',
				msgtemplateid: `${this.selectedEns}`,
			};

			this.$store.dispatch('notifications/sendEnsMessage', requestObject)
				.then(res => {
					if (res.resflag) {
						this.selectableData.branches = [];
						this.selectableData.ensTemplates = [];
						this.selectedRegion = null;
						this.selectedBranch = null;
						this.selectedEns = null;
						this.branchEmployees = [];
						this.selectedEmployeesForEns = [];
						this.close();
					} else {
						this.errorMessage = res.description;
						this.alert = true;
					}
				})
				.catch(error => {
					if (error instanceof NotificationError) {
						this.errorMessage = error.message;
					} else {
						this.errorMessage =
                        'Error communicating with server. Please try again.';
					}
				});
		},
		filterBranchesOnStatus () {
			this.selectableData.branches = this.branchOffices
				.filter((b) => {
					return b.Status === 'Active';
				}).map((b) => {
					return {
						id: b.Id,
						name: b.Name,
						regionId: b.RegionalOfficeId,
						organizationId: b.OrganizationId,
					};
				});
		},
	},
	computed: {
		...mapState({
			organizations: state => state.organisation.organisations,
			regionalOffices: state => state.regions.regionalOffices,
			branchOffices: state => state.branches.branchOffices,
			branchEmployeesFromRepo: state => state.branches.branchEmployees,
			userObject: state => state.authentication.userObject,
			roleId: state => state.authentication.roleId,
		}),
		isSecurityUser () {
			return this.roleId === 5 || this.roleId === 8;
		},
		isRegionalManager () {
			return this.roleId === 9;
		},
		isBranchManager () {
			return this.roleId === 6;
		},
	},
	mounted () {
		this.overlay = true;
		if (this.isSecurityUser) {
			this.getOrganizations();
			this.getRegionalOffices();
		} else if (this.isRegionalManager) {
			this.getAllBranchOffices(this.userObject.UserInfos.WorksIn[0].RegionalOfficeId);
		} else if (this.isBranchManager) {
			this.getBranchEmployees(this.userObject.UserInfos.UserAtWorksIn[0].BranchOfficeId);
			this.getEnsMessageTemplate(this.userObject.UserInfos.UserAtWorksIn[0].BranchOfficeId);
		} else {
			this.getRegionalOffices();
		}
		this.getAllBranches();

		if (this.selectedObj !== null && typeof this.selectedObj !== 'undefined') {
			this.selectedOrganization = this.selectedObj.org;
			this.selectedRegion = this.selectedObj.region;
			this.selectedBranch = this.selectedObj.branch;
			this.getEmployeesAndMessages(this.selectedBranch);
		}
		this.overlay = false;
	},
	watch: {
		async selectedRegion (val) {
			if (val) {
				this.overlay = true;
				await this.filterBranchesOnStatus();
				this.overlay = false;
			}
		},
	},
};
</script>

<style scoped>
	.v-dialog > .v-card > .v-card__title {
        background: #F3F4F6;
        color: #000;
        height: 40px;
        padding: 5px 20px;
    }
</style>
