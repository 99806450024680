import Repository from '@/repositories/RepositoryFactory';
import Vue from 'vue';
import { getNow } from '@/utils/luxon-formater';

const ChatMessageRepository = Repository.get('client', 'chatMessage');

export default {
	namespaced: true,

	state: {
		chatSessions: [],
		messages: {},
		acceptedChat: null,
	},
	mutations: {
		UPDATE_ACCEPTED_CHAT (state, chatRoomId) {
			const chatRoomIndex = state.chatSessions.findIndex(c => c.ChatRoomId === chatRoomId);
			state.acceptedChat = chatRoomId;
			state.chatSessions[chatRoomIndex].status = 'active';
		},
		HANDLE_CHAT_MESSAGE_TYPES (state, data) {
			const msgType = data.Type || data.type;
			let chatRoomIndex = state.chatSessions.findIndex(c => c.ChatRoomId === data.ChatRoomId);
			const calleridIndex = state.chatSessions.findIndex(c => c.CallerId === data.CallerId);

			switch (msgType) {
			case 'mobileChatRequest':
				if (state.chatSessions[chatRoomIndex]) {
					// TODO: How to handle duplicate request?
					// For now we just ignore it.
					return;
				}
				state.chatSessions.push(data);
				chatRoomIndex = state.chatSessions.findIndex(c => c.ChatRoomId === data.ChatRoomId);
				state.chatSessions[chatRoomIndex].status = 'incoming';
				break;
			case 'onChatRequest':

				break;
			case 'initmobile':
				break;
			case 'terminateChatRequest':
				state.chatSessions.splice(calleridIndex, 1);
				break;
			case 'terminateChatRequestFromMobile':
				state.chatSessions.splice(chatRoomIndex, 1);
				break;
			case 'mobileChatRoomCreated':
				// state.messages[data.ChatRoomId] = [];
				Vue.set(state.messages, data.ChatRoomId, [{
					fullName: 'Me',
					source: 'console',
					msg: 'What is your emergency?',
					msgSentTime: getNow(),
					currentLocation: '',
				}]);
				break;
			case 'chatMsgFromMobile':
				state.messages[data.ChatRoomId].push({
					fullName: data.FullName,
					source: 'mobile',
					msg: data.msg,
					msgSentTime: data.msgSentTime,
					currentLocation: data.CurrentLocation,
				});
				break;
			case 'mobileChatClosed':
				// TODO: Hide chat pane
				state.chatSessions.splice(chatRoomIndex, 1);
				delete state.messages[data.ChatRoomId];
				break;
			case 'webChatClosed':
				state.chatSessions.splice(chatRoomIndex, 1);
				delete state.messages[data.ChatRoomId];
				break;
			case 'chatMsgFromOtherConsole':

				break;
			case 'silentTerminateChatRequest':

				break;
			default:
				break;
			}
		},
		HANDLE_CHAT_MESSAGE_SEND (state, data) {
			state.messages[data.chatRoomId].push(data.message);
		},
		CHAT_CLOSED_FROM_WEB (state, ChatRoomId) {
			const chatRoomIndex = state.chatSessions.findIndex(c => c.ChatRoomId === ChatRoomId);
			state.chatSessions.splice(chatRoomIndex, 1);
			delete state.messages[ChatRoomId];
		},
		UPDATE_CHAT_STATUS (state, data) {
			const chatRoomIndex = state.chatSessions.findIndex(c => c.ChatRoomId === data.ChatRoomId);
			// Status can be: hold, active, incoming
			state.chatSessions[chatRoomIndex].status = data.status;
		},
	},
	actions: {
		async sendChatMessage ({ commit }, object) {
			const response = await ChatMessageRepository.sendChatMessage(object);
			return response;
		},
		async answerChatFromConsole ({ commit }, object) {
			const response = await ChatMessageRepository.answerChatFromConsole(object);
			return response;
		},
		async finishChat ({ commit }, object) {
			const response = await ChatMessageRepository.finishChat(object);
			return response;
		},
		async holdChat ({ commit }, object) {
			const response = await ChatMessageRepository.holdChat(object);
			return response;
		},
	},
	getters: {
		getMessages (state) {
			return state.messages[state.acceptedChat];
		},
	},
};
