import Operator from '@/lib/models/Client/Operator';
import { OperatorError } from '@/lib/Errors';
import $http from '../../../plugins/axios';

export default {
	async get () {
		const body = {
			case: 'GET-KNOWN-OPERATORS',
		};

		const response = await $http.post('/api/client/getknownoperators', body);

		if (response.data?.success) {
			const data = response.data.body.organizationobject || [];
			const operators = data.map(o => {
				const operator = new Operator(o);
				return operator;
			});
			return operators;
		} else {
			throw new OperatorError('Error retrieving Users');
		}
	},
};
