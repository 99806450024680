import SecurityOfficer from '@/lib/models/Client/SecurityOfficer';
import { SecurityOfficerError } from '@/lib/Errors';
import $http from '../../../plugins/axios';

export default {
	async get () {
		const body = {
			case: 'GET-ALL-SECURITY-OFFICERS',
		};

		const response = await $http.post('/api/client/getallemployees', body);

		if (response.data?.success) {
			const data = response.data.body.personobject || [];
			const users = data.map(u => {
				const user = new SecurityOfficer(u);
				return user;
			});
			return users;
		} else {
			throw new SecurityOfficerError('Error retrieving Users');
		}
	},
};
