import { TaskError } from '@/lib/Errors';
import $http from '../../../plugins/axios';

export default {
	async dropActiveTask (body) {
		body.case = 'DELETE-ACTIVETASK-BY-ACTIVEID';

		const response = await $http.post('/api/client/dropactivetask', body);

		if (response.data?.success) {
			return response.data.body;
		} else {
			throw new TaskError('Error clearing task');
		}
	},
	async activateTask (body) {
		body.case = 'ACTIVATE-TASKING';

		const response = await $http.post('/api/client/activatetask', body);

		if (response.data?.success) {
			return response.data.body;
		} else {
			throw new TaskError('Error activating task');
		}
	},
	async dropPausedTask (body) {
		body.case = 'FORCE-END-TASKING';

		const response = await $http.post('/api/client/activatetask', body);

		if (response.data?.success) {
			return response.data.body;
		} else {
			throw new TaskError('Error activating task');
		}
	},
};
