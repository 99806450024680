import parsePhoneNumberFromString from 'libphonenumber-js';

/**
 * Format the (probably E.164) phone number for display.
 *
 * Safely handles NULL and invalid inputs:
 *  - NULL returns empty string
 *  - INVALID returns initial input
 */
export default function safeFormatPhone (phone) {
	return parsePhoneNumberFromString(phone ?? '', 'US')?.formatInternational() ?? phone ?? '';
}
