import ClientOrganizationRepository from './v1/Client/OrganizationRepository';
import AdminOrganizationRepository from './v1/Admin/OrganizationRepository';
import AdminRoleRepository from './v1/Admin/RoleRepository';
import AdminSecurityOffice from './v1/Admin/SecurityOfficeRepository';
import AdminUser from './v1/Admin/UserRepository';
import RegionRepository from './v1/Client/RegionRepository';
import BranchRepository from './v1/Client/BranchRepository';
import HeadOfficeRepository from './v1/Client/HeadOfficeRepository';
import AuthenticationRepository from './v1/Client/AuthenticationRepository';
import AdminAuthenticationRepository from './v1/Admin/AuthenticationRepository';
import SecurityOfficeRepository from './v1/Client/SecurityOfficeRepository';
import UserRepository from './v1/Client/UserRepository';
import ClientRoleRepository from './v1/Client/RoleRepository';
import OperatorRepository from './v1/Client/OperatorRepository';
import TaskListRepository from './v1/Client/TaskListRepository';
import SurveyRepository from './v1/Client/SurveyRepository';
import SecurityOfficerRepository from './v1/Client/SecurityOfficerRepository';
import ChatMessageRepository from './v1/Client/ChatMessageRepository';
import ReportRepository from './v1/Client/ReportRepository';
import NotificationRepository from './v1/Client/NotificationRepository';
import ActiveSessionRepository from './v1/Client/ActiveSessionRepository';
import TaskRepository from './v1/Client/TaskRepository';
import VideoRecRepository from './v1/Client/VideoRecRepository';
import SecurityRepository from './v1/SecurityRepository';
import LocationServerRepository from './v1/LocationServerRepository';
import SecurityPhraseRepository from './v1/Client/SecurityPhraseRepository';

const repositories = {
	common: {
		location: LocationServerRepository,
		security: SecurityRepository,
	},
	admin: {
		authentication: AdminAuthenticationRepository,
		organization: AdminOrganizationRepository,
		role: AdminRoleRepository,
		securityOffice: AdminSecurityOffice,
		user: AdminUser,
	},
	client: {
		organization: ClientOrganizationRepository,
		region: RegionRepository,
		headOffice: HeadOfficeRepository,
		authentication: AuthenticationRepository,
		branch: BranchRepository,
		securityOffice: SecurityOfficeRepository,
		user: UserRepository,
		role: ClientRoleRepository,
		operator: OperatorRepository,
		tasklist: TaskListRepository,
		survey: SurveyRepository,
		securityOfficer: SecurityOfficerRepository,
		chatMessage: ChatMessageRepository,
		report: ReportRepository,
		notification: NotificationRepository,
		activeSession: ActiveSessionRepository,
		task: TaskRepository,
		videorec: VideoRecRepository,
		securityPhrase: SecurityPhraseRepository,
	},
};

export default {
	get: (type, name) => {
		if (repositories[type][name]) {
			return repositories[type][name];
		} else if (repositories.common[name]) {
			return repositories.common[name];
		} else {
			throw new Error('Invalid Repository Type');
		}
	},
};
