import RegionalOffice from '@/lib/models/Client/RegionalOffice';
import { RegionalOfficeError } from '@/lib/Errors';
import $http from '../../../plugins/axios';

async function addOrUpdateRegion (body) {
	const response = await $http.post('/api/client/addregionaloffice', body);
	if (response.data?.status === 200) {
		if (response.data?.success) {
			const res = {
				body: response.data.body,
				newRegion: new RegionalOffice(response.data.body.organizationobject),
			};
			return res;
		} else return response.data;
	} else {
		throw new RegionalOfficeError('Error saving Region');
	}
}

async function modifyRegion (body, errorMessage) {
	const response = await $http.post('/api/client/manageregionaloffices', body);

	if (response.data?.status === 200) {
		if (response.data?.success) {
			return response.data.body;
		} else return response.data;
	} else {
		throw new RegionalOfficeError(errorMessage);
	}
}

export default {
	async get () {
		const body = {
			case: 'GET-REGIONAL-OFFICES',
		};

		const response = await $http.post('/api/client/getoffices', body);

		if (response.data?.success) {
			const data = response.data.body.organizationobject || [];
			const regionalOffices = data.map(reg => {
				const region = new RegionalOffice(reg);
				return region;
			});
			return regionalOffices;
		} else {
			throw new RegionalOfficeError('Error retrieving Regions');
		}
	},
	async store (object) {
		object.case = 'ADD-REGIONAL-OFFICE';

		return addOrUpdateRegion(object);
	},
	async update (object) {
		object.case = 'UPDATE-REGIONAL-OFFICE';

		return addOrUpdateRegion(object);
	},
	async updateRegionDetails (object) {
		object.case = 'UPDATE-REGIONAL-OFFICE-DETAILS';

		return modifyRegion(object, 'Error saving Region Details');
	},
	async updateRegionWeekSchedule (object) {
		object.case = 'UPDATE-REGIONAL-OFFICE-WEEKSCHEDULE';

		return modifyRegion(object, 'Error saving Region Week Schedule');
	},
	async updateRegionHolidays (object) {
		object.case = 'UPDATE-REGIONAL-OFFICE-HOLIDAYS';

		return modifyRegion(object, 'Error saving Region Holidays');
	},
	async updateTimers (object) {
		object.case = 'UPDATE-REGIONAL-OFFICE-TIMERS';

		return modifyRegion(object, 'Error saving Region Default Timers');
	},
};
