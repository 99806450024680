import axios from 'axios';
import { APIServerError } from '../lib/Errors';

const $http = axios.create({
	withCredentials: true,
});

/**
 * Set up interceptors for catching permission problems and CSRF Tokens
 *
 * @param {*} router Vue-Router
 * @param {*} param1 Vuex Store
 */
export function setupInterceptors ({ commit, dispatch, state }) {
	$http.interceptors.request.use((request) => {
		if ($http.defaults.baseURL == null || $http.defaults.baseURL.length === 0) {
			throw new APIServerError('Could not find server to connect');
		}

		// NOTE: We set the CSRF token here every time because it changes,
		//       but the session token doesn't change so we set it as a default
		//       header in the `setAuthToken` function below.

		if (state.csrfToken && !request.headers['X-CSRF-TOKEN']) {
			request.headers['X-CSRF-TOKEN'] = state.csrfToken;
		}

		return request;
	});

	$http.interceptors.response.use((response) => {
		if (response.headers['x-xsrf-token']) {
			commit('CSRF_TOKEN', response.headers['x-xsrf-token']);
		}
		return response;
	}, (error) => {
		if (error.response.status === 401) {
			dispatch('authentication/doLogout', {
				type: 'warning',
				message: 'Your session has expired. Please log in again.',
			});
			return error;
		}

		if (error.response.headers['x-xsrf-token']) {
			commit('CSRF_TOKEN', error.response.headers['x-xsrf-token']);
		}

		return error;
	});
}

export function setBaseURL (url) {
	$http.defaults.baseURL = url;
}

export function setAuthToken (token) {
	$http.defaults.headers.Authorization = token ? `Bearer ${token}` : null;
}

export function requireAuthToken () {
	if ($http.defaults.headers.Authorization == null || $http.defaults.headers.Authorization.length < 8) {
		throw new APIServerError('Session token not found');
	}
}

export default $http;
