export default {
	bind (el, binding, vnode) {
		function documentHandler (e) {
			if (el.contains(e.target)) {
				return false;
			}
			if (binding.expression) {
				binding.value(e);
			}
		}
		el.__vueClickOutside__ = documentHandler;
		document.addEventListener('click', documentHandler, true);
	},
	update () {},
	unbind (el, binding) {
		document.removeEventListener('click', el.__vueClickOutside__, true);
		delete el.__vueClickOutside__;
	},
};
