import Repository from '@/repositories/RepositoryFactory';

const SecurityOfficerRepository = Repository.get('client', 'securityOfficer');

export default {
	namespaced: true,
	state: {
		securityOfficers: [],
	},
	getters: {

	},
	mutations: {
		LOAD_SECURITY_OFFICERS: (state, payload) => {
			state.securityOfficers = payload;
		},
	},
	actions: {
		async getSecurityOfficersFromRepo ({ commit }) {
			commit('LOAD_SECURITY_OFFICERS', await SecurityOfficerRepository.get());
		},
	},
};
