<template>
	<v-app id="main-app">
		<v-container class="fill-height" v-if="loading">
			<v-card width="100%">
				<v-card-title>
					<span>{{$t('surveyTemplate')}}</span>
					<v-spacer></v-spacer>
				</v-card-title>
				<v-card-text>
					<v-progress-circular
						indeterminate
						:size="50"
						color="primary"
					></v-progress-circular>
				</v-card-text>
			</v-card>
		</v-container>
		<v-container class="fill-height" style="justify-content: center;" v-if="!submited && !loading">
		<div class="text-center mx-auto px-10 pb-9" v-if="!surveyexternalusersobject">
			<v-progress-circular
				indeterminate
				:size="50"
				color="white"
			></v-progress-circular>
		</div>
		<v-card
			width="50%"
			v-else
		>
			<v-card-title>
				<span>{{$t('surveyTemplate')}}</span>
				<v-spacer></v-spacer>
			</v-card-title>
			<div class="list-height overflow-y-auto pl-2 pr-2" v-if="!finish">
				<v-card class="mx-auto mb-2 mt-2" max-width="100%" v-if="surveyexternalusersobject.Name">
					<v-card-text>
						<v-row>
							<v-col cols="12" md="12">
								<v-text-field disabled :label="$t('surveyName')" :placeholder="$t('surveyName')" dense outlined v-model="surveyexternalusersobject.Name"></v-text-field>
								<label class="caption text--secondary">{{ $t('surveyDescription') }}</label>
								<p class="text--primary">{{ surveyexternalusersobject.Description }}</p>
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>
				<v-card
					class="mx-auto mb-2"
					max-width="100%"
					v-for="(question, index) in showQuestion"
					:key="index"
				>
					<v-card-text ref="different" :key="one">
						<v-row>
							<v-col cols="12" md="12">
								<p class="text--primary ml-3 mb-0">{{ question.Question }}</p>
							</v-col>
						</v-row>
						<v-row align="center" dense v-for="(answer, i) in question.Answers" :key="i">
							<v-col cols="12" md="6">
									<label class="caption text--secondary ml-3">{{ $t('answer') }}</label>
									<v-checkbox
										hide-details
										class="shrink ml-3 mr-2 mt-0"
										v-model="answer.Selected"
										small
										:label="answer.Option"
										@change="checkboxUpdated(answer, question.QuestionId, index, i)"
									></v-checkbox>
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>
			</div>
			<div v-else>
				<v-card class="mx-auto mb-2 mt-2" max-width="100%" v-if="surveyexternalusersobject.Name">
					<v-card-text>
						<v-row>
							<v-col cols="12" md="12">
								<label class="caption text--secondary">{{ $t('surveyName') }}</label>
								<p class="text--primary">{{ surveyexternalusersobject.Name }}</p>
								<!-- <v-text-field disabled :label="$t('surveyName')" :placeholder="$t('surveyName')" dense outlined v-model="surveyexternalusersobject.Name"></v-text-field> -->
								<label class="caption text--secondary">{{ $t('surveyDescription') }}</label>
								<p class="text--primary">{{ surveyexternalusersobject.Description }}</p>
							</v-col>
						</v-row>
					</v-card-text>
					<v-card-text v-if="!notifyRecommendation" class="pl-5">
						<p class="text--primary">{{ $t('surveyRecommendations') }}</p>
						<v-row dense v-for="(answer, i) in recommendationArray" :key="i">
							<span>- {{ answer }}</span>
						</v-row>
					</v-card-text>
					<v-card-text v-else class="pl-5">
						<p class="text--primary">{{ $t('surveyRecommendations') }}</p>
						<v-row dense>
							<span>- {{ message }}</span>
						</v-row>
					</v-card-text>
				</v-card>
			</div>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color="" :disabled="one === 1 || submited === true" @click="goBack">{{  $t('previous') }}</v-btn>
				<v-btn color="primary" :disabled="!finish" @click="submitSurveyAnswers">{{  $t('submitSurvey') }}</v-btn>
			</v-card-actions>
		</v-card>
		<v-footer color="transparent" absolute padless>
			<v-col class="text-center white--text" cols="12">
				&copy;{{ new Date().getFullYear() }} SaferMobility, LLC &#183; {{ appVersion }}
			</v-col>
		</v-footer>
		</v-container>
		<v-container class="fill-height" v-if="!loading && submited">
			<v-card class="mx-auto px-10 pb-9" width="450px">
				<v-card-title class="justify-center pt-12"><img v-bind:src="logo.src" v-bind:style="{ width: logo.width, height: logo.height }"></v-card-title>
				<v-card-subtitle class="text-center py-6 headline">Submitted successfully</v-card-subtitle>
				<v-card-text class="text-center">
					<AlertListAuth />
				</v-card-text>
				<v-card-actions>
				</v-card-actions>
			</v-card>
		</v-container>
	</v-app>
</template>

<script>
import axios from 'axios';
import AlertListAuth from '@/components/base/AlertListAuth.vue';
const { LOGIN_LOGO } = require(`@/themes/${process.env.VUE_APP_THEME}/image-paths`);

export default {
	name: 'SurveyExternalUser',
	components: {
		AlertListAuth,
	},
	data () {
		return {
			appVersion: process.env.VUE_APP_VERSION,
			logo: LOGIN_LOGO,
			surveyexternalusersobject: {},
			questions: {},
			SurveyId: null,
			endNotification: false,
			submited: false,
			lat: 21,
			long: 42,
			loading: true,
			notifyRecommendation: null,
			nonNotifyRecommendation: null,
			answersArray: [],
			testvariable: true,
			showQuestion: [],
			one: 1,
			recommendationArray: [],
			finish: false,
			message: null,
		};
	},
	async mounted () {
		await this.getLocation();
	},
	async created () {
		await this.getSurveyById();
	},
	methods: {
		goBack () {
			if (this.notifyRecommendation) {
				this.message = null;
			}
			this.recommendationArray.pop();
			this.answersArray.pop();
			this.one = this.answersArray.length + 1;
			this.showQuestion.pop();
			this.showQuestion.push(this.questions[this.one - 1]);
			if (this.finish) {
				this.finish = false;
			}
		},
		checkboxUpdated (answer, id, index, i) {
			this.questions.forEach(c => {
				if (c.Question === answer.Question) {
					c.Answers.forEach(an => {
						if (an.Option === answer.Option) {
							an.Selected = true;
						} else an.Selected = false;
					});
				}
			});
			if (answer.EndSurveyWithNotification) {
				if (this.notifyRecommendation) {
					this.message = this.notifyRecommendation;
				}
				this.recommendationArray.push(answer.Action);
				this.answersArray.push({
					QuestionId: answer.QuestionId,
					Answer: answer.Option,
				});
				this.endNotification = true;
				this.finish = true;
				this.one = 0;
			} else {
				if (this.notifyRecommendation) {
					this.message = this.nonNotifyRecommendation;
				}
				this.recommendationArray.push(answer.Action);
				this.answersArray.push({
					QuestionId: answer.QuestionId,
					Answer: answer.Option,
				});
				this.showQuestion = [];
				this.one++;
				if (this.one <= this.questions.length) {
					this.showQuestion.push(this.questions[this.one - 1]);
				} else this.finish = true;
			}
		},
		async getSurveyById () {
			var inputs = {
				token: `${this.$route.params.token}`,
				case: 'GET-SURVEY',
				branchId: `${this.$route.params.id}`,
			};
			await axios.post('https://sbdev.bridge-techs.com/api/client/managesurveysexternaluser', inputs)
				.then((response) => {
					this.surveyexternalusersobject = response.data.body.surveyexternalusersobject;
					this.notifyRecommendation = response.data.body.surveyexternalusersobject.NotifyRecomandation;
					this.nonNotifyRecommendation = response.data.body.surveyexternalusersobject.NonNotifyRecomandation;
					this.questions = response.data.body.surveyexternalusersobject.Questions;
					this.SurveyId = response.data.body.surveyexternalusersobject.SurveyId;
				}).then(() => {
					this.questions.filter(f => {
						f.Answers.forEach(a => {
							a.Question = f.Question;
							a.QuestionId = f.QuestionId;
							a.Selected = false;
						});
					});
					this.showQuestion.push(this.questions[0]);
					this.loading = false;
				});
		},
		async getLocation () {
			if (navigator.geolocation) {
				navigator.geolocation.getCurrentPosition(this.showPosition);
			} else {
				this.lat = 21;
				this.long = 42;
			}
		},
		async showPosition (position) {
			this.lat = position.coords.latitude;
			this.long = position.coords.longitude;
		},
		async submitSurveyAnswers () {
			var inputs = {
				token: `${this.$route.params.token}`,
				case: 'SUBMIT-SURVEY-ANSWERS',
				surveyId: `${this.SurveyId}`,
				branchId: `${this.$route.params.id}`,
				answers: JSON.stringify(this.answersArray),
				endNotification: this.endNotification.toString(),
				notificationQuestionId: null,
				latitude: this.lat,
				longitude: this.long,
			};

			await axios.post('https://sbdev.bridge-techs.com/api/client/managesurveysexternaluser', inputs)
				.then((response) => {
					if (response.data.body.resflag) {
						this.submited = true;
					}
				});
		},
	},
};
</script>
