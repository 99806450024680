import TaskList from '@/lib/models/Client/TaskList';
import BranchForClTemplate from '@/lib/models/Client/BranchForClTemplate';
import { TaskListError } from '@/lib/Errors';
import $http from '../../../plugins/axios';

export default {
	async get (orgId) {
		const body = {
			param_case: 'GET-ALL-CL-TEMPLATE-CLIENT',
			param_organizationid: orgId,
		};

		const response = await $http.post('/api/client/managecltemplates', body);

		if (response.data?.success) {
			const data = response.data.body.responseobject || [];
			const taskList = data.map(u => {
				const task = new TaskList(u);
				return task;
			});
			return taskList;
		} else {
			throw new TaskListError('Error retrieving task list');
		}
	},

	async getAssignedToBranches (clid) {
		const body = {
			taskListId: clid,
		};

		const response = await $http.post('/api/client/getbranchesbytasklistid', body);

		if (response.data?.success) {
			const data = response.data.body.responseobject || [];
			if (data) {
				const Branches = data.map(b => {
					const branch = new BranchForClTemplate(b);
					return branch;
				});
				return Branches;
			} else {
				return [];
			}
		} else {
			throw new TaskListError('Error retrieving Branches for Task List');
		}
	},

	async assign (object) {
		object.param_case = 'ASSIGN-CL-TEMPLATE-CLIENT';

		const response = await $http.post('/api/client/managecltemplates', object);

		if (response.data?.success) {
			return response.data.body;
		} else {
			throw new TaskListError('Error unassigning task list');
		}
	},

	async deassign (object) {
		object.param_case = 'DE-ASSIGN-CL-TEMPLATE-CLIENT';

		const response = await $http.post('/api/client/managecltemplates', object);

		if (response.data?.success) {
			return response.data.body;
		} else {
			throw new TaskListError('Error unassigning task list');
		}
	},

	async cloneClTemplate (id) {
		const body = {
			param_case: 'CLONE-TEMPLATE',
			param_templateid: id,
		};

		const response = await $http.post('/api/client/managecltemplates', body);

		if (response.data?.success) {
			return response.data.body;
		} else {
			throw new TaskListError('Error cloning task list');
		}
	},

	async updateClTemplate (object) {
		object.param_case = 'UPDATE-TASKING-TEMPLATE';

		const response = await $http.post('/api/client/managecltemplates', object);

		if (response.data?.success) {
			return response.data.body;
		} else {
			throw new TaskListError('Error cloning task list');
		}
	},

	async updateClTemplateOrder (object) {
		object.param_case = 'UPDATE-TASKING-TEMPLATE-ORDER';

		const response = await $http.post('/api/client/managecltemplates', object);

		if (response.data?.success) {
			return response.data.body;
		} else {
			throw new TaskListError('Error cloning task list');
		}
	},

	async updateClTemplateName (object) {
		object.param_case = 'UPDATE-TASKING-TEMPLATE-NAME';

		const response = await $http.post('/api/client/managecltemplates', object);

		if (response.data?.success) {
			return response.data.body;
		} else {
			throw new TaskListError('Error updating Task List Name');
		}
	},

	async import () {
		const body = {
			param_case: 'IMPORT-TEMPLATE-FROM-ADMIN',
		};

		const response = await $http.post('/api/client/managecltemplates', body);

		if (response.data?.success) {
			return response.data.body;
		} else {
			throw new TaskListError('Error importing task lists');
		}
	},

	async delete (id) {
		const body = {
			param_case: 'DELETE-TEMPLATE',
			param_templateid: id,
		};

		const response = await $http.post('/api/client/managecltemplates', body);

		if (response.data?.success) {
			return response.data.body;
		} else {
			throw new TaskListError('Error deleting task lists');
		}
	},
};
