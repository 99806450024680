import Repository from '@/repositories/RepositoryFactory';

const RoleRepository = Repository.get('admin', 'role');

export default {
	namespaced: true,
	state: {
		roles: [],
	},
	getters: {

	},
	mutations: {
		LOAD_ROLES: (state, payload) => {
			state.roles = payload;
		},
	},
	actions: {
		async getRolesFromRepo ({ commit }) {
			commit('LOAD_ROLES', await RoleRepository.get());
		},
	},
};
