import safeFormatPhone from '@/utils/phone';

export default class ActivityReport {
	constructor (object) {
		this.NotificationId = object.NotificationId;
		this.BranchAction = object.BranchAction;
		this.RegionalOfficeId = object.RegionalOfficeId;
		this.RegionalOfficeName = object.RegionalOfficeName;
		this.UserId = object.UserId;
		this.Name = object.Name;
		this.Surname = object.Surname;
		this.BranchOfficeId = object.BranchOfficeId;
		this.BranchOfficeName = object.BranchOfficeName;
		this.BranchOfficeDst = object.BranchOfficeDst;
		this.BranchOfficeTimeZone = object.BranchOfficeTimeZone;
		this.BranchOfficeTypeDisplay = object.BranchOfficeTypeDisplay;
		this.start_time = object.start_time ? object.start_time : null;
		this.stop_time = object.stop_time ? object.stop_time : null;
		this.OrganizationId = object.OrganizationId;
		this.OrganizationName = object.OrganizationName;
		this.ChecklistLBranchActions = object.ChecklistLBranchActions;
		this.IsClosedBySystemData = object.IsClosedBySystemData;
		this.NotificationType = object.NotificationType;
		this.IsTestNotification = object.IsTestNotification;
		this.NotificationTimeAddedUTC = object.NotificationTimeAddedUTC;
		this.SecurityAlert = object.SecurityAlert;
		this.NotifiedUsers = object.NotifiedUsers?.map(f => {
			return {
				Name: f.Name,
				PhoneNumber: safeFormatPhone(f.PhoneNumber),
				RoleNameDisplay: f.RoleNameDisplay,
				Surname: f.Surname,
			};
		});
		this.Latitude = object.Latitude;
		this.Longitude = object.Longitude;
		this.MessageContent = object.MessageContent;
	}
}
