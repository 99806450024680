export default {
	namespaced: true,
	name: 'alerts',

	state: {
		/**
		 * Alerts shown while the app is logged in
		 */
		alerts: [],
		/**
		 * Alerts shown on the login page
		 */
		authAlerts: [],
	},

	mutations: {
		add (state, alert) {
			if (alert.auth) {
				state.authAlerts.push(alert);
			} else {
				state.alerts.push(alert);
			}
		},
		remove (state, alert) {
			const idx = state.alerts.indexOf(alert);
			if (idx > -1) {
				state.alerts.splice(idx, 1);
			}
			const idx2 = state.authAlerts.indexOf(alert);
			if (idx2 > -1) {
				state.authAlerts.splice(idx2, 1);
			}
		},
		clear (state) {
			state.alerts.splice(0, state.alerts.length);
			state.authAlerts.splice(0, state.authAlerts.length);
		},
	},
};
