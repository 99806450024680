<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="500"
      style="z-index: 9999"
    >
      <v-card class="card-title">
        <v-card-title>
          <h4>{{ $t('profile') }}</h4>
        </v-card-title>
        <template>
          <Alert
            v-if="alert"
            :type="alertType"
            dense
            text
            dismissible
            v-model="visibility"
            >{{ alertMessage}}</Alert
          >
            <v-card flat>
              <v-card-text>
                <v-container>
                  <v-row>
                    <!-- <v-col class="text-center" cols="5" fluid>
                      <AvatarSelector
                        v-model="file"
                        :formats="formats"
                        :size="sizeKB"
                      />
                    </v-col> -->
                    <v-col cols="12" fluid>
                      <v-row>
                        <v-col md="6">
                          <validation-provider
                            :name="$t('name')"
                            rules="required"
                          >
                            <v-text-field
                              items="profileName"
                              v-model="profileName"
                              prepend-inner-icon="mdi-account"
                              item-text="name"
                              item-value="id"
                              :label="$t('first-name')"
                              outlined
                              dense
                            ></v-text-field>
                          </validation-provider>
                        </v-col>
                        <v-col cols="6">
                          <validation-provider
                            :name="$t('name')"
                            rules="required"
                          >
                            <v-text-field
                              items="profileSurname"
                              prepend-inner-icon="mdi-account"
                              v-model="profileSurname"
                              item-text="name"
                              item-value="id"
                              :label="$t('surname')"
                              outlined
                              dense
                            ></v-text-field>
                          </validation-provider>
                        </v-col>
                        <v-divider></v-divider>
                        <v-col cols="12" sm="12">
                          <v-banner
                            dense
                            label="role"
                            single-line
                            icon="mdi-account-supervisor-circle"
                            >{{userRole}}</v-banner
                          >
                        </v-col>
                        <v-divider></v-divider>
                        <v-col cols="12" sm="12">
                          <v-banner
                            filter
                            label="example@sbdev.bridge-techs.com"
                            prepend-inner-icon="mdi-email-open"
                            single-line
                            icon="mdi-email-open"
                            >{{emailProfile}}</v-banner
                          >
                        </v-col>
                        <v-col cols="12" sm="12">
                          <v-banner
                            mdi-filter
                            filter
                            label="+389 xx xxx xxx"
                            single-line
                            icon="mdi-cellphone"
                            >{{phoneNumber}}</v-banner
                          >
                        </v-col>
                      </v-row>
                      <v-col cols="9">
                        <v-btn
                          depressed
                          color=""
                          elevation="4"
                          raised
                          @click="hidden = !hidden"
                        >
                          {{ $t("changepassword") }}
                          <v-icon v-if="!hidden" color="primary"
                            >mdi-chevron-up</v-icon
                          >
                          <v-icon v-else color="primary"
                            >mdi-chevron-down</v-icon
                          >
                        </v-btn>
                        <validation-provider
                          :name="$t('oldpassword')"
                          rules="required"
                        >
                          <v-text-field
                            v-if="!hidden"
                            prepend-inner-icon="mdi-key-variant"
                            class="mt-5"
                            v-model="oldPassword"
                            :label="$t('oldpassword')"
                            type="password"
                            outlined
                            dense
                          >
                          </v-text-field>
                        </validation-provider>
                        <validation-provider
                          :name="$t('newpassword')"
                          rules="required"
                        >
                          <v-text-field
                            v-if="!hidden"
                            v-model="newPassword"
                            :label="$t('newpassword')"
                            prepend-inner-icon="mdi-key-variant"
                            type="password"
                            outlined
                            dense
                          ></v-text-field>
                        </validation-provider>
                        <validation-provider
                          v-if="!hidden"
                          :name="$t('cnfpassword')"
                          rules="required"
                        >
                          <v-text-field
                            v-model="confirmPassword"
                            :label="$t('cnfpassword')"
                            type="password"
                            prepend-inner-icon="mdi-key-variant"
                            outlined
                            dense
                          ></v-text-field>
                        </validation-provider>
                      </v-col>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
        </template>
          <!-- <v-tab-item value="tab-2">
            <v-card flat>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="6" fluid>
                      <v-select
                        :items="countriesList"
                        class="mt-8"
                        prepend-inner-icon="mdi-earth"
                        v-model="selectedCountry"
                        :label="$t('localcountry')"
                        outlined
                        dense
                      ></v-select>
                    </v-col>
                    <v-col cols="6" fluid>
                      <v-icon>mdi-filter</v-icon
                      ><small>Filter Preferences</small>
                      <v-select
                        :items="filters"
                        v-model="selectedNotify"
                        class="mt-3"
                        item-text="text"
                        item-value="value"
                        :label="$t('notificationsfilter')"
                        outlined
                        dense
                      ></v-select>
                      <v-select
                        :items="filters"
                        v-model="selectedAlarms"
                        item-text="text"
                        item-value="value"
                        :label="$t('alarmsfilter')"
                        outlined
                        dense
                      ></v-select>
                      <v-select
                        :items="filters"
                        v-model="selectedStatistics"
                        item-text="text"
                        item-value="value"
                        :label="$t('statisticsfilter')"
                        outlined
                        dense
                      ></v-select>
                    </v-col>
                  </v-row>

                  <small><b>Notifications</b></small>
                  <v-checkbox
                    label="Do not disturb"
                    color="primary"
                    hide-details
                    v-model="doNotDisturb"
                  ></v-checkbox>
                </v-container>
              </v-card-text>
            </v-card>
          </v-tab-item> -->
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close">
            {{ $t("close") }}
          </v-btn>
          <v-btn color="primary" @click="save">
            {{ $t("save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- <template>
</template> -->
  </v-row>
</template>

<script>
import { mapState } from 'vuex';
import Alert from '@/components/base/MaterialAlert.vue';
import countries from '@/lib/Countries.js';
import { UserError } from '@/lib/Errors';
// import AvatarSelector from './AvatarSelector';
import RepositoryFactory from '@/repositories/RepositoryFactory';

const {
	manageUserProperties,
	updateDndNotifications,
	updateUserProfile,
} = RepositoryFactory.get('client', 'user');

export default {
	name: 'Profile',
	components: {
		// AvatarSelector,
		Alert,
	},
	props: ['dialog'],
	data: () => ({
		file: null,
		formats: ['image/jpg', 'image/jpeg', 'image/png'],
		sizeKB: 700,
		timeout: 2000,
		selectableData: {
			countries: countries,
		},
		doNotDisturb: false,
		tempProfileName: null,
		tempProfileSurname: null,
		filters: [
			{ text: 'Today', value: 'today' },
			{ text: 'Yesterday', value: 'yesterday' },
			{ text: 'This Week', value: 'this_week' },
			{ text: 'Last Week', value: 'last_week' },
			{ text: 'This Month', value: 'this_month' },
			{ text: 'Last Month', value: 'last_month' },
		],
		visibility: true,
		selectedNotify: null,
		hidden: true,
		selectedAlarms: null,
		selectedStatistics: null,
		selectedCountry: null,
		oldPassword: null,
		newPassword: null,
		confirmPassword: null,
		profileName: null,
		profileSurname: null,
		emailProfile: null,
		userRole: null,
		// profileAddress: null,
		// profileCity: null,
		// profileState: null,
		// profileCoutry: null,
		// profileZipCode: null,
		phoneNumber: null,
		alertMessage: '',
		alertType: '',
		alert: false,
		errorMessage: '',
		tab: null,
	}),
	computed: {
		...mapState({
			userObject: state => state.authentication.userObject,
		}),
		countriesList: {
			get () {
				return this.selectableData.countries.map(c => {
					return c.name;
				});
			},
		},
	},
	methods: {
		close () {
			this.$emit('user-profile-closed');
			this.hidden = true;
			this.profileName = this.tempProfileName;
			this.profileSurname = this.tempProfileSurname;
			this.alert = false;
			this.alertType = '';
			this.alertMessage = '';
		},
		save () {
			if (
				this.oldPassword !== null &&
        this.newPassword !== null &&
        this.confirmPassword !== null
			) {
				const requestObject = {
					case: 'UPDATE-PASSWORD',
					confirmpass: this.confirmPassword,
					newpass: this.newPassword,
					oldpass: this.oldPassword,
				};
				if (this.newPassword === this.confirmPassword) {
					manageUserProperties(requestObject)
						.then(data => {
							if (data.status === 200 && data.success) {
								this.alert = true;
								this.alertType = 'success';
								this.alertMessage = data.body.msg;
							} else {
								this.alert = true;
								this.alertType = 'warning';
								this.alertMessage = data.description;
							}
						})
						.catch(error => {
							if (error instanceof UserError) {
								this.alert = true;
								this.alertType = 'error';
								this.alertMessage = error.message;
							} else {
								this.alert = true;
								this.alertType = 'error';
								this.alertMessage = 'Error communicating with server. Please try again.';
							}
						});
				} else {
					this.alert = true;
					this.alertType = 'warning';
					this.alertMessage = 'New Password confirmation failed';
				}
			}
			var dndObj = {
				NotificationDoNotDisturb: this.doNotDisturb,
			};
			updateDndNotifications(dndObj)
				.then(data => {
					if (data.status === 200 && data.success) {
						this.alert = true;
						this.alertType = 'success';
						this.alertMessage = data.body.msg;
					} else {
						this.alert = true;
						this.alertType = 'warning';
						this.alertMessage = data.description;
					}
				})
				.catch(error => {
					if (error instanceof UserError) {
						this.alert = true;
						this.alertType = 'error';
						this.alertMessage = error.message;
					} else {
						this.alert = true;
						this.alertType = 'error';
						this.alertMessage = 'Error communicating with server. Please try again.';
					}
				});
			if (this.profileName !== null && this.profileSurname !== null) {
				const profileinputs = {
					profileName: this.profileName,
					profileLastName: this.profileSurname,
					profileEmail: this.userObject.UserInfos.Email,
				};
				updateUserProfile(profileinputs)
					.then(data => {
						if (data.status === 200 && data.success) {
							this.alert = true;
							this.alertType = 'success';
							this.alertMessage = data.body.msg;

							this.tempProfileName = this.profileName;
							this.tempProfileSurname = this.profileSurname;
						} else {
							this.alert = true;
							this.alertType = 'warning';
							this.alertMessage = data.description;

							this.profileName = this.tempProfileName;
							this.profileSurname = this.tempProfileSurname;
						}
					})
					.catch(error => {
						if (error instanceof UserError) {
							this.alert = true;
							this.alertType = 'error';
							this.alertMessage = error.message;
						} else {
							this.alert = true;
							this.alertType = 'error';
							this.alertMessage = 'Error communicating with server. Please try again.';
						}
					});
			}
		},
	},
	watch: {
		dialog (val) {
			if (val === true) {
				this.doNotDisturb = this.userObject.UserSettings.NotificationDoNotDisturb;
				if (this.profileName === null) {
					this.profileName = this.userObject.UserInfos.Name;
					this.tempProfileName = this.profileName;
				}
				if (this.profileSurname === null) {
					this.profileSurname = this.userObject.UserInfos.Surname;
					this.tempProfileSurname = this.profileSurname;
				}
				this.profileUpdate = this.userObject.UserInfos.NotificiationProfileUpdater;
				this.emailProfile = this.userObject.UserInfos.Email;
				this.phoneNumber = this.userObject.UserInfos.PhoneNumber;
				this.userRole = this.userObject.UserInfos.RoleNameDisplay;
				// this.profileAddress = this.userObject.UserInfos.Address;
				// this.profileCity = this.userObject.UserInfos.City;
				// this.profileCountry = this.userObject.UserInfos.Country;
				// this.profileZipCode = this.userObject.UserInfos.ZipCode;
				// this.profileState = this.userObject.UserInfos.State;
			}
		},
		visibility (val) {
			if (!val) {
				this.alert = false;
				this.visibility = true;
			}
		},
	},
};
</script>
<style scoped>
	.tableTabs {
		width: 110px;
	}
	.v-card__title {
        background: #F3F4F6;
        color: #000;
        height: 40px;
        padding: 5px 20px;
    }
	.v-dialog > .v-card > .v-card__title {
        background: #F3F4F6;
        color: #000;
        height: 40px;
        padding: 5px 20px;
    }
</style>
