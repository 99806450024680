import TemplateDetails from './TemplateDetails.js';
import AssignedToBranches from './AssignedToBranches.js';

export default class TaskList {
	constructor (object) {
		this.TemplateAutoId = object.TemplateAutoId;
		this.TemplateName = object.TemplateName;
		this.TemplateDescription = object.TemplateDescription;
		this.TaskingType = object.TaskingType;
		this.TaskingTypeName = object.TaskingTypeName;
		this.OrganizationId = object.OrganizationId;
		this.OrganizationName = object.OrganizationName;
		this.TemplateDetails = object.TemplateDetails ? object.TemplateDetails.map(obj => {
			return new TemplateDetails(obj);
		}) : null;
		if (Array.isArray(object.AssignedToBranches)) {
			this.AssignedToBranches = object.AssignedToBranches.map(obj => {
				return new AssignedToBranches(obj);
			});
			this.Branches = this.AssignedToBranches.length > 1 ? 'Multiple' : this.AssignedToBranches[0].BranchOfficeName;
		} else {
			this.AssignedToBranches = null;
			this.Branches = '';
		}
		this.IsAllowedChecklistEdit = object.IsAllowedChecklistEdit;
		this.IsAllowedChecklistDelete = object.IsAllowedChecklistDelete;
	}
}
