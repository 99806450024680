<template>
	<v-app id="main-app">
		<v-container class="fill-height" fluid>
		<div v-if="loadingLink" class="text-center mx-auto px-10 pb-9">
			<v-progress-circular
				indeterminate
				:size="50"
				color="white"
			></v-progress-circular>
		</div>
		<v-card class="mx-auto px-10 pb-9" width="450px" :loading="loading" v-else>
			<v-card-title class="justify-center pt-12"><img v-bind:src="logo.src" v-bind:style="{ width: logo.width, height: logo.height }"></v-card-title>
			<v-card-text class="text-center">
			<Alert
				v-if="alert"
				:type="alertType"
				dense
				text
				dismissible
				v-model="visibility"
			>{{ alertMessage}}</Alert>
				<v-form @submit.prevent="resetPassword" v-if="!linkExpired">
					<v-text-field
						v-model="password"
						label="New Password"
						placeholder=" "
						name="password"
						outlined
						required
						spellcheck="false"
						:append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
						:autocomplete="showNewPassword ? 'off' : 'current-password'"
						:rules="[rules.passwordRequired]"
						:type="showNewPassword ? 'text' : 'password'"
						@click:append="showNewPassword = !showNewPassword"/>

					<v-text-field
						label="Confirm Password"
						placeholder=" "
						v-model="confirmPassword"
						name="password"
						outlined
						required
						spellcheck="false"
						:append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
						:autocomplete="showPassword ? 'off' : 'current-password'"
						:rules="[rules.confirmPasswordRequired]"
						:type="showPassword ? 'text' : 'password'"
						@click:append="showPassword = !showPassword"
						@keyup.enter="clickButton" />
				</v-form>
				<span v-else>{{ message }}</span>
			</v-card-text>
			<v-card-actions class="d-flex justify-center mb-6">
				<v-btn v-if="beforeFinish" class="px-16 py-6" color="primary" @click="resetPassword()">{{ $t('setNewPassword') }}</v-btn>
				<v-btn v-else class="px-16 py-6" color="primary" @click="goToLogin()">{{ $t('goToLogin') }}</v-btn>
			</v-card-actions>
		</v-card>
		<v-footer color="transparent" absolute padless>
			<v-col class="text-center white--text" cols="12">
				&copy;{{ new Date().getFullYear() }} SaferMobility, LLC &#183; {{ appVersion }}
			</v-col>
		</v-footer>
		</v-container>
	</v-app>
</template>

<script>
import Alert from '@/components/base/MaterialAlert.vue';
import Repository from '@/repositories/RepositoryFactory';
const { LOGIN_LOGO } = require(`@/themes/${process.env.VUE_APP_THEME}/image-paths`);

const UserRepository = Repository.get('client', 'user');

export default {
	components: {
		Alert,
	},
	data () {
		return {
			appVersion: process.env.VUE_APP_VERSION,
			logo: LOGIN_LOGO,
			loading: false,
			beforeFinish: true,
			case: '',
			password: '',
			confirmPassword: '',
			showPassword: false,
			showNewPassword: false,
			rules: {
				passwordRequired: value => !!value || 'Enter your new password',
				confirmPasswordRequired: value => !!value || 'Confirm your new password',
			},
			alert: false,
			alertType: 'success',
			alertMessage: '',
			visibility: true,
			linkExpired: false,
			loadingLink: true,
			message: null,
		};
	},
	watch: {
		visibility (val) {
			if (!val) {
				this.alert = false;
				this.visibility = true;
			}
		},
	},
	async mounted () {
		await this.checkValidationLink();
		this.loadingLink = false;
	},
	methods: {
		async checkValidationLink () {
			await this.$store.dispatch('authentication/queryLocationServer', this.$route.params.email);
			await this.$store.dispatch('getCsrfToken');
			const body = {
				token: this.$route.params.token,
			};
			const response = await UserRepository.validateLink(body);
			if (!response.body.resflag) {
				this.linkExpired = true;
				this.beforeFinish = false;
				this.message = response.body.msg;
			}
		},
		async resetPassword () {
			if (this.password !== this.confirmPassword) {
				this.alertMessage = 'Please confirm your new password correctly';
				this.alertType = 'error';
				this.alert = true;
			} else {
				const body = {
					password: this.password,
					token: this.$route.params.token,
				};
				const response = await UserRepository.confirmPassword(body);
				if (response.success) {
					this.alertMessage = response.description;
					this.alertType = 'success';
					this.alert = true;
					this.beforeFinish = false;
					this.linkExpired = true;
					this.message = null;
				} else {
					this.alertMessage = response.description;
					this.alertType = 'error';
					this.alert = true;
				}
			}
		},
		clickButton () {
			return this.resetPassword();
		},
		goToLogin () {
			this.$router.push({ name: 'Login' });
		},
	},
};
</script>
<style scoped>
#main-app {
	background: linear-gradient(#557DBE, #3C5886)
}
</style>
