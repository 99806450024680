import Repository from '@/repositories/RepositoryFactory';

const HeadOfficeRepository = Repository.get('client', 'headOffice');

export default {
	namespaced: true,
	state: {
		mainOffice: [],
		headOffice: null,
	},
	getters: {

	},
	mutations: {
		LOAD_HEAD_OFFICE: (state, payload) => {
			state.mainOffice[0] = payload;
			state.headOffice = payload;
		},
	},
	actions: {
		async getHeadOfficeFromRepo ({ commit }) {
			commit('LOAD_HEAD_OFFICE', await HeadOfficeRepository.get());
		},
		async updateHeadOfficeWeekSchedule ({ commit }, object) {
			const response = await HeadOfficeRepository.updateHeadOfficeWeekSchedule(object);
			return response;
		},
		async updateHeadOfficeHolidays ({ commit }, object) {
			const response = await HeadOfficeRepository.updateHeadOfficeHolidays(object);
			return response;
		},
	},
};
