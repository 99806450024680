import Organization from '@/lib/models/Client/Organization';
import $http from '../../../plugins/axios';

import { OrganizationError } from '@/lib/Errors';

export default {
	async get () {
		const body = {
			case: 'GET-ALL-ORGANIZATIONS',
		};

		const response = await $http.post('/api/client/getorganizations', body);

		if (response.data?.success) {
			const data = response.data.body.organizationobject || [];
			const organizations = data.map(org => {
				const organization = new Organization(org);
				return organization;
			});
			return organizations;
		} else {
			throw new OrganizationError('Error retrieving Organizations');
		}
	},

	async update (object) {
		object.case = 'UPDATE-EMPLOYEE';

		const response = await $http.post('/api/client/manageemployees', object);

		if (response.data?.success) {
			return response.data.body;
		} else {
			throw new OrganizationError('Error updating employee');
		}
	},

	async getBranchStructure () {
		const body = {
			case: 'GET-ALL-BRANCH-STRUCTURE',
		};

		const response = await $http.post('/api/client/getstatistics', body);

		if (response.data?.success) {
			return response.data.body.organizationobject;
		} else {
			throw new OrganizationError('Error getting branch structure');
		}
	},
};
