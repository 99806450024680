import notifySound from '@/sounds/beep-06.ogg';

import { Howl } from 'howler';

var notifyRinger = new Howl({
	src: notifySound,
	loop: true,
});

export default function createAlarmNotificationPlugin (count) {
	return function (store) {
		const unsubscribe = store.subscribe((mutation) => {
			const branchAction = mutation?.payload?.BranchAction;
			if (mutation.type !== 'notifications/LOAD_NOTIFICATION') {
				if (branchAction?.includes('Trouble') || branchAction?.includes('Panic') || branchAction?.includes('Alert') || branchAction?.includes('Alarm')) {
					if (count !== 1) {
						notifyRinger.play();
						store.commit('notifications/ADD_COUNT');
						setTimeout(function () {
							notifyRinger.stop();
							store.commit('notifications/REMOVE_COUNT');
						}, 2000);
					} else {
						unsubscribe();
					}
				}
			}
		});
	};
}
