import SecurityPhrase from '@/lib/models/Client/SecurityPhrase';
import { SecurityPhraseError } from '@/lib/Errors';
import $http from '../../../plugins/axios';

async function addOrUpdateSecurityPhrase (body) {
	const response = await $http.post('/api/client/commonphrase', body);

	if (response.data?.success) {
		const res = {
			body: response.data.body,
			newPhrase: new SecurityPhrase(response.data.body.phraseobject),
		};
		return res;
	} else {
		throw new SecurityPhraseError(response.data.description);
	}
}
export default {
	async getAllSecurityPhrases (object) {
		object.case = 'GET-ALL-SECURITY-PHRASES';

		const response = await $http.post('/api/client/commonphrase', object);

		if (response.data?.status === 200) {
			if (response.data?.success) {
				const data = response.data.body.phraseobject || [];
				const securityPhrase = data.map(so => {
					const securityPhrase = new SecurityPhrase(so);
					return securityPhrase;
				});
				return securityPhrase;
			} else return [];
		} else {
			throw new SecurityPhraseError('Error retrieving Security Phrases!');
		}
	},

	async getSecurityPhrase (object) {
		object.case = 'GET-SECURITY-PHRASE';

		const response = await $http.post('/api/client/commonphrase', object);

		if (response.data?.success) {
			return response;
		} else {
			throw new SecurityPhraseError('Error storing Security Phrases!');
		}
	},

	async getSecurityPhraseForUpdate (object) {
		object.case = 'GET-SECURITY-PHRASES-FOR-UPDATE';

		const response = await $http.post('/api/client/commonphrase', object);

		if (response.data?.status === 200) {
			if (response.data?.success) {
				const data = response.data?.body?.phraseobject || [];
				const securityPhrase = data.map(so => {
					const securityPhrase = new SecurityPhrase(so);
					return securityPhrase;
				});
				return securityPhrase;
			} else return [];
		} else {
			throw new SecurityPhraseError('Error storing Security Phrases!');
		}
	},

	async store (object) {
		object.case = 'ADD-SECURITY-PHRASE';

		return addOrUpdateSecurityPhrase(object);
	},

	async update (object) {
		object.case = 'UPDATE-MULTIPLE-SECURITY-PHRASES';
		const response = await $http.post('/api/client/commonphrase', object);
		if (response.data?.success) {
			return response.data?.description;
		} else {
			throw new SecurityPhraseError(response.data.description);
		}
	},
};
