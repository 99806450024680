import Repository from '@/repositories/RepositoryFactory';

const SurveyRepository = Repository.get('client', 'survey');

export default {
	namespaced: true,
	state: {
		surveys: [],
		survey: {},
		branchSurvey: {},
		usersToNotify: [],
		usersToSelect: [],
		surveyExternalUser: {},
		externalUsersForSurvey: [],
	},
	getters: {

	},
	mutations: {
		LOAD_SURVEYS: (state, payload) => {
			state.surveys = payload;
		},
		LOAD_SURVEY_BY_ID: (state, payload) => {
			state.survey = payload;
		},
		LOAD_SURVEY_BY_ID_EXTERNAL_USER: (state, payload) => {
			state.surveyExternalUser = payload;
		},
		LOAD_BRANCH_SURVEY: (state, payload) => {
			state.branchSurvey = payload;
		},
		LOAD_USERS_TO_NOTIFY: (state, payload) => {
			state.usersToNotify = payload;
		},
		LOAD_USERS_TO_SELECT: (state, payload) => {
			state.usersToSelect = payload;
		},
		LOAD_EXTERNAL_USERS_FOR_SURVEY: (state, payload) => {
			state.externalUsersForSurvey = payload;
		},
		CLEAR_SURVEYS: (state) => {
			state.survey = {};
		},
	},
	actions: {
		async getSurveysFromRepo ({ commit }) {
			commit('LOAD_SURVEYS', await SurveyRepository.get());
		},

		async getSurveyById ({ commit }, id) {
			commit('LOAD_SURVEY_BY_ID', await SurveyRepository.getSurveyById(id));
		},

		async getSurveyExternalUser ({ commit }, object) {
			commit('LOAD_SURVEY_BY_ID_EXTERNAL_USER', await SurveyRepository.getSurveyExternalUser(object));
		},

		async getBranchSurvey ({ commit }, id) {
			commit('LOAD_BRANCH_SURVEY', await SurveyRepository.getBranchSurvey(id));
		},

		async storeSurvey ({ commit }, object) {
			const response = await SurveyRepository.store(object);
			return response;
		},

		async getSurveyUsers ({ commit }, id) {
			commit('LOAD_USERS_TO_NOTIFY', await SurveyRepository.getSurveyUsers(id));
		},

		async getUsersToSelectForSurvey ({ commit }, id) {
			commit('LOAD_USERS_TO_SELECT', await SurveyRepository.getUsersToSelectForSurvey(id));
		},

		async assignSurvey ({ commit }, object) {
			const response = await SurveyRepository.assign(object);
			return response;
		},

		async deassignSurvey ({ commit }, id) {
			const response = await SurveyRepository.deassign(id);
			return response;
		},

		async addExternalUsers ({ commit }, users) {
			const response = await SurveyRepository.addExternalUsers(users);
			return response;
		},

		async updateListOfExtUsersForSurvey ({ commit }, object) {
			const response = await SurveyRepository.updateListOfExtUsersForSurvey(object);
			return response;
		},

		async getExternalUsersByBranch ({ commit }, id) {
			commit('LOAD_EXTERNAL_USERS_FOR_SURVEY', await SurveyRepository.getExternalUsersByBranch(id));
		},

		async updateUsersToTakeSurvey ({ commit }, object) {
			const response = await SurveyRepository.updateUsersToTakeSurvey(object);
			return response;
		},

		async updateUsersToNotifyForSurvey ({ commit }, object) {
			const response = await SurveyRepository.updateUsersToNotifyForSurvey(object);
			return response;
		},

		async updateSurveySchedule ({ commit }, object) {
			const response = await SurveyRepository.updateSurveySchedule(object);
			return response;
		},

		async updateName ({ commit }, name) {
			const response = await SurveyRepository.updateName(name);
			return response;
		},

		async updateSurvey ({ commit }, object) {
			const response = await SurveyRepository.updateSurvey(object);
			return response;
		},
	},
};
