import SecurityOffice from '@/lib/models/Admin/SecurityOffice';
import { SecurityOfficeError } from '@/lib/Errors';
import $http from '../../../plugins/axios';

export default {
	async get () {
		const body = {
			case: 'GET-ALL-SECURITY-OFFICES',
		};

		const response = await $http.post('/api/admin/getAllSecurityOffices', body);

		if (response.data?.success) {
			const data = response.data.body.responseobject || [];
			const securityOffices = data.map(so => {
				const securityOffice = new SecurityOffice(so);
				return securityOffice;
			});
			return securityOffices;
		} else {
			throw new SecurityOfficeError('Error retrieving Security Offices');
		}
	},

	async store (object) {
		object.case = 'ADD-SECURITY-OFFICE';

		const response = await $http.post('/api/admin/addsecurityoffice', object);

		if (response.data?.success) {
			return response.data.body;
		} else {
			throw new SecurityOfficeError('Error adding Security Office');
		}
	},

	async update (object) {
		object.case = 'UPDATE-SECURITY-OFFICE';

		const response = await $http.post('/api/admin/addsecurityoffice', object);

		if (response.data?.success) {
			return response.data.body;
		} else {
			throw new SecurityOfficeError('Error updating Security Office');
		}
	},
};
