import Repository from '@/repositories/RepositoryFactory';

const SecurityOfficeRepository = Repository.get('client', 'securityOffice');

export default {
	namespaced: true,
	state: {
		securityOffices: [],
	},
	getters: {

	},
	mutations: {
		LOAD_SECURITY_OFFICES: (state, payload) => {
			state.securityOffices = payload;
		},
	},
	actions: {
		async getSecurityOfficesFromRepo ({ commit }) {
			commit('LOAD_SECURITY_OFFICES', await SecurityOfficeRepository.get());
		},
	},
};
