import Organization from '@/lib/models/Admin/Organization';
import $http from '../../../plugins/axios';

import { OrganizationError } from '@/lib/Errors';

export default {
	async get () {
		const body = {
			case: 'GET-ALL-ORGANIZATIONS',
		};

		const response = await $http.post('/api/admin/getorganizations', body);

		if (response.data?.success) {
			const data = response.data.body.clientobject || [];
			const organizations = data.map(org => {
				const organization = new Organization(org);
				return organization;
			});
			return organizations;
		} else {
			throw new OrganizationError('Error retrieving Organizations');
		}
	},

	async getById (id) {
		const body = {
			case: 'GET-ORGANIZATION-BY-ORGANIZATIONID',
			organizationId: id,
		};

		const response = await $http.post('/api/admin/getorganizationbyid', body);

		if (response.data?.success) {
			const data = response.data.body.clientobject;
			const organization = new Organization(data);
			return organization;
		} else {
			throw new OrganizationError('Error retrieving Organization');
		}
	},

	async store (object) {
		object.case = 'ADD-CLIENT';

		const response = await $http.post('/api/admin/addclient', object);

		if (response.data?.success) {
			return response.data.body;
		} else {
			throw new OrganizationError('Error creating Organization');
		}
	},

	async update (object) {
		object.case = 'UPDATE-ORGANIZATIONS';

		const response = await $http.post('/api/admin/editclient', object);
		if (response.data?.status === 200) {
			if (response.data?.success) {
				const data = response.data.body;
				return data;
			} else return response.data;
		} else {
			throw new OrganizationError('Error updating Organization');
		}
	},
};
