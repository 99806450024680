import Repository from '@/repositories/RepositoryFactory';

const OrganisationRepository = Repository.get('client', 'organization');

export default {
	namespaced: true,
	state: {
		organisations: [],
	},
	getters: {

	},
	mutations: {
		LOAD_ORGANISATIONS: (state, payload) => {
			state.organisations = payload;
		},
	},
	actions: {
		async get ({ commit }) {
			commit('LOAD_ORGANISATIONS', await OrganisationRepository.get());
		},
		async update ({ commit }, object) {
			const response = await OrganisationRepository.update(object);
			return response;
		},
	},
};
