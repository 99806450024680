import safeFormatPhone from '@/utils/phone';

export default class HeadOffice {
	constructor (object) {
		this.Id = object.HeadOfficeId;
		this.Name = object.Name;
		this.Code = object.Code;
		this.PhoneNumber = safeFormatPhone(object.PhoneNumber);
		this.OrganizationName = object.OrganizationName;
		this.Status = object.Status === 'ACTIVE' ? 'Active' : object.Status === 'PENDING' ? 'Pending' : 'Inactive';
		this.Address = object.Address;
		this.City = object.City;
		this.State = object.State;
		this.ZipCode = object.ZipCode;
		this.Country = object.Country;
		this.Latitude = object.Latitude;
		this.Longitude = object.Longitude;
		this.Opening = object.Opening;
		this.Closing = object.Closing;
		this.Servicing = object.Servicing;
		this.FormatedAddress = object.Address + ', ' + object.City + ', ' + object.State + ', ' + object.ZipCode + ', ' + object.Country;
		this.StatusId = object.StatusId;
		this.Altitude = object.Altitude;
		this.OfficeTypeId = object.OfficeTypeId;
		this.OfficeTypeName = object.OfficeTypeName;
		this.WorkScheduleId = object.WorkScheduleId;
		this.OrganizationId = object.OrganizationId;
		this.WeekSchedule = object.WeekSchedule;
		this.HolidaysTemplate = object.HolidaysTemplate;
		this.PrimaryContactEmail = object.PrimaryContactEmail;
		this.PrimaryContactName = object.PrimaryContactName;
		this.PrimaryContactPhone = object.PrimaryContactPhone;
		this.PrimaryContactSurname = object.PrimaryContactSurname;
		this.SecondaryContactEmail = object.SecondaryContactEmail;
		this.SecondaryContactName = object.SecondaryContactName;
		this.SecondaryContactPhone = object.SecondaryContactPhone;
		this.SecondaryContactSurname = object.SecondaryContactSurname;
		this.AutoCloseTime = object.AutoCloseTime;
		this.TaskNames = object.TaskNames;
		this.Dst = object.Dst;
	}
}
