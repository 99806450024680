const filterRanges = [
	{ name: 'Today', value: 'today' },
	{ name: 'Yesterday', value: 'yesterday' },
	{ name: 'This Week', value: 'this_week' },
	{ name: 'Last Week', value: 'last_week' },
	{ name: 'This Month', value: 'this_month' },
	{ name: 'Last Month', value: 'last_month' },
	{ name: 'Custom', value: 'custom' },
];
export default filterRanges;
