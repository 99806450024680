import Repository from '@/repositories/RepositoryFactory';

const ChatMessageRepository = Repository.get('client', 'chatMessage');
const VideoRecRepository = Repository.get('client', 'videorec');

export default {
	namespaced: true,

	state: {
		chatSessions: [],
		chatDetails: null,
		videoSessions: [],
	},
	mutations: {
		LOAD_CHAT_RECORDINGS: (state, payload) => {
			state.chatSessions = payload;
		},
		LOAD_CHAT_DETAILS: (state, payload) => {
			state.chatDetails = payload;
		},
		LOAD_VIDEO_RECORDINGS: (state, payload) => {
			state.videoSessions = payload;
		},
	},
	actions: {
		async getChatRecordings ({ commit }, object) {
			commit('LOAD_CHAT_RECORDINGS', await ChatMessageRepository.getChatRecordings(object));
		},
		async getSessionDetailsBySessionId ({ commit }, id) {
			commit('LOAD_CHAT_DETAILS', await ChatMessageRepository.getSessionDetailsBySessionId(id));
		},
		async getVideoRecordingsFromRepo ({ commit }, object) {
			commit('LOAD_VIDEO_RECORDINGS', await VideoRecRepository.get(object));
		},
	},
	getters: {

	},
};
