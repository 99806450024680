import activeSessions from './modules/activeSessions';
import adminRoles from './modules/admin/adminRoles';
import adminOrganizations from './modules/admin/adminOrganizations';
import adminSecurityOffices from './modules/admin/adminSecurityOffices';
import adminUser from './modules/admin/adminUser';
import alerts from './modules/alerts';
import authentication from './modules/authentication';
import branches from './modules/branches';
import chat from './modules/chat';
import chatMessages from './modules/chatMessages';
import filters from './modules/filters';
import headOffice from './modules/headOffice';
import notifications from './modules/notifications';
import organisation from './modules/organisation';
import recordings from './modules/recordings';
import regions from './modules/regions';
import reports from './modules/reports';
import roles from './modules/roles';
import securityOfficers from './modules/securityOfficers';
import securityOffices from './modules/securityOffices';
import securityPhrases from './modules/securityPhrases';
import socket from './modules/socket';
import surveys from './modules/surveys';
import task from './modules/task';
import tasklists from './modules/tasklists';
import timer from './modules/timer';
import users from './modules/users';
import videoCalls from './modules/video-calls';

export default {
	adminSecurityOffices,
	adminUser,
	adminRoles,
	adminOrganizations,
	alerts,
	regions,
	headOffice,
	branches,
	authentication,
	securityOffices,
	users,
	roles,
	tasklists,
	surveys,
	securityOfficers,
	chatMessages,
	reports,
	notifications,
	socket,
	activeSessions,
	filters,
	videoCalls,
	chat,
	task,
	organisation,
	recordings,
	timer,
	securityPhrases,
};
