import Vue from 'vue';
import Vuetify from 'vuetify/lib';
const { PRODUCT_THEME } = require(`../themes/${process.env.VUE_APP_THEME}/colors`);

Vue.use(Vuetify);

export default new Vuetify({
	theme: {
		themes: {
			light: PRODUCT_THEME,
		},
	},
});
