import Role from './Role';

export default class User {
	constructor (object) {
		this.Id = object.UserId;
		this.FirstName = object.Name;
		this.LastName = object.Surname;
		this.FullName = `${object.Name} ${object.Surname}`;
		this.Email = object.Email;
		this.Address = object.Address;
		this.City = object.City;
		this.State = object.State;
		this.ZipCode = object.ZipCode;
		this.Country = object.Country;
		this.PhoneNumber = object.PhoneNumber;
		this.Number = object.Number;
		this.Avatar = object.Avatar;
		this.Role = new Role({
			RoleId: object.RoleId,
			RoleName: object.RoleNameDisplay,
		});
		this.LangId = object.LangId;
		this.StatusId = object.StatusId;
		this.WorksIn = object.WorksIn;
		this.CarrierInfo = object.CarrierInfo;
		this.FirstAccount = object.FirstAccount;
		this.Assignments = object.Assignments;
	}
}
