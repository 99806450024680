import Repository from '@/repositories/RepositoryFactory';

const OrganizationRepository = Repository.get('admin', 'organization');

export default {
	namespaced: true,
	state: {
		organizations: [],
		selectedOrganization: null,
	},
	getters: {

	},
	mutations: {
		LOAD_ORGANIZATIONS_ADMIN: (state, payload) => {
			state.organizations = payload;
		},
		GET_ORGANIZATION_ADMIN: (state, payload) => {
			state.selectedOrganization = payload;
		},
		RESET_SELECTED_ORGANIZATION: (state) => {
			state.selectedOrganization = {
				Id: null,
				Name: null,
				Address: null,
				City: null,
				State: null,
				ZipCode: null,
				Country: null,
				PhoneNumber: null,
				Email: null,
				Latitude: null,
				Longitude: null,
				Altitude: null,
				PrimaryContact: {
					Name: null,
					Surname: null,
					Email: null,
					PhoneNumber: null,
				},
				SecondaryContact: {
					Name: null,
					Surname: null,
					Email: null,
					PhoneNumber: null,
				},
				StatusId: null,
				AdminData: [],
				SecurityOffices: [],
				HeadOfficeId: null,
				Dealers: [],
			};
		},
	},
	actions: {
		async getOrganizationsFromRepoAdmin ({ commit }) {
			commit('LOAD_ORGANIZATIONS_ADMIN', await OrganizationRepository.get());
		},
		async getOrganizationFromRepoAdmin ({ commit }, payload) {
			commit('GET_ORGANIZATION_ADMIN', await OrganizationRepository.getById(payload.id));
		},
		async createOrganization ({ commit }, object) {
			const response = await OrganizationRepository.store(object);
			return response;
		},
		async updateOrganization ({ commit }, object) {
			const response = await OrganizationRepository.update(object);
			return response;
		},
		resetSelectedOrganizationAdmin ({ commit }) {
			commit('RESET_SELECTED_ORGANIZATION');
		},
	},
};
