import { PRESETS } from '@/utils/dates';

export default {
	namespaced: true,
	state: {
		startDate: PRESETS[0].range[0],
		endDate: PRESETS[0].range[1],
		startTime: '00:00:00',
		endTime: '23:59:59',
	},
	mutations: {
		UPDATE_FILTERS: (state, payload) => {
			state.startDate = payload.pickerStart;
			state.endDate = payload.pickerEnd;
		},
	},
	actions: {
		async updateFilters ({ commit }, payload) {
			commit('UPDATE_FILTERS', payload);
		},
	},
	getters: {
		getFilter (state) {
			return {
				startDate: state.startDate,
				endDate: state.endDate,
			};
		},
	},
};
