import Vue from 'vue';
import Vuex from 'vuex';
import Organization from '@/lib/models/Admin/Organization';
import Repository from '@/repositories/RepositoryFactory';
import modules from './modules';
import createAlarmNotificationPlugin from '@/store/plugins/alarm-notification-sound';

const alarmNotificationPlugin = createAlarmNotificationPlugin();

const SecurityRepository = Repository.get('common', 'security');

const AdminRepositories = {
	OrganizationRepository: Repository.get('admin', 'organization'),
	RoleRepository: Repository.get('admin', 'role'),
	SecurityOfficeRepository: Repository.get('admin', 'securityOffice'),
	UserRepository: Repository.get('admin', 'user'),
};

const ClientRepositories = {
	ClientOrganizationRepository: Repository.get('client', 'organization'),
};

Vue.use(Vuex);

const store = new Vuex.Store({
	modules,
	plugins: [alarmNotificationPlugin],
	state: {
		barColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',
		drawer: null,
		pageSize: { height: 500, width: 500 },
		// roles: [],
		organizations: [],
		selectedOrganization: null,
		// securityOffices: [],
		// users: [],
		// branches: [],
		branchStructure: [],
		csrfToken: null,
		webrtcErrors: null,
	},
	getters: {
		pageHeight: (state) => (add = 0) => { return add + state.pageSize.height + 'px'; },
	},
	mutations: {
		SET_DRAWER (state, payload) {
			state.drawer = payload;
		},
		SET_SCREEN_SIZE (state, payload) {
			state.pageSize = payload.height > 555 ? { height: payload.height - 350, width: payload.width } : { height: 200, width: payload.width };
		},

		LOAD_ORGANIZATIONS: (state, payload) => {
			state.organizations = payload;
		},

		LOAD_ORGANIZATIONS_ADMIN: (state, payload) => {
			state.organizations = payload;
		},

		GET_ORGANIZATION_ADMIN: (state, payload) => {
			state.selectedOrganization = payload;
		},

		RESET_SELECTED_ORGANIZATION_ADMIN: (state) => {
			const defaultObject = {
				Id: null,
				Name: null,
				Address: null,
				City: null,
				State: null,
				ZipCode: null,
				Country: null,
				PhoneNumber: null,
				Status: null,
				StatusId: null,
				PrimaryContact: {
					Name: null,
					Surname: null,
					Email: null,
					Phone: null,
				},
				SecondaryContact: {
					Name: null,
					Surname: null,
					Email: null,
					Phone: null,
				},
				AdminData: {
					Name: null,
					Surname: null,
					Phone: null,
					Code: null,
					Email: null,
				},
				Dealers: [],
				SecurityOffices: [],
			};
			state.selectedOrganization = new Organization(defaultObject);
		},

		GET_ROLES_ADMIN: (state, payload) => {
			state.roles = payload;
		},

		GET_SECURITY_OFFICES_ADMIN: (state, payload) => {
			state.securityOffices = payload;
		},

		GET_EMPLOYEES_ADMIN: (state, payload) => {
			state.users = payload;
		},

		UPDATE_CLIENT_ADMINS_TO_ORGANIZATION: (state, payload) => {
			state.selectedOrganization.AdminData = payload;
		},

		UPDATE_SECURITY_OFFICES_TO_ORGANIZATION: (state, payload) => {
			state.selectedOrganization.SecurityOffices = payload;
		},

		UPDATE_DEALERS_TO_ORGANIZATION: (state, payload) => {
			state.selectedOrganization.Dealers = payload;
		},

		SET_BRANCH_STRUCTURE (state, payload) {
			state.branchStructure = payload.map(organization => {
				return {
					orgId: organization.OrganizationId,
					orgName: organization.OrganizationName,
					regions: organization.Regions === null ? null : organization.Regions.map(region => {
						return {
							regId: region.RegionalOfficeId,
							regName: region.RegionalOfficeName,
							branches: region.Branches === null ? null : region.Branches.map(branch => {
								return {
									branchId: branch.BranchOfficeId,
									branchName: branch.BranchOfficeName,
								};
							}),
						};
					}),
				};
			});
		},
		CSRF_TOKEN (state, token) {
			state.csrfToken = token;
		},

		addRinging () {
			// NO-OP for ringing plugin
		},
		removeRinging () {
			// NO-OP for ringing plugin
		},
		webrtcErrors (state, errors) {
			state.webrtcErrors = errors;
		},
	},
	actions: {
		async getOrganizationsFromRepo ({ commit }) {
			commit('LOAD_ORGANIZATIONS', await ClientRepositories.ClientOrganizationRepository.get());
		},

		async getOrganizationsFromRepoAdmin ({ commit }) {
			commit('LOAD_ORGANIZATIONS_ADMIN', await AdminRepositories.OrganizationRepository.get());
		},

		async getOrganizationFromRepoAdmin ({ commit }, payload) {
			commit('GET_ORGANIZATION_ADMIN', await AdminRepositories.OrganizationRepository.getById(payload.id));
		},

		resetSelectedOrganizationAdmin ({ commit }) {
			commit('RESET_SELECTED_ORGANIZATION_ADMIN');
		},

		async getRolesAdmin ({ commit }) {
			commit('GET_ROLES_ADMIN', await AdminRepositories.RoleRepository.get());
		},

		async getSecurityOfficesAdmin ({ commit }) {
			commit('GET_SECURITY_OFFICES_ADMIN', await AdminRepositories.SecurityOfficeRepository.get());
		},

		async getAllEmployeesAdmin ({ commit }) {
			commit('GET_EMPLOYEES_ADMIN', await AdminRepositories.UserRepository.get());
		},

		async fetchBranchStructure (context) {
			const data = await ClientRepositories.ClientOrganizationRepository.getBranchStructure();
			if (data) {
				context.commit('SET_BRANCH_STRUCTURE', data);
			}
		},

		async getCsrfToken () {
			// the `commit` is done in the Axios interceptor
			await SecurityRepository.getCsrfToken();
		},

		/**
		 * Set up connections to Webapp and Controller WebSockets
		 *
		 * @param {Vuex.Store} store
		 */
		async setupWebSockets (store) {
			await store.dispatch('socket/setConnection');

			// If this is a security session, set up the WebSocket for the controller too.
			if (store.getters['authentication/isVideoUser']) {
				await loadVideoCallPlugins(store);

				await store.dispatch('authentication/requestExternalToken');
			}
		},
	},
});

async function loadVideoCallPlugins ({ commit }) {
	const { default: createControllerWebSocketPlugin } = await import(/* webpackChunkName: "operatorPages" */ './plugins/controller-websocket');
	const { default: createWebRTCPlugin, checkBrowserCompatibility } = await import(/* webpackChunkName: "operatorPages" */ './plugins/browser-webrtc');
	const { default: createRingerPlugin } = await import(/* webpackChunkName: "operatorPages" */ './plugins/ringer');

	const failures = await checkBrowserCompatibility();

	if (failures) {
		commit('webrtcErrors', failures);
	} else {
		commit('webrtcErrors', null);
		createControllerWebSocketPlugin({ namespace: 'videoCalls' })(store);
		createWebRTCPlugin({
			namespace: 'videoCalls',
			remoteMediaEl: 'remoteVideo',
		})(store);
		createRingerPlugin()(store);
	}
}

export default store;
