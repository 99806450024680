export default class SecurityPhrase {
	constructor (object) {
		this.Id = object.SecurityPhraseId;
		this.Phrase = object.Phrase;
		this.IsDuress = object.IsDuress;
		this.Expires = object.Expires;
		this.Created = object.Created;
		this.CanBeEdited = object.CanBeEdited;
		this.CreatedBy = object.CreatedBy;
		this.PhraseCreated = object.Created;
		this.PhraseExpires = object.Expires;
		this.HasExpiration = object.ExpirationCanBeEdited;
	}
}
