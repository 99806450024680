import { APIServerError } from '@/lib/Errors';
import $http from '../../plugins/axios';

export default {
	async getCsrfToken () {
		const response = await $http.get('/security/csrf-token');
		if (response.status !== 204) {
			throw new APIServerError('Unable to get security token');
		}
	},
};
