import safeFormatPhone from '@/utils/phone';

export default class OfficeType {
	constructor (name, surname, email, phone, avatar) {
		this.Name = name;
		this.Surname = surname;
		this.Email = email;
		this.PhoneNumber = safeFormatPhone(phone);
		this.Avatar = avatar;
	}
}
