<template>
  <v-app-bar
    id="app-bar"
    absolute
    app
	flat
    color="transparent"
    height="75"
  >
	<!-- TODO: Support for mobile in future -->
    <v-btn
      class="mr-3"
      elevation="1"
      fab
      small
      @click="setDrawer(!drawer)"
    >
      <v-icon v-if="drawer">
        mdi-dots-vertical
      </v-icon>

      <v-icon v-else>
        mdi-view-dashboard
      </v-icon>
    </v-btn>
    <v-spacer />
	<Profile :dialog="profileDialog" @user-profile-closed="profileDialog=false"></Profile>
	<Console :dialog="consoleDialog" @console-dialog-closed="consoleDialog=false"></Console>

	<RangePicker opens="right" :startDate="filterStartDate" :endDate="filterEndDate" @change="updateVuex"></RangePicker>
	<v-menu
		bottom
		left
		offset-y
		origin="top right"
		transition="scale-transition"
	>
      <template v-slot:activator="{ attrs, on }">
		<v-btn
			class="ml-2"
			min-width="0"
			v-bind="attrs"
			v-on="on"
			id="mdi-account"
			rounded
		>
			<span v-if="authentication.userObject" class="mr-3">{{ avatartInitials }}</span>
			<v-icon>mdi-account-circle</v-icon>
		</v-btn>
      </template>

      <v-list :tile="false" nav dense>
		<template>
			<!-- <base-item
				:key="`item-${i}`"
				:item="item"
				:denseIcon="true"
			/> -->
			<div style="text-align: center">
				<span v-if="authentication.userObject">{{ roleUser }}</span>
				<v-divider></v-divider>
			</div>
			<v-list-item-group
			>
			<v-list-item
					v-for="(item, i) in userMenu"
					:key="i"
					@click="menuActionClick(item.action)"
				>
					<v-list-item-icon>
						<v-icon v-text="item.icon"></v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title v-text="item.text"></v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list-item-group>
		</template>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
// Components
import Profile from '@/views/components/Profile';
import { DateTime } from 'luxon';
import Console from '@/views/components/Console';
import { PRESETS } from '@/utils/dates';
import RangePicker from '@/components/globalFilter/components/Picker.vue';

// Utilities
import { mapState, mapMutations } from 'vuex';
// import { validate } from 'vee-validate';

export default {
	name: 'DashboardCoreAppBar',
	components: {
		Profile,
		Console,
		RangePicker,
		// VDaterange,
		// AppBarItem: {
		// 	render (h) {
		// 		return h(VHover, {
		// 			scopedSlots: {
		// 				default: ({ hover }) => {
		// 					return h(VListItem, {
		// 						attrs: this.$attrs,
		// 						class: {
		// 							'black--text': !hover,
		// 							'white--text btn-color elevation-12': hover,
		// 						},
		// 						props: {
		// 							activeClass: '',
		// 							dark: hover,
		// 							link: true,
		// 							...this.$attrs,
		// 						},
		// 					}, this.$slots.default);
		// 				},
		// 			},
		// 		});
		// 	},
		// },
	},

	props: {
		value: {
			type: Boolean,
			default: false,
		},
	},

	data: () => ({
		filterStartDate: '',
		filterEndDate: '',
		profileDialog: false,
		consoleDialog: false,
		filter: false,
		inputProps: { solo: true, style: { width: '240px' } },
		menuProps: { offsetY: true, closeOnContentClick: false },
		presets: PRESETS,
		formatOfDate: 'MM/DD/YYYY',
	}),
	computed: {
		...mapState(['drawer']),
		...mapState({
			startDate: state => state.filters.startDate,
			endDate: state => state.filters.endDate,
			authentication: state => state.authentication,
		}),
		roleUser () {
			return this.authentication ? `${this.authentication.userObject.UserInfos.RoleNameDisplay}` : '';
		},
		avatartInitials () {
			return this.authentication ? `${this.authentication.userObject.UserInfos.Name}${' '}${this.authentication.userObject.UserInfos.Surname.charAt(0)}` : '';
		},
		startDateFromated () {
			return DateTime.fromISO(this.startDate).toLocaleString();
		},
		endDateFromated () {
			return DateTime.fromISO(this.endDate).toLocaleString();
		},
		userMenu () {
			if (this.$store.getters['authentication/isVideoUser']) {
				return [
					{
						text: 'Profile',
						action: 'profile',
						icon: 'mdi-account',
					},
					{
						text: 'Console',
						action: 'console',
						icon: 'mdi-tune',
					},
					{
						text: 'Logout',
						action: 'logout',
						icon: 'mdi-logout',
					},
				];
			} else {
				return [
					{
						text: 'Profile',
						action: 'profile',
						icon: 'mdi-account',
					},
					{
						text: 'Logout',
						action: 'logout',
						icon: 'mdi-logout',
					},
				];
			}
		},
	},
	methods: {
		...mapMutations({
			setDrawer: 'SET_DRAWER',
		}),
		role () {
			this.userRole = `${this.authentication.userObject.UserInfos.RoleNameDisplay}`;
		},
		menuActionClick (action) {
			if (action === 'profile') {
				this.profileDialog = true;
				this.consoleDialog = false;
			} else if (action === 'console') {
				this.consoleDialog = true;
				this.profileDialog = false;
			} else if (action === 'logout') {
				this.$store.dispatch('authentication/doLogout', {
					type: 'success',
					message: 'You have logged out. For additional security, you may want to close your web browser.',
				});
			}
		},
		updateVuex (data) {
			const payload = {
				pickerStart: data[0].format('yyyy-MM-DD'),
				pickerEnd: data[1].format('yyyy-MM-DD'),
			};
			this.$store.dispatch('filters/updateFilters', payload);
		},
	},
	created () {
		this.filterStartDate = this.startDate;
		this.filterEndDate = this.endDate;
	},
};
</script>

<style>
	.btn-color{
		background: linear-gradient(#557DBE, #3C5886)
	}

	.mdrp__activator .activator-wrapper .text-field:focus~label, .mdrp__activator .activator-wrapper .text-field__filled~label {
		display: none !important;
	}
</style>
