import Repository from '@/repositories/RepositoryFactory';

const SecurityOfficeRepository = Repository.get('admin', 'securityOffice');

export default {
	namespaced: true,
	state: {
		securityOffices: [],
	},
	getters: {

	},
	mutations: {
		LOAD_SECURITY_OFFICES: (state, payload) => {
			state.securityOffices = payload;
		},
	},
	actions: {
		async getSecurityOfficesFromRepo ({ commit }) {
			commit('LOAD_SECURITY_OFFICES', await SecurityOfficeRepository.get());
		},
		async storeSecurityOffice ({ commit }, object) {
			const response = await SecurityOfficeRepository.store(object);
			return response;
		},
		async updateSecurityOffice ({ commit }, object) {
			const response = await SecurityOfficeRepository.update(object);
			return response;
		},
	},
};
