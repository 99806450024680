import { NotificationError } from '@/lib/Errors';
import EnsNotification from '@/lib/models/Client/EnsNotification';
import EnsMessageTemplate from '@/lib/models/Client/EnsMessageTemplate';
import $http from '../../../plugins/axios';

async function getNotifications (payload, typeName) {
	const response = await $http.get('/api/client/getnotificationsv2', { params: payload });

	if (response.data?.success) {
		const body = response.data.body.notificationobject || [];
		if (body === null) {
			return [];
		}
		return body;
	}

	throw new NotificationError(`Error retrieving ${typeName} Notifications`);
}

export default {
	async getAllNotifications (object) {
		object.case = 'GET-ALL-NOTIFICATIONS-WEB';

		return getNotifications(object, 'Normal');
	},
	async getNotificationById (object) {
		object.case = 'GET-NOTIFICATION-BY-ID';

		return getNotifications(object, 'Normal');
	},
	async getAlarmNotifications (object) {
		object.case = 'GET-TROUBLE-NOTIFICATIONS-WEB';

		return getNotifications(object, 'Alarm');
	},
	async getPanicNotifications (object) {
		object.case = 'GET-PANIC-NOTIFICATIONS-WEB';

		return getNotifications(object, 'Panic');
	},
	async getAnomalyNotifications (object) {
		object.case = 'GET-ALL-NOTIFICATIONS-NOT-DONES-WEB';

		return getNotifications(object, 'Anomaly');
	},
	async getEmergencyNotifications (object) {
		object.case = 'GET-EMERGENCY-NOTIFICATIONS-WEB';

		return getNotifications(object, 'Emergency');
	},
	async getEnsNotifications (object) {
		object.case = 'GET-EMERGENCY-CONSOLE-NOTIFICATIONS';

		const response = await $http.post('/api/client/getnotifications', object);

		if (response.data?.success) {
			const body = response.data.body.notificationobject || [];
			const notifications = body.map(n => {
				const notification = new EnsNotification(n);
				return notification;
			});
			return notifications;
		} else {
			throw new NotificationError('Error retrieving ENS Notifications');
		}
	},
	async markAllAsRead (object) {
		const response = await $http.post('/api/client/getnotifications', object);

		if (response.data?.success) {
			return response.data.body;
		} else {
			throw new NotificationError('Error marking notifications as read');
		}
	},
	async getEnsMessageTemplate (object) {
		object.case = 'GET-ALL-TEMPLATES';

		const response = await $http.post('/api/client/manageensmessagetemplates', object);

		if (response.data?.success) {
			const data = response.data.body.templateobject || [];
			const templates = data.map(r => {
				const template = new EnsMessageTemplate(r);
				return template;
			});
			return templates;
		} else {
			throw new NotificationError('Error retreiving ENS notifications');
		}
	},
	async markAsReadNotification (id) {
		const body = {
			case: 'READ-NOTIFICATION-BY-ID',
			taskid: id,
		};

		const response = await $http.post('/api/client/getnotifications', body);

		if (response.data?.success) {
			return response.data.body;
		} else {
			throw new NotificationError('Error marking notification as read');
		}
	},
	async sendEnsMessage (object) {
		object.case = 'NOTIFY-EMEGREGENCY';

		const response = await $http.post('/api/client/manageensmessagetemplates', object);

		if (response.data?.success) {
			return response.data.body;
		} else {
			throw new NotificationError('Error sending ENS message');
		}
	},
	async updateNotificationSettings (object) {
		object.case = 'UPDATE-MY-NOTIFICATIONS-SETTINGS';
		const response = await $http.post('/api/client/updatenotificationsettingsweb', object);

		if (response.data?.success) {
			return response.data.body;
		} else {
			throw new NotificationError('Error sending ENS message');
		}
	},
	async getNotificationSettingForUser (object) {
		object.case = 'GET-MY-NOTIFICATIONS-SETTINGS';
		const response = await $http.post('/api/client/updatenotificationsallowed', object);

		if (response.data?.success) {
			return response.data.body;
		} else {
			throw new NotificationError('Error retrieving notification settings');
		}
	},
	async getNotificationsAllowed (object) {
		object.case = 'GET-MY-NOTIFICATIONS-ALLOWED';
		const response = await $http.post('/api/client/updatenotificationsallowed', object);

		if (response.data?.success) {
			return response.data.body;
		} else {
			throw new NotificationError('Error retrieving notification settings');
		}
	},
	async updateNotificationsAllowed (object) {
		object.case = 'UPDATE-MY-NOTIFICATIONS-ALLOWED';
		const response = await $http.post('/api/client/updatenotificationsallowed', object);

		if (response.data?.success) {
			return response.data.body;
		} else {
			throw new NotificationError('Error updating notification settings');
		}
	},
};
