/* Countries and country codes $ */

const countries = [
	{ name: 'United States of America', dial_code: '+1', code: 'US', locale: 'en-US' },
	{ name: 'Afghanistan', dial_code: '+93', code: 'AF', locale: 'fa-AF' },
	{ name: 'Aland Islands', dial_code: '+358', code: 'AX', locale: 'sv-AX' },
	{ name: 'Albania', dial_code: '+355', code: 'AL', locale: 'sq-AL' },
	{ name: 'Algeria', dial_code: '+213', code: 'DZ', locale: 'ar-DZ' },
	{ name: 'American Samoa', dial_code: '+1 684', code: 'AS', locale: 'en-AS' },
	{ name: 'Andorra', dial_code: '+376', code: 'AD', locale: 'ca-AD' },
	{ name: 'Angola', dial_code: '+244', code: 'AO', locale: 'pt-AO' },
	{ name: 'Anguilla', dial_code: '+1 264', code: 'AI', locale: 'en-AI' },
	{ name: 'Antigua and Barbuda', dial_code: '+1268', code: 'AG', locale: 'en-AG' },
	{ name: 'Argentina', dial_code: '+54', code: 'AR', locale: 'es-AR' },
	{ name: 'Armenia', dial_code: '+374', code: 'AM', locale: 'hy-AM' },
	{ name: 'Aruba', dial_code: '+297', code: 'AW', locale: 'nl-AW' },
	{ name: 'Australia', dial_code: '+61', code: 'AU', locale: 'en-AU' },
	{ name: 'Austria', dial_code: '+43', code: 'AT', locale: 'de-AT' },
	{ name: 'Azerbaijan', dial_code: '+994', code: 'AZ', locale: 'az-AZ' },
	{ name: 'Bahamas', dial_code: '+1 242', code: 'BS', locale: 'en-BS' },
	{ name: 'Bahrain', dial_code: '+973', code: 'BH', locale: 'ar-BH' },
	{ name: 'Bangladesh', dial_code: '+880', code: 'BD', locale: 'bn-BD' },
	{ name: 'Barbados', dial_code: '+1 246', code: 'BB', locale: 'en-BB' },
	{ name: 'Belarus', dial_code: '+375', code: 'BY', locale: 'be-BY' },
	{ name: 'Belgium', dial_code: '+32', code: 'BE', locale: 'nl-BE' },
	{ name: 'Belize', dial_code: '+501', code: 'BZ', locale: 'en-BZ' },
	{ name: 'Benin', dial_code: '+229', code: 'BJ', locale: 'fr-BJ' },
	{ name: 'Bermuda', dial_code: '+1 441', code: 'BM', locale: 'en-BM' },
	{ name: 'Bhutan', dial_code: '+975', code: 'BT', locale: 'dz-BT' },
	{ name: 'Bolivia, Plurinational State of', dial_code: '+591', code: 'BO', locale: 'es-BO' },
	{ name: 'Bosnia and Herzegovina', dial_code: '+387', code: 'BA', locale: 'bs-BA' },
	{ name: 'Botswana', dial_code: '+267', code: 'BW', locale: 'en-BW' },
	{ name: 'Brazil', dial_code: '+55', code: 'BR', locale: 'pt-BR' },
	{ name: 'British Indian Ocean Territory', dial_code: '+246', code: 'IO', locale: 'en-IO' },
	{ name: 'Brunei Darussalam', dial_code: '+673', code: 'BN', locale: 'ms-BN' },
	{ name: 'Bulgaria', dial_code: '+359', code: 'BG', locale: 'bg-BG' },
	{ name: 'Burkina Faso', dial_code: '+226', code: 'BF', locale: 'fr-BF' },
	{ name: 'Burundi', dial_code: '+257', code: 'BI', locale: 'fr-BI' },
	{ name: 'Cambodia', dial_code: '+855', code: 'KH', locale: 'km-KH' },
	{ name: 'Cameroon', dial_code: '+237', code: 'CM', locale: 'fr-CM' },
	{ name: 'Canada', dial_code: '+1', code: 'CA', locale: 'en-CA' },
	{ name: 'Cape Verde', dial_code: '+238', code: 'CV', locale: 'pt-CV' },
	{ name: 'Cayman Islands', dial_code: '+ 345', code: 'KY', locale: 'en-KY' },
	{ name: 'Central African Republic', dial_code: '+236', code: 'CF', locale: 'fr-CF' },
	{ name: 'Chad', dial_code: '+235', code: 'TD', locale: 'fr-TD' },
	{ name: 'Chile', dial_code: '+56', code: 'CL', locale: 'es-CL' },
	{ name: 'China', dial_code: '+86', code: 'CN', locale: 'zh-CN' },
	{ name: 'Christmas Island', dial_code: '+61', code: 'CX', locale: 'en-CX' },
	{ name: 'Cocos (Keeling) Islands', dial_code: '+61', code: 'CC', locale: 'en-CC' },
	{ name: 'Colombia', dial_code: '+57', code: 'CO', locale: 'es-CO' },
	{ name: 'Comoros', dial_code: '+269', code: 'KM', locale: 'ar-KM' },
	{ name: 'Congo', dial_code: '+242', code: 'CG', locale: 'fr-CG' },
	{ name: 'Congo, The Democratic Republic of the Congo', dial_code: '+243', code: 'CD', locale: 'fr-CD' },
	{ name: 'Cook Islands', dial_code: '+682', code: 'CK', locale: 'en-CK' },
	{ name: 'Costa Rica', dial_code: '+506', code: 'CR', locale: 'es-CR' },
	{ name: 'Cote d\'Ivoire', dial_code: '+225', code: 'CI', locale: 'fr-CI' },
	{ name: 'Croatia', dial_code: '+385', code: 'HR', locale: 'hr-HR' },
	{ name: 'Cuba', dial_code: '+53', code: 'CU', locale: 'es-CU' },
	{ name: 'Cyprus', dial_code: '+357', code: 'CY', locale: 'el-CY' },
	{ name: 'Czech Republic', dial_code: '+420', code: 'CZ', locale: 'cs-CZ' },
	{ name: 'Denmark', dial_code: '+45', code: 'DK', locale: 'da-DK' },
	{ name: 'Djibouti', dial_code: '+253', code: 'DJ', locale: 'fr-DJ' },
	{ name: 'Dominica', dial_code: '+1 767', code: 'DM', locale: 'en-DM' },
	{ name: 'Dominican Republic', dial_code: '+1 849', code: 'DO', locale: 'es-DO' },
	{ name: 'Ecuador', dial_code: '+593', code: 'EC', locale: 'es-EC' },
	{ name: 'Egypt', dial_code: '+20', code: 'EG', locale: 'ar-EG' },
	{ name: 'El Salvador', dial_code: '+503', code: 'SV', locale: 'es-SV' },
	{ name: 'Equatorial Guinea', dial_code: '+240', code: 'GQ', locale: 'es-GQ' },
	{ name: 'Eritrea', dial_code: '+291', code: 'ER', locale: 'ti-ER' },
	{ name: 'Estonia', dial_code: '+372', code: 'EE', locale: 'et-EE' },
	{ name: 'Ethiopia', dial_code: '+251', code: 'ET', locale: 'am-ET' },
	{ name: 'Falkland Islands (Malvinas)', dial_code: '+500', code: 'FK', locale: 'en-FK' },
	{ name: 'Faroe Islands', dial_code: '+298', code: 'FO', locale: 'fo-FO' },
	{ name: 'Fiji', dial_code: '+679', code: 'FJ', locale: 'en-FJ' },
	{ name: 'Finland', dial_code: '+358', code: 'FI', locale: 'fi-FI' },
	{ name: 'France', dial_code: '+33', code: 'FR', locale: 'fr-FR' },
	{ name: 'French Guiana', dial_code: '+594', code: 'GF', locale: 'fr-GF' },
	{ name: 'French Polynesia', dial_code: '+689', code: 'PF', locale: 'fr-PF' },
	{ name: 'Gabon', dial_code: '+241', code: 'GA', locale: 'fr-GA' },
	{ name: 'Gambia', dial_code: '+220', code: 'GM', locale: 'en-GM' },
	{ name: 'Georgia', dial_code: '+995', code: 'GE', locale: 'ka-GE' },
	{ name: 'Germany', dial_code: '+49', code: 'DE', locale: 'de-DE' },
	{ name: 'Ghana', dial_code: '+233', code: 'GH', locale: 'en-GH' },
	{ name: 'Gibraltar', dial_code: '+350', code: 'GI', locale: 'en-GI' },
	{ name: 'Greece', dial_code: '+30', code: 'GR', locale: 'el-GR' },
	{ name: 'Greenland', dial_code: '+299', code: 'GL', locale: 'kl-GL' },
	{ name: 'Grenada', dial_code: '+1 473', code: 'GD', locale: 'en-GD' },
	{ name: 'Guadeloupe', dial_code: '+590', code: 'GP', locale: 'fr-GP' },
	{ name: 'Guam', dial_code: '+1 671', code: 'GU', locale: 'en-GU' },
	{ name: 'Guatemala', dial_code: '+502', code: 'GT', locale: 'es-GT' },
	{ name: 'Guernsey', dial_code: '+44', code: 'GG', locale: 'en-GG' },
	{ name: 'Guinea', dial_code: '+224', code: 'GN', locale: 'fr-GN' },
	{ name: 'Guinea-Bissau', dial_code: '+245', code: 'GW', locale: 'pt-GW' },
	{ name: 'Guyana', dial_code: '+595', code: 'GY', locale: 'en-GY' },
	{ name: 'Haiti', dial_code: '+509', code: 'HT', locale: 'fr-HT' },
	{ name: 'Holy See (Vatican City State)', dial_code: '+379', code: 'VA', locale: 'it-VA' },
	{ name: 'Honduras', dial_code: '+504', code: 'HN', locale: 'es-HN' },
	{ name: 'Hong Kong', dial_code: '+852', code: 'HK', locale: 'en-HK' },
	{ name: 'Hungary', dial_code: '+36', code: 'HU', locale: 'hu-HU' },
	{ name: 'Iceland', dial_code: '+354', code: 'IS', locale: 'is-IS' },
	{ name: 'India', dial_code: '+91', code: 'IN', locale: 'en-IN' },
	{ name: 'Indonesia', dial_code: '+62', code: 'ID', locale: 'id-ID' },
	{ name: 'Iran, Islamic Republic of Persian Gulf', dial_code: '+98', code: 'IR', locale: 'fa-IR' },
	{ name: 'Iraq', dial_code: '+964', code: 'IQ', locale: 'ar-IQ' },
	{ name: 'Ireland', dial_code: '+353', code: 'IE', locale: 'en-IE' },
	{ name: 'Isle of Man', dial_code: '+44', code: 'IM', locale: 'en-IM' },
	{ name: 'Israel', dial_code: '+972', code: 'IL', locale: 'he-IL' },
	{ name: 'Italy', dial_code: '+39', code: 'IT', locale: 'it-IT' },
	{ name: 'Jamaica', dial_code: '+1 876', code: 'JM', locale: 'en-JM' },
	{ name: 'Japan', dial_code: '+81', code: 'JP', locale: 'ja-JP' },
	{ name: 'Jersey', dial_code: '+44', code: 'JE', locale: 'en-JE' },
	{ name: 'Jordan', dial_code: '+962', code: 'JO', locale: 'ar-JO' },
	{ name: 'Kazakhstan', dial_code: '+7 7', code: 'KZ', locale: 'kk-KZ' },
	{ name: 'Kenya', dial_code: '+254', code: 'KE', locale: 'en-KE' },
	{ name: 'Kiribati', dial_code: '+686', code: 'KI', locale: 'en-KI' },
	{ name: 'Korea, Democratic People\'s Republic of Korea', dial_code: '+850', code: 'KP', locale: 'ko-KP' },
	{ name: 'Korea, Republic of South Korea', dial_code: '+82', code: 'KR', locale: 'ko-KR' },
	{ name: 'Kuwait', dial_code: '+965', code: 'KW', locale: 'ar-KW' },
	{ name: 'Kyrgyzstan', dial_code: '+996', code: 'KG', locale: 'ky-KG' },
	{ name: 'Laos', dial_code: '+856', code: 'LA', locale: 'lo-LA' },
	{ name: 'Latvia', dial_code: '+371', code: 'LV', locale: 'lv-LV' },
	{ name: 'Lebanon', dial_code: '+961', code: 'LB', locale: 'ar-LB' },
	{ name: 'Lesotho', dial_code: '+266', code: 'LS', locale: 'en-LS' },
	{ name: 'Liberia', dial_code: '+231', code: 'LR', locale: 'en-LR' },
	{ name: 'Libyan Arab Jamahiriya', dial_code: '+218', code: 'LY', locale: 'ar-LY' },
	{ name: 'Liechtenstein', dial_code: '+423', code: 'LI', locale: 'de-LI' },
	{ name: 'Lithuania', dial_code: '+370', code: 'LT', locale: 'lt-LT' },
	{ name: 'Luxembourg', dial_code: '+352', code: 'LU', locale: 'lb-LU' },
	{ name: 'Macao', dial_code: '+853', code: 'MO', locale: 'zh-MO' },
	{ name: 'Macedonia', dial_code: '+389', code: 'MK', locale: 'mk-MK' },
	{ name: 'Madagascar', dial_code: '+261', code: 'MG', locale: 'mg-MG' },
	{ name: 'Malawi', dial_code: '+265', code: 'MW', locale: 'en-MW' },
	{ name: 'Malaysia', dial_code: '+60', code: 'MY', locale: 'ms-MY' },
	{ name: 'Maldives', dial_code: '+960', code: 'MV', locale: 'dv-MV' },
	{ name: 'Mali', dial_code: '+223', code: 'ML', locale: 'fr-ML' },
	{ name: 'Malta', dial_code: '+356', code: 'MT', locale: 'mt-MT' },
	{ name: 'Marshall Islands', dial_code: '+692', code: 'MH', locale: 'en-MH' },
	{ name: 'Martinique', dial_code: '+596', code: 'MQ', locale: 'fr-MQ' },
	{ name: 'Mauritania', dial_code: '+222', code: 'MR', locale: 'ar-MR' },
	{ name: 'Mauritius', dial_code: '+230', code: 'MU', locale: 'fr-MU' },
	{ name: 'Mayotte', dial_code: '+262', code: 'YT', locale: 'fr-YT' },
	{ name: 'Mexico', dial_code: '+52', code: 'MX', locale: 'es-MX' },
	{ name: 'Micronesia, Federated States of Micronesia', dial_code: '+691', code: 'FM', locale: 'en-FM' },
	{ name: 'Moldova', dial_code: '+373', code: 'MD', locale: 'ru-MD' },
	{ name: 'Monaco', dial_code: '+377', code: 'MC', locale: 'fr-MC' },
	{ name: 'Mongolia', dial_code: '+976', code: 'MN', locale: 'mn-MN' },
	{ name: 'Montenegro', dial_code: '+382', code: 'ME', locale: 'sr-ME' },
	{ name: 'Montserrat', dial_code: '+1664', code: 'MS', locale: 'en-MS' },
	{ name: 'Morocco', dial_code: '+212', code: 'MA', locale: 'ar-MA' },
	{ name: 'Mozambique', dial_code: '+258', code: 'MZ', locale: 'pt-MZ' },
	{ name: 'Myanmar', dial_code: '+95', code: 'MM', locale: 'my-MM' },
	{ name: 'Namibia', dial_code: '+264', code: 'NA', locale: 'en-NA' },
	{ name: 'Nauru', dial_code: '+674', code: 'NR', locale: 'en-NR' },
	{ name: 'Nepal', dial_code: '+977', code: 'NP', locale: 'ne-NP' },
	{ name: 'Netherlands', dial_code: '+31', code: 'NL', locale: 'nl-NL' },
	{ name: 'Netherlands Antilles', dial_code: '+599', code: 'AN', locale: 'nl-AN' },
	{ name: 'New Caledonia', dial_code: '+687', code: 'NC', locale: 'fr-NC' },
	{ name: 'New Zealand', dial_code: '+64', code: 'NZ', locale: 'en-NZ' },
	{ name: 'Nicaragua', dial_code: '+505', code: 'NI', locale: 'es-NI' },
	{ name: 'Niger', dial_code: '+227', code: 'NE', locale: 'fr-NE' },
	{ name: 'Nigeria', dial_code: '+234', code: 'NG', locale: 'en-NG' },
	{ name: 'Niue', dial_code: '+683', code: 'NU', locale: 'en-NU' },
	{ name: 'Norfolk Island', dial_code: '+672', code: 'NF', locale: 'en-NF' },
	{ name: 'Northern Mariana Islands', dial_code: '+1 670', code: 'MP', locale: 'ch-MP' },
	{ name: 'Norway', dial_code: '+47', code: 'NO', locale: 'no-NO' },
	{ name: 'Oman', dial_code: '+968', code: 'OM', locale: 'ar-OM' },
	{ name: 'Pakistan', dial_code: '+92', code: 'PK', locale: 'en-PK' },
	{ name: 'Palau', dial_code: '+680', code: 'PW', locale: 'en-PW' },
	{ name: 'Palestinian Territory', dial_code: '+970', code: 'PS', locale: 'ar-PS' },
	{ name: 'Panama', dial_code: '+507', code: 'PA', locale: 'es-PA' },
	{ name: 'Papua New Guinea', dial_code: '+675', code: 'PG', locale: 'en-PG' },
	{ name: 'Paraguay', dial_code: '+595', code: 'PY', locale: 'es-PY' },
	{ name: 'Peru', dial_code: '+51', code: 'PE', locale: 'es-PE' },
	{ name: 'Philippines', dial_code: '+63', code: 'PH', locale: 'en-PH' },
	{ name: 'Pitcairn', dial_code: '+872', code: 'PN', locale: 'en-PN' },
	{ name: 'Poland', dial_code: '+48', code: 'PL', locale: 'pl-PL' },
	{ name: 'Portugal', dial_code: '+351', code: 'PT', locale: 'pt-PT' },
	{ name: 'Puerto Rico', dial_code: '+1 939', code: 'PR', locale: 'en-PR' },
	{ name: 'Qatar', dial_code: '+974', code: 'QA', locale: 'ar-QA' },
	{ name: 'Romania', dial_code: '+40', code: 'RO', locale: 'ro-RO' },
	{ name: 'Russia', dial_code: '+7', code: 'RU', locale: 'ru-RU' },
	{ name: 'Rwanda', dial_code: '+250', code: 'RW', locale: 'rw-RW' },
	{ name: 'Reunion', dial_code: '+262', code: 'RE', locale: 'fr-RE' },
	{ name: 'Saint Barthelemy', dial_code: '+590', code: 'BL', locale: 'fr-BL' },
	{ name: 'Saint Helena, Ascension and Tristan Da Cunha', dial_code: '+290', code: 'SH', locale: 'en-SH' },
	{ name: 'Saint Kitts and Nevis', dial_code: '+1 869', code: 'KN', locale: 'en-KN' },
	{ name: 'Saint Lucia', dial_code: '+1 758', code: 'LC', locale: 'en-LC' },
	{ name: 'Saint Martin', dial_code: '+590', code: 'MF', locale: 'fr-MF' },
	{ name: 'Saint Pierre and Miquelon', dial_code: '+508', code: 'PM', locale: 'fr-PM' },
	{ name: 'Saint Vincent and the Grenadines', dial_code: '+1 784', code: 'VC', locale: 'en-VC' },
	{ name: 'Samoa', dial_code: '+685', code: 'WS', locale: 'en-WS' },
	{ name: 'San Marino', dial_code: '+378', code: 'SM', locale: 'it-SM' },
	{ name: 'Sao Tome and Principe', dial_code: '+239', code: 'ST', locale: 'pt-ST' },
	{ name: 'Saudi Arabia', dial_code: '+966', code: 'SA', locale: 'ar-SA' },
	{ name: 'Senegal', dial_code: '+221', code: 'SN', locale: 'fr-SN' },
	{ name: 'Serbia', dial_code: '+381', code: 'RS', locale: 'rs-RS' },
	{ name: 'Seychelles', dial_code: '+248', code: 'SC', locale: 'fr-SC' },
	{ name: 'Sierra Leone', dial_code: '+232', code: 'SL', locale: 'en-SL' },
	{ name: 'Singapore', dial_code: '+65', code: 'SG', locale: 'en-SG' },
	{ name: 'Slovakia', dial_code: '+421', code: 'SK', locale: 'sk-SK' },
	{ name: 'Slovenia', dial_code: '+386', code: 'SI', locale: 'sl-SI' },
	{ name: 'Solomon Islands', dial_code: '+677', code: 'SB', locale: 'en-SB' },
	{ name: 'Somalia', dial_code: '+252', code: 'SO', locale: 'so-SO' },
	{ name: 'South Africa', dial_code: '+27', code: 'ZA', locale: 'en-ZA' },
	{ name: 'South Georgia and the South Sandwich Islands', dial_code: '+500', code: 'GS', locale: 'en-GB' },
	{ name: 'Spain', dial_code: '+34', code: 'ES', locale: 'es-ES' },
	{ name: 'Sri Lanka', dial_code: '+94', code: 'LK', locale: 'si-LK' },
	{ name: 'Sudan', dial_code: '+249', code: 'SD', locale: 'ar-SD' },
	{ name: 'Suriname', dial_code: '+597', code: 'SR', locale: 'nl-SR' },
	{ name: 'Svalbard and Jan Mayen', dial_code: '+47', code: 'SJ', locale: 'no-SJ' },
	{ name: 'Swaziland', dial_code: '+268', code: 'SZ', locale: 'en-SZ' },
	{ name: 'Sweden', dial_code: '+46', code: 'SE', locale: 'sv-SE' },
	{ name: 'Switzerland', dial_code: '+41', code: 'CH', locale: 'de-CH' },
	{ name: 'Syrian Arab Republic', dial_code: '+963', code: 'SY', locale: 'ar-SY' },
	{ name: 'Taiwan', dial_code: '+886', code: 'TW', locale: 'zh-TW' },
	{ name: 'Tajikistan', dial_code: '+992', code: 'TJ', locale: 'tg-TJ' },
	{ name: 'Tanzania, United Republic of Tanzania', dial_code: '+255', code: 'TZ', locale: 'en-TZ' },
	{ name: 'Thailand', dial_code: '+66', code: 'TH', locale: 'th-TH' },
	{ name: 'Timor-Leste', dial_code: '+670', code: 'TL', locale: 'pt-TL' },
	{ name: 'Togo', dial_code: '+228', code: 'TG', locale: 'fr-TG' },
	{ name: 'Tokelau', dial_code: '+690', code: 'TK', locale: 'en-TK' },
	{ name: 'Tonga', dial_code: '+676', code: 'TO', locale: 'en-TO' },
	{ name: 'Trinidad and Tobago', dial_code: '+1 868', code: 'TT', locale: 'en-TT' },
	{ name: 'Tunisia', dial_code: '+216', code: 'TN', locale: 'ar-TN' },
	{ name: 'Turkey', dial_code: '+90', code: 'TR', locale: 'tr-TR' },
	{ name: 'Turkmenistan', dial_code: '+993', code: 'TM', locale: 'tk-TM' },
	{ name: 'Turks and Caicos Islands', dial_code: '+1 649', code: 'TC', locale: 'en-TC' },
	{ name: 'Tuvalu', dial_code: '+688', code: 'TV', locale: 'en-TV' },
	{ name: 'Uganda', dial_code: '+256', code: 'UG', locale: 'en-UG' },
	{ name: 'Ukraine', dial_code: '+380', code: 'UA', locale: 'uk-UA' },
	{ name: 'United Arab Emirates', dial_code: '+971', code: 'AE', locale: 'ar-AE' },
	{ name: 'United Kingdom', dial_code: '+44', code: 'GB', locale: 'en-GB' },
	{ name: 'Uruguay', dial_code: '+598', code: 'UY', locale: 'es-UY' },
	{ name: 'Uzbekistan', dial_code: '+998', code: 'UZ', locale: 'uz-UZ' },
	{ name: 'Vanuatu', dial_code: '+678', code: 'VU', locale: 'bi-VU' },
	{ name: 'Venezuela, Bolivarian Republic of Venezuela', dial_code: '+58', code: 'VE', locale: 'es-VE' },
	{ name: 'Vietnam', dial_code: '+84', code: 'VN', locale: 'vi-VN' },
	{ name: 'Virgin Islands, British', dial_code: '+1 284', code: 'VG', locale: 'en-VG' },
	{ name: 'Virgin Islands, U.S.', dial_code: '+1 340', code: 'VI', locale: 'en-VI' },
	{ name: 'Wallis and Futuna', dial_code: '+681', code: 'WF', locale: 'fr-WF' },
	{ name: 'Yemen', dial_code: '+967', code: 'YE', locale: 'ar-YE' },
	{ name: 'Zambia', dial_code: '+260', code: 'ZM', locale: 'en-ZM' },
	{ name: 'Zimbabwe', dial_code: '+263', code: 'ZW', locale: 'en-ZW' },
];

export default countries;
