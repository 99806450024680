import HeadOffice from '@/lib/models/Client/HeadOffice';
import { HeadOfficeError } from '@/lib/Errors';
import $http from '../../../plugins/axios';

export default {
	async get () {
		const body = {
			case: 'GET-HEAD-OFFICE',
		};

		const response = await $http.post('/api/client/getoffices', body);

		if (response.data?.success) {
			const data = response.data.body.organizationobject;
			const headOffice = new HeadOffice(data);
			return headOffice;
		} else {
			throw new HeadOfficeError('Error retrieving Head Offices');
		}
	},
	async updateTimers (object) {
		object.case = 'UPDATE-HEAD-OFFICE-TIMERS';

		return modifyHeadOffice(object, 'Error saving Head Office Default Timers');
	},
	async updateHeadOfficeWeekSchedule (object) {
		object.case = 'UPDATE-HEAD-OFFICE-WEEKSCHEDULE';

		return modifyHeadOffice(object, 'Error saving Head Office Week Schedule');
	},
	async updateHeadOfficeHolidays (object) {
		object.case = 'UPDATE-HEAD-OFFICE-HOLIDAYS';

		return modifyHeadOffice(object, 'Error saving Region Holidays');
	},
};

async function modifyHeadOffice (body, errorMessage) {
	const response = await $http.post('/api/client/getoffices', body);

	if (response.data?.success) {
		return response.data.body;
	} else {
		throw new HeadOfficeError(errorMessage);
	}
}
