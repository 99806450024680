import safeFormatPhone from '@/utils/phone';

export default class VideoSession {
	constructor (object) {
		this.Id = object.id;
		this.Text_id = object.text_id;
		this.Media_filename = object.media_filename;
		this.Time_stamp = object.time_stamp;
		this.Id_dup = object.id_dup;
		this.First_name = object.first_name;
		this.Last_name = object.last_namel;
		this.Email = object.email;
		this.Language = object.language;
		this.Organization_name = object.organization_name;
		this.Organization_id = object.organization_id;
		this.Blood_type = object.blood_type;
		this.Hearing_impaired = object.hearing_impaired;
		this.Height = object.height;
		this.Weight = object.weight;
		this.Special = object.special;
		this.Phone_number = safeFormatPhone(object.phone_number);
		this.contact_name1 = object.contact_name1;
		this.Contact_relationship1 = object.contact_relationship1;
		this.Contact_phone1 = object.contact_phone1;
		this.Contact_email1 = object.contact_email1;
		this.Contact_name2 = object.contact_name2;
		this.Contact_relationship2 = object.contact_relationship2;
		this.Contact_phone2 = object.contact_phone2;
		this.Contact_email2 = object.contact_email2;
		this.Contact_name3 = object.contact_name3;
		this.Contact_relationship3 = object.contact_relationship3;
		this.Contact_phone3 = object.contact_phone3;
		this.Contact_email3 = object.contact_email3;
		this.Console_text_id = object.console_text_id ? object.console_text_id : null;
		this.Console_first_name = object.console_first_name ? object.console_first_name : null;
		this.Console_last_name = object.console_last_name ? object.console_last_name : null;
		this.Answer_time = object.answer_time ? object.answer_time : null;
		this.time_zone_code = object.time_zone_code ? object.time_zone_code : null;
		this.WorksIn = object.time_zone_code ? object.time_zone_code : null;
		this.Time_zone = object.time_zone;
		this.Start_time = object.start_time;
	}
}
