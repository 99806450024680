import Repository from '@/repositories/RepositoryFactory';

const ReportRepository = Repository.get('client', 'report');

export default {
	namespaced: true,
	state: {
		dailyReports: [],
		activityReports: [],
		activityReportDetails: {},
		surveyReports: [],
		surveyReportDetails: [],
		checkInOutReports: [],
		summaryReports: [],
		timerReports: [],
	},
	getters: {

	},
	mutations: {
		LOAD_DAILY_REPORTS: (state, payload) => {
			state.dailyReports = payload;
		},
		LOAD_ACTIVITY_REPORTS: (state, payload) => {
			state.activityReports = payload;
		},
		LOAD_ACTIVITY_REPORT_DETAILS: (state, payload) => {
			state.activityReportDetails = payload;
		},
		LOAD_CHECKINOUT_REPORTS: (state, payload) => {
			state.checkInOutReports = payload;
		},
		LOAD_SURVEY_REPORTS: (state, payload) => {
			state.surveyReports = payload;
		},
		LOAD_SURVEY_REPORT_DETAILS: (state, payload) => {
			state.surveyReportDetails = payload;
		},
		LOAD_SUMMARY_REPORTS: (state, payload) => {
			state.summaryReports = payload;
		},
		LOAD_TIMER_REPORTS: (state, payload) => {
			state.timerReports = payload;
		},
	},
	actions: {
		async getDailyReportsFromRepo ({ commit }, object) {
			commit('LOAD_DAILY_REPORTS', await ReportRepository.getDailyReports(object));
		},

		async getActivityReportsFromRepo ({ commit }, object) {
			commit('LOAD_ACTIVITY_REPORTS', await ReportRepository.getActivityReports(object));
		},

		async getActivityReportDetailsFromRepo ({ commit }, id) {
			commit('LOAD_ACTIVITY_REPORT_DETAILS', await ReportRepository.getActivityReportDetails(id));
		},

		async getCheckInOutReportsFromRepo ({ commit }, filter) {
			commit('LOAD_CHECKINOUT_REPORTS', await ReportRepository.getCheckInOutReports(filter));
		},

		async getSurveyReportsFromRepo ({ commit }, object) {
			commit('LOAD_SURVEY_REPORTS', await ReportRepository.getSurveyReports(object));
		},

		async getSurveyReportDetails ({ commit }, object) {
			commit('LOAD_SURVEY_REPORT_DETAILS', await ReportRepository.getSurveyReportDetails(object));
		},
		async getSummaryReports ({ commit }, object) {
			commit('LOAD_SUMMARY_REPORTS', await ReportRepository.getSummaryReports(object));
		},
		async getTimerReportsFromRepo ({ commit }, object) {
			commit('LOAD_TIMER_REPORTS', await ReportRepository.getTimerReports(object));
		},
	},
};
