export default class SurveyReport {
	constructor (object) {
		this.SubmitetSurveyId = object.SubmitetSurveyId;
		this.EndWithNotification = object.EndWithNotification ? object.EndWithNotification : null;
		this.NotificationQuestionId = object.NotificationQuestionId ? object.NotificationQuestionId : null;
		this.SurveyId = object.SurveyId;
		this.CreatedAtUTC = object.CreatedAtUTC ? object.CreatedAtUTC : null;
		this.UserName = object.UserName;
		this.UserSurname = object.UserSurname;
		this.UserId = object.UserId;
		this.RoleId = object.RoleId;
		this.RoleName = object.RoleName;
		this.RoleNameDisplay = object.RoleNameDisplay;
		this.BranchOfficeId = object.BranchOfficeId;
		this.BranchOfficeName = object.BranchOfficeName;
		this.BranchDst = object.BranchDst;
		this.BranchOfficeTypeDisplay = object.BranchOfficeTypeDisplay;
		this.RegionalOfficeId = object.RegionalOfficeId;
		this.OrganizationId = object.OrganizationId;
		this.RegionalOfficeName = object.RegionalOfficeName;
		this.OrganizationName = object.OrganizationName;
		this.SurveyName = object.SurveyName;
	}
}
