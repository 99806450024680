import Repository from '@/repositories/RepositoryFactory';

const ChatMessageRepository = Repository.get('client', 'chatMessage');

export default {
	namespaced: true,
	state: {
		chatMessages: {},
	},
	getters: {

	},
	mutations: {
		LOAD_CHAT_MESSAGES: (state, payload) => {
			state.chatMessages = payload;
		},
	},
	actions: {
		async getchatMessagesFromRepo ({ commit }, object) {
			commit('LOAD_CHAT_MESSAGES', await ChatMessageRepository.getConsoleChat(object));
		},
	},
};
