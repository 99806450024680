import safeFormatPhone from '@/utils/phone';

export default class UserSurveyForNotify {
	constructor (object) {
		this.Id = object.UserId;
		this.FirstName = object.Name;
		this.LastName = object.Surname;
		this.RoleId = object.RoleId;
		this.RoleName = object.RoleName;
		this.RoleNameDisplay = object.RoleNameDisplay;
		this.Email = object.Email;
		this.PhoneNumber = safeFormatPhone(object.PhoneNumber);
	}
}
