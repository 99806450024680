import safeFormatPhone from '@/utils/phone';

export default class UserExternalForSurvey {
	constructor (object) {
		this.ExternalUserId = object.ExternalUserId;
		this.Name = object.Name;
		this.Email = object.Email;
		this.Phone = safeFormatPhone(object.Phone);
		this.BranchOfficeId = object.BranchOfficeId;
	}
}
