import Vue from 'vue';
import Repository from '@/repositories/RepositoryFactory';

const ReportRepository = Repository.get('client', 'report');

export default {
	namespaced: true,

	state: {
		timers: [],
		timerPings: {},
	},
	mutations: {
		HANDLE_TIMER_MESSAGE_TYPES (state, data) {
			const msgType = data.Type || data.type;
			const timerIndex = state.timers.findIndex(t => t.NonRetailTaskId === data.NonRetailTaskId);
			switch (msgType) {
			case 'START-CHECKINOUT-PINGS':
				state.timers.push(data);
				Vue.set(state.timerPings, data.NonRetailTaskId, [{
					NonRetailTaskId: data.NonRetailTaskId,
					BranchOfficeId: data.BranchOfficeId,
					Latitude: data.Latitude,
					Longitude: data.Longitude,
					TimerForAction: data.TimerForAction,
					CurrentTimeUTC: data.CurrentTimeUTC,
					UserCurrentTimeLocal: data.UserCurrentTimeLocal,
				}]);
				break;
			case 'CHECKINOUT-PINGS':
				state.timerPings[data.NonRetailTaskId].push({
					NonRetailTaskId: data.NonRetailTaskId,
					BranchOfficeId: data.BranchOfficeId,
					Latitude: data.Latitude,
					Longitude: data.Longitude,
					TimerForAction: data.TimerForAction,
					CurrentTimeUTC: data.CurrentTimeUTC,
					UserCurrentTimeLocal: data.UserCurrentTimeLocal,
				});
				break;
			case 'STOP-CHECKINOUT-PINGS':
				state.timers.splice(timerIndex, 1);
				delete state.timerPings[data.NonRetailTaskId];
				break;
			default:
				state.timers.splice(timerIndex, 1);
				delete state.timerPings[data.NonRetailTaskId];
				break;
			}
		},
		LOAD_RUNING_TIMERS (state, payload) {
			payload.forEach(t => {
				state.timers.push(t);
				Vue.set(state.timerPings, t.NonRetailTaskId, [{
					NonRetailTaskId: t.NonRetailTaskId,
					BranchOfficeId: t.BranchOfficeId,
					Latitude: t.Latitude,
					Longitude: t.Longitude,
					TimerForAction: t.TimerForAction,
					CurrentTimeUTC: null,
					UserCurrentTimeLocal: null,
				}]);
				t.Pings.forEach(p => {
					state.timerPings[p.NonRetailTaskId].push({
						NonRetailTaskId: p.NonRetailTaskId,
						BranchOfficeId: null,
						Latitude: p.Latitude,
						Longitude: p.Longitude,
						TimerForAction: p.TimerForAction,
						CurrentTimeUTC: null,
						UserCurrentTimeLocal: null,
					});
				});
			});
		},
	},
	actions: {
		async getTimerTrackings ({ commit }) {
			await commit('LOAD_RUNING_TIMERS', await ReportRepository.getTimerTrackings());
		},
	},
	getters: {

	},
};
