<template>
 <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="1000px"
      style="z-index: 9999"
    >
	<v-card>
		<v-card-title class="card-title" color="primary">
			<span class="headline">{{$t('consoles')}}</span>
			<v-spacer></v-spacer>
			<v-btn
				icon
				dark
				@click="close"
			>
            <v-icon color="primary">mdi-close</v-icon>
        </v-btn>
		</v-card-title>
		<Alert
            v-if="alert"
            :type="alertType"
            dense
            text
            dismissible
            v-model="visibility"
            >{{ alertMessage}}</Alert
          >
		<v-data-table
				:headers="headers"
				:items="securityOfficers"
				item-key="Id"
				class="elevation-1 fill-height"
				fluid
				:height="pageHeight()"
				style="cursor:pointer"
				:items-per-page="securityOfficers.length"
				:single-expand="true"
				:expanded="expanded"
				hide-default-footer
				@click:row="expand"
		>
		<template v-slot:item.statusColor="{ item }">
			<div class="pa-2 rounded-circle d-inline-block" :class="getColor(item.Status)"></div>
		</template>
		<template v-slot:expanded-item="{ headers, item}">
			<td :colspan="headers.length">
				<v-container>
					<p class="text-center" v-if="!chatMessages.MessagesArray">
						{{$t('nomessages')}}
					</p>
					<div v-for="msg in chatMessages.MessagesArray" :key="msg.MsgAutoId">
						<small><p :class="msg.MsgFromUserId === item.Id ? 'text-right' : 'text-left'">{{msg.MsgFromUserId === item.Id ? item.FirstName+' '+item.LastName : userObject.UserInfos.Name+' '+ userObject.UserInfos.Surname}} {{ formatDateTime(msg.MsgSentTimestamp) }}</p></small>
							<v-alert
								:border="msg.MsgFromUserId === item.Id ? 'right' : 'left'"
								:color="msg.MsgFromUserId === item.Id ? 'secondary' : 'primary'"
								class="pt-3 pb-0"
								:class=" msg.MsgFromUserId === item.Id ? 'black--text' : 'white--text'"
								dark
								dense
							>
							<p :class="msg.MsgFromUserId === item.Id ? 'text-right' : 'text-left'" v-html="msg.MsgContent"></p>
							</v-alert>
					</div>
				</v-container>
			</td>
		</template>
		</v-data-table>
	<v-card-actions>
          <v-spacer></v-spacer>
			<v-btn class="btn" color="primary" text @click="close">
            {{ $t("close") }}
          </v-btn>
	</v-card-actions>
</v-card>
   </v-dialog>
  </v-row>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import Alert from '@/components/base/MaterialAlert.vue';
import { formattedDateTime } from '@/utils/luxon-formater';

export default {
	components: {
		Alert,
	},
	name: 'Console',
	// required: true,
	props: ['dialog'],
	data: () => ({
		securityOfficers: [],
		expanded: [],
		visibility: false,
		Status: null,
		alertMessage: '',
		alertType: '',
		alert: false,
		Name: null,
		Surname: null,
		Email: null,
		RoleNameDisplay: null,
		PhoneNumber: null,
		role: null,
		emailProfile: null,
		person: [],
		UserId: null,
		longitude: null,
		latitude: null,
		param_latitude: null,
		param_longitude: null,
		Phone: null,
		Role: null,
	}),
	computed: {
		...mapGetters(['pageHeight']),
		...mapState({
			userObject: state => state.authentication.userObject,
			securityOffices: state => state.securityOffices.securityOffices,
			securityOfficersFromRepo: state => state.securityOfficers.securityOfficers,
			chatMessages: state => state.chatMessages.chatMessages,
			onlineConsoles: state => state.socket.onlineConsoles,
		}),
		headers () {
			return [
				{ text: '', value: 'statusColor' },
				{ text: this.$t('status'), value: 'Status', sortable: true },
				{ text: this.$t('name'), value: 'FirstName', sortable: true },
				{ text: this.$t('surname'), value: 'LastName', sortable: true },
				{ text: this.$t('role'), value: 'RoleNameDisplay', sortable: true },
				{ text: this.$t('email'), value: 'Email', sortable: true },
				{ text: this.$t('phone-number'), value: 'PhoneNumber', sortable: true },

			];
		},
	},
	methods: {
		formatDateTime (timestamp) {
			return formattedDateTime(timestamp, 'timestamp', null);
		},
		getColor (status) {
			if (status === 'Offline') return 'error';
			else return 'success';
		},
		showChat (userId) {
		},
		// openSurveyDialog () {
		// 	this.surveyDialog = true;
		// },
		close () {
			this.expanded = [];
			this.$emit('console-dialog-closed');
		},
		expand (item) {
			this.getChatMessages(item.Id);
			// this.getBranchEmployees(item.Id);
			// this.getBranchById(item.Id);
			if (item === this.expanded[0]) this.expanded = [];
			else this.expanded = [item];
		},
		getSecurityOffices () {
			this.$store.dispatch('securityOffices/getSecurityOfficesFromRepo', { root: true });
		},
		getSecurityOfficers () {
			this.$store.dispatch('securityOfficers/getSecurityOfficersFromRepo', { root: true })
				.then(() => {
					this.securityOfficers = this.securityOfficersFromRepo.map(s => {
						return {
							Id: s.Id,
							Status: 'Offline',
							FirstName: s.FirstName,
							LastName: s.LastName,
							RoleNameDisplay: s.RoleNameDisplay,
							Email: s.Email,
							PhoneNumber: s.PhoneNumber,
						};
					});
				})
				.then(() => {
					this.compareConsoles(this.onlineConsoles);
				});
		},
		getChatMessages (userId) {
			const securityOfficeId = this.userObject.UserProfile.SecurityOfficeId;
			let latitude;
			let longitude;
			for (var i = 0; i < this.securityOffices.length; i++) {
				if (this.securityOffices[i].Id === securityOfficeId) {
					latitude = this.securityOffices[i].Latitude;
					longitude = this.securityOffices[i].Longitude;
				}
			}
			const inputs = {
				param_longitude: `${longitude}`,
				param_latitude: `${latitude}`,
				param_accuracy: `${10.12}`,
				param_mobile_session_type: 'WEB',
				param_userid2: `${userId}`,
			};

			this.$store.dispatch('chatMessages/getchatMessagesFromRepo', inputs);
		},
		compareConsoles (onlineConsoles) {
			this.securityOfficers.forEach(so => {
				let isOnline = false;
				for (let i = 0; i < onlineConsoles.length; i++) {
					if (so.Id === onlineConsoles[i].UserId) {
						isOnline = true;
					}
				}
				if (isOnline) {
					so.Status = 'Online';
				} else {
					so.Status = 'Offline';
				}
			});
		},
		initialize () {
			this.getSecurityOffices();
		},
	},
	watch: {
		dialog (val) {
			if (val === true) {
				this.getSecurityOffices();
				this.getSecurityOfficers();
			}
		},
		visibility (val) {
			if (!val) {
				this.alert = false;
				this.visibility = true;
			}
		},
		onlineConsoles (val) {
			this.compareConsoles(val);
		},
	},
};
</script>
