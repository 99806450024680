import SecurityOffice from '@/lib/models/Client/SecurityOffice';
import { SecurityOfficeError } from '@/lib/Errors';
import $http from '../../../plugins/axios';

export default {
	async get () {
		const body = {
			case: 'GET-ALL-SECURITY-OFFICES',
		};

		const response = await $http.post('/api/client/getallsecurityoffices', body);

		if (response.data?.success) {
			const data = response.data.body.organizationobject || [];
			const securityOffices = data.map(so => {
				const securityOffice = new SecurityOffice(so);
				return securityOffice;
			});
			return securityOffices;
		} else {
			throw new SecurityOfficeError('Error retrieving Security Offices');
		}
	},
};
