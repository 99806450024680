<template>
      <v-card class="flex-grow-0">
		<v-container v-resize="onResize">
			<v-row>
				<v-col>
					<v-list dense>
						<v-list-item-group
							v-model="selectedItem"
							color="primary"
							@click="onResize"
						>
							<v-list-item
								v-for="(item, i) in ranges"
								:key="i"
							>
							<v-list-item-content>
								<v-list-item-title v-text="item.name"></v-list-item-title>
							</v-list-item-content>
							</v-list-item>
						</v-list-item-group>
					</v-list>
				</v-col>

				<v-divider vertical></v-divider>

				<v-col v-if="!isDisabled">
					<v-container v-resize="onResize">
								<v-menu ref="menuStartDate" v-model="menuStartDate"
									:close-on-content-click="false" :return-value.sync="startDate"
									transition="scale-transition" min-width="auto" nudge-left="50" offset-y
								>
									<template v-slot:activator="{ on, attrs }">
										<v-text-field
											v-model="startDate" label="From Date" prepend-icon="mdi-calendar" v-bind="attrs" v-on="on" :disabled="isDisabled"
										></v-text-field>
									</template>
									<v-date-picker v-model="startDate" no-title scrollable>
										<v-spacer></v-spacer>
										<v-btn text color="primary" @click="menuStartDate = false">{{ $t('cancel') }}</v-btn>
										<v-btn text color="primary" @click="$refs.menuStartDate.save(startDate)">{{ $t('ok') }}</v-btn>
									</v-date-picker>
								</v-menu>

								<v-menu ref="menuEndDate" v-model="menuEndDate"
									:close-on-content-click="false" :return-value.sync="endDate"
									transition="scale-transition" min-width="auto" nudge-left="50" offset-y
								>
									<template v-slot:activator="{ on, attrs }">
										<v-text-field
											v-model="endDate" label="To Date" prepend-icon="mdi-calendar" v-bind="attrs" v-on="on" :disabled="isDisabled"
										></v-text-field>
									</template>
									<v-date-picker v-model="endDate" no-title scrollable :min="minimumDate">
										<v-spacer></v-spacer>
										<v-btn text color="primary" @click="menuEndDate = false">{{ $t('cancel') }}</v-btn>
										<v-btn text color="primary" @click="$refs.menuEndDate.save(endDate)">{{ $t('ok') }}</v-btn>
									</v-date-picker>
								</v-menu>
					</v-container>
				</v-col>
			</v-row>
		</v-container>
		<!-- <v-container fluid>
				<v-row dense>
					<v-col sm="12">
						<v-select :items="ranges" v-model="selectedItem" item-text="name" item-value="value" label="Range" required></v-select>
					</v-col>
				</v-row>
				<v-row dense v-if="selectedItem === 'custom'">
					<v-col>
						<v-date-picker v-model="startDate"></v-date-picker>
					</v-col>
					<v-col>
						<v-date-picker v-model="endDate"></v-date-picker>
					</v-col>
				</v-row>
		</v-container> -->
		<v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            text
            @click="menu = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="menu = false"
          >
            Apply
          </v-btn>
        </v-card-actions>
      </v-card>
</template>

<script>
import filterRanges from '@/constants.js';
export default {
	name: 'MaterialFilter',
	data: () => ({
		ranges: filterRanges,
		// selectedItem: 'today',
		selectedItem: 6,
		menuStartDate: false,
		menuEndDate: false,
		startDate: null,
		endDate: null,
		windowSize: {
			x: 0,
			y: 0,
		},
	}),
	computed: {
		isDisabled () {
			// return this.selectedItem !== 'custom';
			return this.selectedItem !== 6;
		},
		minimumDate () {
			const today = this.getTodaysDate();
			return this.startDate ?? today;
		},
	},
	methods: {
		onResize () {
			this.windowSize = { x: window.innerWidth, y: window.innerHeight };
		},
		getTodaysDate () {
			const d = new Date();
			let month = '' + (d.getMonth() + 1);
			let day = '' + d.getDate();
			const year = d.getFullYear();

			if (month.length < 2) { month = '0' + month; }
			if (day.length < 2) { day = '0' + day; }

			return [year, month, day].join('-');
		},
	},

};
</script>

<style>

</style>
