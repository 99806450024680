export default class SummaryReport {
	constructor (object) {
		this.Openings = object?.Openings ? object?.Openings : 0;
		this.OpeningsEarly = object?.OpeningsEarly ? object?.OpeningsEarly : 0;
		this.OpeningsLate = object?.OpeningsLate ? object?.OpeningsLate : 0;
		this.OpeningsOnTime = object?.OpeningsOnTime ? object?.OpeningsOnTime : 0;
		this.NotOpened = object?.NotOpened ? object?.NotOpened : 0;
		this.Closings = object?.Closings ? object?.Closings : 0;
		this.ClosingsEarly = object?.ClosingsEarly ? object?.ClosingsEarly : 0;
		this.ClosingsLate = object?.ClosingsLate ? object?.ClosingsLate : 0;
		this.ClosingsOnTime = object?.ClosingsOnTime ? object?.ClosingsOnTime : 0;
		this.AutoClosed = object?.AutoClosed ? object?.AutoClosed : 0;
		this.Service = object?.Service ? object?.Service : 0;
		this.Panic = object?.Panic ? object?.Panic : 0;
		this.Trouble = object?.Trouble ? object?.Trouble : 0;
		this.Emergency = object?.Emergency ? object?.Emergency : 0;
		this.SecurityAlerts = object?.SecurityAlerts ? object?.SecurityAlerts : 0;
		this.EmergencyData = object?.EmergencyData ? object?.EmergencyData : [];
		this.MissingData = object?.MissingData ? object?.MissingData : {};
		this.PanicData = object?.PanicData ? object?.PanicData : [];
		this.SecurityAlertsData = object?.SecurityAlertsData ? object?.SecurityAlertsData : [];
		this.TroubleData = object?.TroubleData ? object?.TroubleData : [];
		this.BranchActivity = object?.BranchActivity ? object.BranchActivity : [];
	}
}
