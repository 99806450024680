
import ws from './../../lib/websocket';

export default {
	namespaced: true,
	state: {
		allNotifications: [],
		onlineConsoles: [],
	},
	getters: {

	},
	mutations: {
		UPDATE_ONLINE_CONSOLES: (state, payload) => {
			state.onlineConsoles = payload;
		},
	},
	actions: {
		async setConnection ({ commit }) {
			ws();
		},
	},
};
