import ActiveSession from '@/lib/models/Client/ActiveSession';
import { ActiveSessionError } from '@/lib/Errors';
import $http from '../../../plugins/axios';

export default {
	async get () {
		const body = {
			case: 'GET-ALL-SESSIONS',
		};

		const response = await $http.post('/api/client/getactivesessions', body);

		if (response.data?.success) {
			const data = response.data.body.personobject || [];
			const activeSessions = data.map(as => {
				const activeSession = new ActiveSession(as);
				return activeSession;
			});
			return activeSessions;
		} else {
			throw new ActiveSessionError('Error retrieving Active Session');
		}
	},
};
