import Role from '@/lib/models/Client/Role';
import { RoleError } from '@/lib/Errors';
import $http from '../../../plugins/axios';

export default {
	async get () {
		const body = {
			case: 'GET-ALL-ROLES-STATUS',
		};

		const response = await $http.post('/api/client/managerolestatus', body);

		if (response.data?.success) {
			const data = response.data.body.roleobject || [];
			const roles = data.map(r => {
				const role = new Role(r);
				return role;
			});
			return roles;
		} else {
			throw new RoleError('Error retrieving Users');
		}
	},
};
