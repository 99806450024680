export default class ActivityReportDetails {
	constructor (object) {
		this.NotificationId = object.NotificationId;
		this.BranchAction = object.BranchAction;
		this.RegionalOfficeId = object.RegionalOfficeId;
		this.RegionalOfficeName = object.RegionalOfficeName;
		this.UserId = object.UserId;
		this.Name = object.Name;
		this.Surname = object.Surname;
		this.Longitude = object.Longitude;
		this.Latitude = object.Latitude;
		this.Packet = object.Packet;
		this.Msg = object.Msg;
		this.Notes = object.Notes ? object.Notes : null;
		this.NoteCategory = object.NoteCategory ? object.NoteCategory : null;
		this.NotesBy = null;
		this.OrganizationId = object.OrganizationId;
		this.OrganizationName = object.OrganizationName;
		this.IsClosedBySystemData = object.IsClosedBySystemData;
		this.NotificationType = object.NotificationType;
		this.NotificationUserProfile = object.NotificationUserProfile;
		this.ChecklistLBranchActions = object.ChecklistLBranchActions;
		this.IsTestNotification = object.IsTestNotification;
		this.NotificationTimeAddedUTC = object.NotificationTimeAddedUTC;
		this.SecurityAlert = object.SecurityAlert;
		this.Destination = object.Destination ? object.Destination : null;
	}
}
