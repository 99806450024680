<template>
    <div>
        <v-text-field
            ref="search"
            v-model="search"
            hide-details
            label="Search"
            single-line
        ></v-text-field>
            <v-divider></v-divider>
            <v-list
                subheader
                three-line
                flat
                fluid
                class="overflow-y-auto"
                max-height="500"
                v-if="usersType === 'survey_externals'"
            >
            <v-subheader>{{ $t('extSurveyParticipants') }}</v-subheader>
            <v-checkbox
                class="ml-4 mt-0"
                label="Select All"
                color="primary"
                v-model="selectAll"
                @change="sendSelected"
            ></v-checkbox>
            <v-list-item v-for="u in usersList" :key="u.Email">
                <template>
                    <v-list-item-action>
                    <v-checkbox
                        :value="u"
                        color="primary"
                        v-model="selected"
                        @change="sendSelected"
                    ></v-checkbox>
                    </v-list-item-action>

                    <v-list-item-content>
                    <v-list-item-title>{{ u.Name }} ({{ $t('external') }})</v-list-item-title>
                    <v-list-item-subtitle>{{ u.Email }}</v-list-item-subtitle>
                    <v-list-item-subtitle>{{ phone(u.Phone) }}</v-list-item-subtitle>
                    </v-list-item-content>
                </template>
            </v-list-item>
        </v-list>
        <v-list
                subheader
                two-line
                flat
                fluid
                class="overflow-y-auto"
                max-height="500"
                v-else
            >
            <v-subheader v-if="usersType === 'alerted'">{{ $t('alertedUsers') }}</v-subheader>
            <v-subheader v-else-if="usersType === 'ens'" class="ma-0 pa-0">{{ $t('ensUsers') }}</v-subheader>
            <v-subheader v-else>{{ $t('surveyParticipants') }}</v-subheader>
            <v-checkbox
                class="ml-4 mt-0"
                label="Select All"
                color="primary"
                v-model="selectAll"
                @change="sendSelected"
            ></v-checkbox>
            <v-list-item v-for="u in usersList" :key="u.Id">
                <template>
                    <v-list-item-action>
                    <v-checkbox
                        :value="u.Id"
                        color="primary"
                        v-model="selected"
                        @change="sendSelected"
                    ></v-checkbox>
                    </v-list-item-action>

                    <v-list-item-content>
                    <v-list-item-title>{{ u.FirstName }} {{ u.LastName }} ({{ u.RoleNameDisplay }})</v-list-item-title>
                    <v-list-item-subtitle>{{ u.Email }}</v-list-item-subtitle>
                    <v-list-item-subtitle>{{ phone(u.PhoneNumber) }}</v-list-item-subtitle>
                    </v-list-item-content>
                </template>
            </v-list-item>
        </v-list>
    </div>
</template>

<script>
import safeFormatPhone from '@/utils/phone';

export default {
	props: ['users', 'usersType', 'selectedUsers'],
	data () {
		return {
			selected: [],
			search: '',
		};
	},
	computed: {
		usersList () {
			const search = this.search.toLowerCase();

			if (!search) return this.users;

			return this.users.filter(u => {
				const email = u.Email.toLowerCase();
				const name = u.FirstName.toLowerCase();
				const phoneNumber = u.PhoneNumber;
				const roleNameDisplay = u.RoleNameDisplay.toLowerCase();

				return email.indexOf(search) > -1 || name.indexOf(search) > -1 || phoneNumber.indexOf(search) > -1 || roleNameDisplay.indexOf(search) > -1;
			});
		},
		selectAll: {
			get () {
				return this.users ? this.selectedUsers.length === this.users.length : false;
			},
			set (value) {
				var selected = [];

				if (value) {
					if (this.usersType === 'survey_externals') {
						this.users.forEach(function (user) {
							selected.push(user);
						});
					} else {
						this.users.forEach(function (user) {
							selected.push(user.Id);
						});
					}
				}
				this.selected = selected;
			},
		},
	},
	methods: {
		phone (phoneNumber) {
			return safeFormatPhone(phoneNumber);
		},
		sendSelected () {
			switch (this.usersType) {
			case 'alerted':
				this.$emit('alerted-users-list', this.selected);
				break;
			case 'survey':
				this.$emit('survey-users-list', this.selected);
				break;
			case 'survey_externals':
				this.$emit('survey-externals-list', this.selected);
				break;
			case 'ens':
				this.$emit('ens-list', this.selected);
				break;
			default:
				break;
			}
		},
	},
	mounted () {
		setTimeout(() => {
			this.selected = this.selectedUsers;
		}, 500);
	},
};
</script>
