export const PRODUCT_THEME = {
	primary: '#1976D2',
	secondary: '#F3F4F6',
	info: '#00CAE3',
	accent: '#8c9eff',
	error: '#b71c1c',
	open: '#4caf50',
	close: '#9E9E9E',
	service: '#FF9800',
	alarm: '#F44336',
};
