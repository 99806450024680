export default class TemplateDetails {
	constructor (object) {
		this.TemplateDetailsAutoId = object.TemplateDetailsAutoId;
		this.TemplateAutoId = object.TemplateAutoId;
		this.TemplateDetailsName = object.TemplateDetailsName;
		this.TemplateDetailsDescription = object.TemplateDetailsDescription;
		this.PriorityOrder = object.PriorityOrder;
		this.IsOrderMandatory = object.IsOrderMandatory;
		this.TimeInterval = object.TimeInterval;
	}
}
