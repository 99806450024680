import Vue from 'vue';
import parsePhoneNumberFromString from 'libphonenumber-js';
import { DateTime } from 'luxon';

// import { addBreadcrumb } from '@sentry/browser';

export class IncidentStatus {
	static get UNKNOWN () {
		return null;
	}

	static get NEW () {
		return 'new';
	}

	static get TRANSFER () {
		return 'transfer';
	}

	static get ACTIVE () {
		return 'active';
	}

	static get ENDED () {
		return 'ended';
	}

	static get CLOSEOUT () {
		return 'closeout';
	}

	static get OTHER_CONSOLE () {
		return 'other-console';
	}

	static get OTHER_CLOSEOUT () {
		return 'other-closeout';
	}
}

export class ActiveIncident {
	status = IncidentStatus.UNKNOWN;
	profile = null;
	textMessages = [];
	locations = [];
	answeredBy = undefined;
	transferTo = undefined;

	// This makes sure we only try to answer the call once on the server
	sentAnswerRequest = false;

	// TODO Add closeout data structures

	constructor (incidentId, callerId) {
		this.incidentId = incidentId;
		this.callerId = callerId;
		// NOTE: 'US' here is a **fallback** for if no country code is provided.
		// This exists for legacy reasons. Any phone number that comes into the
		// system with a country code for another country will still be parsed correctly.
		const phoneNumber = parsePhoneNumberFromString(callerId, 'US');
		// The phone number in E.164 format with the plus stripped off
		this.cleanedCallerId = phoneNumber.format('E.164').substring(1);
		// The phone number for display
		this.formattedCallerId = phoneNumber.formatInternational();
	}
}

export default {
	namespaced: true,
	state: {
		isConnected: false,

		// IMPORTANT: Always use `Vue.set` and `Vue.delete` to add/remove items from this object.
		// TODO: Store other console real names instead of only username and activeCallerId
		otherConsoles: {},

		// IMPORTANT: Always use `Vue.set` and `Vue.delete` to add/remove items from this object.
		calls: {},

		// temporarily holds the callerId of the call we want to answer
		// while we are connecting to media
		tryToAnswerCallerId: null,
		activeCallerId: null,

		activePlaybackIncidentId: null,
		activePlaybackIncidentData: null,
		activePlaybackIncidentMedia: null,

		operatorUsername: null,

		// NOTE: These are not settings for whether to send
		// local media. They are settings for mute/unmute.
		// In practice, in this application, we do not ever send video,
		// even if the value is set to `true`.
		localAudioEnabled: true,
		localVideoEnabled: false,
		haveRemoteVideo: false,
	},
	getters: {
		activeCall (state) {
			return state.calls[state.activeCallerId];
		},
		locations (state) {
			return state.calls[state.activeCallerId]?.locations || [];
		},
		currentLocation (state) {
			const locations = state.calls[state.activeCallerId]?.locations;
			if (locations?.length) {
				return locations[locations.length - 1];
			}
			return null;
		},
		textMessages (state) {
			return state.calls[state.activeCallerId]?.textMessages || [];
		},
		profile (state) {
			return state.calls[state.activeCallerId]?.profile;
		},
		getCall: (state) => (callerId, alreadyClean) => {
			return getCallFromPhoneNumber(state, callerId, alreadyClean);
		},
		canAnswerCall: (state) => (callerId, alreadyClean) => {
			const call = getCallFromPhoneNumber(state, callerId, alreadyClean);
			// TODO Is there any other status we should check here?
			return (call?.answeredBy?.length === 0 || call?.transferTo === state.operatorUsername) && call?.disconnectedBy?.length === 0;
		},
	},
	actions: {
		tryAnswerCall ({ commit, state }, { callerId, alreadyClean }) {
			// If we already have an active call, don't do anything here
			// Note that this could also be true if we have already clicked to
			// answer THIS call but the WebRTC connection is not yet stable.
			if (state.activeCallerId) {
				// TODO: Is there any kind of notification/error message we should
				//       be showing in this case?
				return;
			}

			const call = getCallFromPhoneNumber(state, callerId, alreadyClean);

			// If there is no such call, don't do anything here
			if (!call) {
				// TODO: Is there any kind of notification/error message we should
				//       be showing in this case?
				return;
			}

			// If the call has already been answered (and is not a transfer to us), don't do anything here
			if (call.answeredBy && call.transferTo !== state.operatorUsername) {
				// TODO: Is there any kind of notification/error message we should
				//       be showing in this case?
				return;
			}

			// This starts the process to answer the call
			commit('tryAnswerCall', call.cleanedCallerId);
			commit('startLocalMedia');
		},
		endCall ({ commit, state, getters }) {
			if (!state.activeCallerId) {
				return;
			}

			commit('endCall', getters.activeCall.callerId);
			commit('endPeerConnection');
			commit('stopLocalMedia');
		},
		transferCall ({ commit, getters, state }, toOperator) {
			if (!state.activeCallerId) {
				return;
			}

			commit('transferCall', { callerId: getters.activeCall.callerId, toOperator });
			commit('endPeerConnection');
			commit('stopLocalMedia');
		},
		holdCall ({ commit, getters, state }) {
			if (!state.activeCallerId) {
				return;
			}

			commit('transferCall', { callerId: getters.activeCall.callerId, toOperator: state.operatorUsername });
			commit('endPeerConnection');
			commit('stopLocalMedia');
		},
		MESSAGE_callerStatus ({ commit, state }, message) {
			commit('updateCallStatus', message);

			// Determine whether to play ringing.
			if (message.disconnectedBy === '' && message.transfer === '' && message.answerer === '') {
				// If there is a new call, ring
				commit('addRinging', null, { root: true });
			} else if (message.disconnectedBy === '' && message.transfer === this.operatorUsername && message.answerer === '') {
				// If there is a trasfer to us, ring
				// TODO: is there any reason to make this a different ringtone?
				commit('addRinging', null, { root: true });
			} else {
				// TODO: is this correct?
				commit('removeRinging', null, { root: true });
			}

			// Check if call is being hung up and end our media connections
			if (message.disconnectedBy !== '' && message.answerer === state.operatorUsername) {
				commit('endPeerConnection');
				commit('stopLocalMedia');
			}
		},
		addLocalMessage ({ commit, getters, state }, messageText) {
			const call = getters.activeCall;
			if (call) {
				const message = {
					clientType: 'Console',
					destination: call.callerId,
					message: messageText,
					source: state.operatorUsername,
					timestamp: DateTime.utc(),
				};
				message.key = `${message.timestamp}:${message.source}`;

				commit('addLocalMessage', message);
			}
		},
	},
	mutations: {
		updateCallStatus (state, message) {
			let call = getCallFromPhoneNumber(state, message.callerID);
			if (!call) {
				call = new ActiveIncident(message.incidentNumber, message.callerID);
				Vue.set(state.calls, call.cleanedCallerId, call);
			}

			call.transferTo = message.transfer;
			call.answeredBy = message.answerer;
			call.disconnectedBy = message.disconnectedBy;

			// If we already had a call from a user that needed closeout, and the
			// same user calls back again, we need to update the incident number so that
			// the newest call is the one that gets the closeout info.
			if (call.incidentId !== message.incidentNumber) {
				call.incidentId = message.incidentNumber;
			}

			// Check if call is being hung up
			if (message.disconnectedBy !== '') {
				// TODO: break down WebRTC connection - emit('webrtc.hangup')

				// Mark this call as un-clicked again, so
				// that in case the person calls right back,
				// we will be able to accept again.
				call.sentAnswerRequest = false;

				// TODO: stop ringing

				// If this is our current call, clean up the call interface
				if (state.activeCallerId === call.cleanedCallerId) {
					state.activeCallerId = null;
				}

				// TODO: If we want to re-enable closeouts, we need to use this:
				/*
				if (call.answeredBy === '' || call.answeredBy === state.operatorUsername) {
					// This allows closeout to be done
					call.status = IncidentStatus.ENDED;
				} else {
					// This assumes that the other console will take care of doing the closeout.
					Vue.delete(state.calls, call.cleanedCallerId);
				}
				*/
				// Since we aren't using closeouts, we use the line below instead:
				Vue.delete(state.calls, call.cleanedCallerId);

				removeCallFromOtherConsolesList(state, call);

				return;
			}

			// Check if the call is hold/transfer
			if (call.transferTo !== null && call.transferTo.length > 0) {
				if (call.transferTo === state.operatorUsername) {
					call.status = IncidentStatus.TRANSFER;

					// HOLD is implemented as a transfer to yourself.  There is stuff we need
					// to do in that case...
					if (call.transferTo === call.answeredBy) {
						// Tear down the WebRTC connection, because we have some kind of issue
						// with PeerConnection renegotiation, so we will start from scratch every time.
						// TODO: break down WebRTC connection - emit('webrtc.hangup')
					}

					return;
				} else {
					// Check if we transferred the call away from ourselves so we can end our PC
					if (call.answeredBy === state.operatorUsername && state.activeCallerId === call.cleanedCallerId) {
						state.activeCallerId = null;
						// TODO: break down WebRTC connection - emit('webrtc.hangup')
					}
				}
			}

			// If this call is unanswered, let it ring
			if (call.answeredBy === '') {
				call.status = IncidentStatus.NEW;

				removeCallFromOtherConsolesList(state, call);
				return;
			}

			// The only remaining possible status change is that someone has picked up
			// the call. That someone could be us, or it could be another console.
			if (call.answeredBy === state.operatorUsername) {
				call.status = IncidentStatus.ACTIVE;
			} else {
				call.status = IncidentStatus.OTHER_CONSOLE;
				Vue.set(state.otherConsoles, call.answeredBy, call.cleanedCallerId);
			}
		},
		tryAnswerCall (state, cleanedCallerId) {
			state.tryToAnswerCallerId = cleanedCallerId;
		},
		answerCall (state) {
			const callerId = state.tryToAnswerCallerId;
			state.tryToAnswerCallerId = null;
			state.activeCallerId = callerId;
			// The controller-websocket Vuex plugin also subscribes to this mutation
			// to tell the server we want to answer the call
		},
		endCall (state) {
			state.activeCallerId = null;
			// The controller-websocket Vuex plugin subscribes to this mutation
			// to end the active call
		},
		transferCall (state) {
			state.activeCallerId = null;
			// The controller-websocket Vuex plugin subscribes to this mutation
			// to transfer (or hold) the active call
		},
		setActivePlaybackIncident (state, incidentId) {
			state.activePlaybackIncidentId = incidentId;
			// The controller-websocket Vuex plugin subscribes to this mutation
			// to get the playback data for a call
		},
		setSocketConnected (state, { isConnected, operatorUsername }) {
			state.isConnected = isConnected;
			state.operatorUsername = operatorUsername;
		},
		MESSAGE_error (state, message) {
			// TODO
		},
		MESSAGE_consoleStatus (state, message) {
			if (!message.connected) {
				Vue.delete(state.otherConsoles, message.consoleID);
			}
			// TODO: Handle other stuff from this message type.
			//       See console-base/src/managers/call-manager.js
		},
		MESSAGE_location (state, message) {
			const call = getCallFromPhoneNumber(state, message.id);
			if (call) {
				const loc = {
					lat: message.latitude,
					lng: message.longitude,
					// TODO: Any other fields?
				};
				call.locations.push(loc);
			}
		},
		MESSAGE_profile (state, message) {
			const call = getCallFromPhoneNumber(state, message.id);
			if (call) {
				call.profile = message;
			}
		},
		MESSAGE_text (state, message) {
			const call = getCallFromPhoneNumber(state, message.source);
			if (call) {
				message.key = `${message.timestamp}:${message.source}`;

				message.timestamp = DateTime.fromSQL(message.timestamp, { zone: 'UTC' });

				if (!call.textMessages.some(msg => msg.key === message.key)) {
					call.textMessages.push(message);
				}
			}
		},
		MESSAGE_iceCandidate () {
			// NO-OP
			// The browser-webrtc Vuex plugin subscribes to this mutation
			// to accept the ICE candidates from the server
		},
		MESSAGE_answer () {
			// NO-OP
			// The browser-webrtc Vuex plugin subscribes to this mutation
			// to accept the SDP answer from the server
		},
		MESSAGE_ICEServers () {
			// NO-OP
			// The browser-webrtc Vuex plugin subscribes to this mutation
			// to accept the list of ICE servers from the server and start the ICE process
		},
		MESSAGE_playbackData (state, message) {
			// eslint-disable-next-line eqeqeq
			if (state.activePlaybackIncidentId == message.incidentNumber) {
				state.activePlaybackIncidentData = message;
			}
		},
		MESSAGE_playbackMediaToken (state, message) {
			// eslint-disable-next-line eqeqeq
			if (state.activePlaybackIncidentId == message.incidentNumber) {
				state.activePlaybackIncidentMedia = message;
			}
		},
		startLocalMedia () {
			// NO-OP
			// The browser-webrtc Vuex plugin subscribes to this mutation
			// to start the local media stream(s)
		},
		haveLocalMedia (_state, { error }) {
			// The browser-webrtc Vuex plugin calls this mutation when it
			// has connected to the browser's media input(s)
			if (error) {
				// TODO: handle errors
			}
		},
		foundLocalIceCandidate () {
			// NO-OP
			// The controller-websocket Vuex plugin subscribes to this mutation
			// to send local ICE candidates to the server
		},
		peerConnectionState (_state, connectionState) {
			if (connectionState === 'connected') {
				// TODO: Answer the call here
			}
			// TODO: Add error handling for ICE failures and/or any other status changes.
			//       See https://developer.mozilla.org/en-US/docs/Web/API/RTCPeerConnection/connectionState#rtcpeerconnectionstate_enum
			//       and https://developer.mozilla.org/en-US/docs/Web/API/RTCPeerConnection/iceConnectionState#rtciceconnectionstate_enum
		},
		setLocalOffer () {
			// NO-OP
			// The controller-websocket Vuex plugin subscribes to this mutation
			// to send the local SDP offer to the server
		},
		requestIceServers () {
			// NO-OP
			// The controller-websocket Vuex plugin subscribes to this mutation
			// to request the ICE server list from the server
		},
		endPeerConnection () {
			// The browser-webrtc Vuex plugin subscribes to this mutation
			// to close the RTCPeerConnection
		},
		stopLocalMedia () {
			// The browser-webrtc Vuex plugin subscribes to this mutation
			// to end the local media stream(s)
		},
		localAudioEnabled (state, isEnabled) {
			// The browser-webrtc Vuex plugin subscribes to this mutation
			// to actually do the muting/unmuting.
			// Here we store the value so it can be displayed in the web interface
			state.localAudioEnabled = isEnabled;
		},
		localVideoEnabled (state, isEnabled) {
			// The browser-webrtc Vuex plugin subscribes to this mutation
			// to actually do the muting/unmuting.
			// Here we store the value so it can be displayed in the web interface
			state.localVideoEnabled = isEnabled;
		},
		haveRemoteVideo (state, haveRemoteVideo) {
			state.haveRemoteVideo = haveRemoteVideo;
		},
		addLocalMessage (state, message) {
			const call = getCallFromPhoneNumber(state, message.destination, false);
			if (call) {
				call.textMessages.push(message);
			}
			// The controller-websocket Vuex plugin subscribes to this mutation
			// to send the message to the server
		},
	},
};

function cleanCallerId (number) {
	// NOTE: 'US' here is a **fallback** for if no country code is provided.
	// This exists for legacy reasons. Any phone number that comes into the
	// system with a country code for another country will still be parsed correctly.
	const phoneNumber = parsePhoneNumberFromString(number, 'US');
	// The phone number in E.164 format with the plus stripped off
	return phoneNumber.format('E.164').substring(1);
}

function getCallFromPhoneNumber (state, number, alreadyClean = false) {
	const formattedNumber = alreadyClean ? number : cleanCallerId(number);
	return state.calls[formattedNumber];
}

/**
 * If the call has a blank `answerBy` or is now disconnected,
 * remove it from the list of other consoles (if it is there).
 *
 * @param {Object} state
 * @param {ActiveIncident} call
 */
function removeCallFromOtherConsolesList (state, call) {
	for (const otherConsole in state.otherConsoles) {
		if (state.otherConsoles[otherConsole] === call.cleanedCallerId) {
			Vue.set(state.otherConsoles, otherConsole, null);
		}
	}
}
