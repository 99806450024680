import ChatMessage from '@/lib/models/Client/ChatMessage';
import ChatSession from '@/lib/models/Client/ChatSession';
import ChatSessionDetails from '@/lib/models/Client/ChatSessionDetails';
import { ChatMessageError } from '@/lib/Errors';
import $http from '../../../plugins/axios';

export default {
	async getConsoleChat (object) {
		const response = await $http.post('/api/client/startchatso', object);

		if (response.data?.success) {
			const data = response.data.body.sessiondetails;
			const chat = new ChatMessage(data);
			return chat;
		} else {
			throw new ChatMessageError('Error retrieving Chats');
		}
	},
	async sendChatMessage (object) {
		const response = await $http.post('/api/client/writeinchat', object);

		if (response.data?.success) {
			return response.data.body;
		} else {
			throw new ChatMessageError('Error sending chat message');
		}
	},
	async answerChatFromConsole (object) {
		const response = await $http.post('/api/client/answerchatfromconsole', object);

		if (response.data?.success) {
			return response.data.body;
		} else {
			throw new ChatMessageError('Error answering chat');
		}
	},
	async finishChat (object) {
		const response = await $http.post('/api/client/finishChat', object);

		if (response.data?.success) {
			return response.data.body;
		} else {
			throw new ChatMessageError('Error ending chat');
		}
	},
	async holdChat (object) {
		const response = await $http.post('/api/client/holdchat', object);

		if (response.data?.success) {
			return response.data.body;
		} else {
			throw new ChatMessageError('Error holding chat');
		}
	},
	async getChatRecordings (object) {
		const response = await $http.post('/api/client/getSessions', object);

		if (response.data?.success) {
			const data = response.data.body.resultobject || [];
			if (data) {
				const chatSessions = data.map(c => {
					const chat = new ChatSession(c);
					return chat;
				});
				return chatSessions;
			} else {
				return [];
			}
		} else {
			throw new ChatMessageError('Error retrieving chat list');
		}
	},
	async getSessionDetailsBySessionId (id) {
		const body = {
			param_session_id: id,
		};

		const response = await $http.post('/api/client/getSessionDetailsBySessionId', body);

		if (response.data?.success) {
			const data = response.data.body.resultobject;
			const chatSessionDetails = new ChatSessionDetails(data);
			return chatSessionDetails;
		} else {
			throw new ChatMessageError('Error retrieving chat list');
		}
	},
};
