<template>
    <v-list>
		<v-list-item-group
			:active-class="`primary white--text`"
			mandatory
		>
			<!-- TODO: Remove deprecated use of `tag=span` -->
			<router-link v-for="call in videoCalls" :key="call.cleanedCallerId" tag="span" :to="{ path: `/video/${call.cleanedCallerId}` }" style="text-decoration: none;">
			<v-list-item>
				<v-list-item-avatar>
				<v-icon
					class="blink"
					color="white"
					large
					dark
				>
					mdi-video
				</v-icon>
				</v-list-item-avatar>
				<v-list-item-content>
					<v-list-item-title>{{ callTitle(call) }}</v-list-item-title>
					<v-list-item-subtitle>{{ callSubtitle(call) }}</v-list-item-subtitle>
				</v-list-item-content>
			</v-list-item>
			</router-link>
		</v-list-item-group>
    </v-list>
</template>

<script>
import { mapState } from 'vuex';

export default {
	computed: {
		...mapState({
			videoCalls: state => state.videoCalls.calls,
		}),
	},
	methods: {
		callTitle (call) {
			if (call.profile) {
				return `${call.profile.firstName} ${call.profile.lastName}`;
			} else {
				return call.formattedCallerId;
			}
		},
		callSubtitle (call) {
			// If we have the call profile, the phone number is the subtitle.
			// If the profile has not come in yet, the phone number is the title instead.
			if (call.profile) {
				return call.formattedCallerId;
			}
			return '';
		},
	},
};
</script>

<style scoped>
	.blink {
		animation: blinker 1s linear infinite;
	}
	@keyframes blinker {
		50% {
			opacity: 0;
		}
	}
</style>
